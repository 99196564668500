/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, Category, CategoryCount, CommonService, FilterCategory, FilterStatuses, Floor, InstallStatusFilter, OperationalStatusFilter, UserService, Utilities, ViewingPreferences } from '@SiteOwl/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Options } from 'ngx-slider-v2';
import { DBDevicePinSizeValue } from '../../constant/floor.constant';

@Component({
  selector: 'so-equipment-filter-count',
  templateUrl: './equipment-filter-count.component.html',
  styleUrls: ['./equipment-filter-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentFilterCountComponent implements OnInit, OnChanges {
  @Input() categoryData: any[] = [];

  @Input() selectedFloor!: Floor;

  @Input() showDeviceName!: boolean;

  @Input() showPartNumber!: boolean;

  @Input() showAbbreviatedName!: boolean;

  @Input() isDeviceArchived!: boolean

  @Input() showCoverage!: boolean;

  @Input() showPlanMarkup!: boolean

  @Input() showPlanMarkupFilter!: boolean
  @Input() hideLiveDevice!: boolean;

  @Input() projectId!: any;

  @Input() stage: any;
  @Input() showConnectionLine!: boolean;

  @Input() filterCategory: FilterCategory[] = [];
  @Input() installedByUsers: any;
  @Input() id: any;

  @Input() pinSize: any;
  @Input() isCableEnable: any;
  @Input() selectedFilterCountTab: any;
  @Input() buildingId: any;
  @Input() siteId: any;
  @Input() floorDetails: any;
  @Input() deviceStatusData: any;
  @Input() isLiveDeviceAvailable: any;
  @Input() pinColorType: any = null;
  @Input() serviceTickets: any;
  @Output() filterCategoryChange = new EventEmitter();
  @Output() componentClicked = new EventEmitter();
  @Output() pinColorValueUpdate = new EventEmitter();
  @Output() pinSizeValueChanged = new EventEmitter();
  @Output() liveDeviceToggle = new EventEmitter();
  @Output() assignedToUserChanged = new EventEmitter();
  categoryCount: any;
  totalEquipmentCount: any;
  totalHours!: number;
  totalDeviceEquipmentCount: any;
  totalTaskEquipmentCount: any;
  isAccordianOpen: any = [];
  tempAccordianOpen: any;
  serviceTicketCountForFloor: any = 0;
  assignedTo: any = null;
  installByUserItems: any;
  user: any;
  isLiveSystemAccessForCustomer: any;
  hideLiveDeviceToggle: any;

  pinSizeValue = 54;
  pinSizeOptions: Options = {
    floor: 18,
    ceil: 60,
    step: 6,
    showTicks: true
  };
  isTicketAccessible=false;

  constructor(private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService) { }
  ngOnInit(): void {
    this.user = this.authService.getUserKeyData();
    this.isLiveSystemAccessForCustomer = this.user.isLiveSystemAccessForCustomer;
    this.isTicketAccessible = this.user.isTicketing;
    this.getCategoryCount();
    this.countTotalServiceTicket();
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'showPlanMarkupFilter') {
        this.showPlanMarkupFilter = _.cloneDeep(changes[propName].currentValue);
        this.setFilterCategory();
      }
      if (propName === 'serviceTickets') {
        if (!Utilities.isNull(changes[propName].currentValue) && changes[propName].currentValue !== undefined) {
          this.selectedFloor.serviceTicket = _.cloneDeep(changes[propName].currentValue)
          this.countTotalServiceTicket();
        }
      } else if (propName === 'selectedFloor') {
        if (!Utilities.isNull(changes[propName].currentValue)) {
          this.getCategoryCount();
          this.countTotalServiceTicket();
        }
      } else if (propName === 'categoryData') {
        this.getCategoryCount();
        this.setFilterCategory();
      } else if (propName === 'installedByUsers') {
        this.installedByUsers = changes[propName].currentValue
        if (this.installedByUsers) {
          this.installedByUsers = _.sortBy(this.installedByUsers, [function (a) {
            if (!Utilities.isEmpty(a.name)) {
              return a.name.toLowerCase()
            } else if (!Utilities.isEmpty(a.email)) {
              return a.email.toLowerCase()
            } else if (!Utilities.isEmpty(a.value)) {
              return a.value.toLowerCase();
            }
          }]);
        }
      } else if (propName === 'hideLiveDevice') {
        this.hideLiveDevice = _.cloneDeep(changes[propName].currentValue);
        if (this.hideLiveDevice && this.hideLiveDeviceToggle) {
          this.hideLiveDeviceToggle = false;
        }
      }
    }
  }
  setFilterCategory() {
    this.filterCategory = [];
    ViewingPreferences.forEach((element: any) => {
      const category = new Category();
      category.id = element.id;
      category.name = element.name;
      category.displayName = element.displayName;
      if (element.id === 7 || element.id === 6) {
        if (element.id === 6) {
          this.filterCategory.push({ isChecked: this[element.key as keyof this], category: category, type: 'vp' })
        }
        if (this.projectId && this.showPlanMarkupFilter && element.id === 7) {
          this.filterCategory.push({ isChecked: this[element.key as keyof this], category: category, type: 'vp' })
        }
      } else {
        this.filterCategory.push({ isChecked: this[element.key as keyof this], category: category, type: 'vp' })
      }
    })
    if (this.categoryData && this.categoryData.length > 0) {
      this.categoryData.forEach((category: Category) => {
        if (category.name !== 'Favorites') {
          this.filterCategory.push({ isChecked: true, category: category, type: 'st' })
        }
      });
    }
    OperationalStatusFilter.forEach((element: any) => {
      const category = new Category();
      category.id = element.id;
      category.name = element.name;
      category.displayName = element.displayName;
      this.filterCategory.push({ isChecked: true, category: category, type: 'op' })
    });
    if (this.projectId) {
      InstallStatusFilter.forEach((element: any) => {
        const category = new Category();
        category.id = element.id;
        category.name = element.name;
        category.displayName = element.displayName;
        this.filterCategory.push({ isChecked: true, category: category, type: 'is' })
      });
    }
    this.filterCategoryChange.emit(this.filterCategory)
  }
  onSelect(event: any) {

  }
  clicked(event: any) {
    this.componentClicked.emit();
  }
  getCategoryCount(): void {
    this.categoryCount = [];
    this.totalEquipmentCount = 0;
    this.totalDeviceEquipmentCount = 0;
    this.totalTaskEquipmentCount = 0;
    this.totalHours = 0;
    if (this.selectedFloor.floorEquipments && this.selectedFloor.floorEquipments.length > 0) {
      if (this.projectId) {
        this.totalDeviceEquipmentCount = this.selectedFloor.floorEquipments.filter(x => x.type === 'device' && x.isArchived === false && x.projectId !== null).length
        this.totalTaskEquipmentCount = this.selectedFloor.floorEquipments.filter(x => x.type !== 'device' && x.isArchived === false).length
      } else {
        this.totalDeviceEquipmentCount = this.selectedFloor.floorEquipments.filter(x => x.type === 'device' && x.isArchived === false).length
        this.totalTaskEquipmentCount = this.selectedFloor.floorEquipments.filter(x => x.type !== 'device' && x.isArchived === false).length
      }
    }
    if (this.selectedFloor.floorEquipments) {
      this.selectedFloor.floorEquipments.forEach(element => {
        if (this.projectId) {
          if (!Utilities.isNull(element.installHours) && !element.isArchived && !element.isDeleted && element.projectId !== null) {
            if (!isNaN(Number(element.installHours))) {
              this.totalHours += Number(element.installHours);
            }
          }
        }
        if (element.childFloorEquipments && element.childFloorEquipments.length > 0) {
          element.childFloorEquipments.forEach(elementChild => {
            if (elementChild.type === 'task' && !elementChild.isArchived && !elementChild.isDeleted) {
              this.totalTaskEquipmentCount = this.totalTaskEquipmentCount + 1;
            }
            if (this.projectId) {
              if (elementChild.type === 'device' && !elementChild.isArchived && !elementChild.isDeleted && element.projectId !== null) {
                this.totalDeviceEquipmentCount = this.totalDeviceEquipmentCount + 1;
              }
            } else {
              if (elementChild.type === 'device' && !elementChild.isArchived && !elementChild.isDeleted) {
                this.totalDeviceEquipmentCount = this.totalDeviceEquipmentCount + 1;
              }
            }
            if (this.projectId) {
              if (!Utilities.isNull(elementChild.installHours) && !elementChild.isArchived && !elementChild.isDeleted && elementChild.projectId !== null) {
                if (!isNaN(Number(elementChild.installHours))) {
                  this.totalHours += Number(elementChild.installHours);
                }
              }
            }
          })
        }
      });
    }
    if (!Utilities.isEmpty(this.categoryData)) {

      this.categoryData.forEach((category: Category) => {
        let equipmentCategory: any;
        if (this.projectId && this.selectedFloor.floorEquipments) {
          equipmentCategory = this.selectedFloor.floorEquipments.filter(x => (x.categoryName === category.name) && (!x.isArchived && !x.isDeleted && x.projectId !== null));
        } else {
          if (this.selectedFloor.floorEquipments) {
            equipmentCategory = this.selectedFloor.floorEquipments.filter(x => (x.categoryName === category.name) && (!x.isArchived && !x.isDeleted));
          }
        }
        if (this.selectedFloor.floorEquipments) {
          this.selectedFloor.floorEquipments.forEach(equipment => {
            if (this.projectId) {
              if (!equipment.isArchived && !equipment.isDeleted && equipment.projectId !== null) {

                equipment.childFloorEquipments.forEach(chidlEquipment => {
                  if ((!chidlEquipment.isArchived && !chidlEquipment.isDeleted && chidlEquipment.projectId !== null) && chidlEquipment.categoryName == category.name) {
                    equipmentCategory.push(chidlEquipment)
                  }
                })
              }
            } else {
              if (!equipment.isArchived && !equipment.isDeleted) {

                equipment.childFloorEquipments.forEach(chidlEquipment => {
                  if ((!chidlEquipment.isArchived && !chidlEquipment.isDeleted) && chidlEquipment.categoryName == category.name) {
                    equipmentCategory.push(chidlEquipment)
                  }
                })
              }
            }
          })
        }

        if (equipmentCategory.length > 0) {
          const tempCategoryCount: CategoryCount = {
            name: category.name,
            count: equipmentCategory.length,
            parts: []
          };
          if (equipmentCategory[0].projectStage === 'Installation' || equipmentCategory[0].projectStage === 'Design') {
            this.totalEquipmentCount += equipmentCategory.filter((x: any) => x.installStatus === 'InProgress' || x.installStatus === 'Planned' || x.installStatus === 'Done').length
          } else {
            this.totalEquipmentCount += equipmentCategory.length;
          }
          const partGroupData = _.groupBy(equipmentCategory, (item) => {
            return !Utilities.isEmptyWithTrim(item.partNumber) ? item.partNumber.trim() : '(blank)'
          });
          for (const key in partGroupData) {
            tempCategoryCount.parts.push({
              name: key,
              count: partGroupData[key].length
            })
          }

          tempCategoryCount.parts = _.sortBy(tempCategoryCount.parts, [function (a) {
            const r = /^[a-z0-9]/i;
            return r.test(a.name) ? a.name : a.name === '(blank)' ? 'z' + a.name : 'y' + a.name
          }]);
          this.categoryCount.push(tempCategoryCount);

        }
      });
    }
  }
  getAccordianClass(mark: any, name: any) {
    if (!mark._isOpen) {
      this.tempAccordianOpen = name;
      this.isAccordianOpen.splice(this.isAccordianOpen.indexOf(name), 1);
    } else {
      if (this.isAccordianOpen && this.isAccordianOpen.indexOf(name) < 0 && this.tempAccordianOpen !== name) {
        this.isAccordianOpen.push(name);
      }
    }
  }
  keepDefault(e: any, category: any) {
    e.preventDefault();
    e.stopPropagation();
    if (Utilities.isNull(this.projectId)) {
      this.commonService.resetFilters('site');
      this.commonService.removeItem('menu')
      this.commonService.setLocalStorageString('menu', 'siteDevice')
      if (this.categoryData) {
        this.router.navigate(['/secure/live-site/devices'], {
          queryParams: {
            site: this.siteId,
            building: this.buildingId,
            floor: this.selectedFloor.id,
            state: 'all',
            category: this.categoryData && this.categoryData.find((x: any) => x.name === category.name).id,
          }, relativeTo: this.route
        });
      }
    } else {
      // this.eventSerivce.broadcast(EventEmitterType.menuChanged, { data: 'projectDevice' });
      this.commonService.resetFilters('project');
      this.commonService.removeItem('menu')
      this.commonService.setLocalStorageString('menu', 'projectDevice')
      this.router.navigate(['/secure/project/devices'], {
        queryParams: {
          project: this.projectId,
          building: this.buildingId,
          floor: this.selectedFloor.id,
          category: this.categoryData.find(x => x.name === category.name).id
        }, relativeTo: this.route
      });
    }

  }
  totalClicked(isDeviceOnly?: boolean, isTaskOnly?: boolean) {
    let state = 'deviceList'
    if (Utilities.isNull(this.projectId)) {
      // this.eventSerivce.broadcast(EventEmitterType.menuChanged, { data: 'siteDevice' });
      // this.localStorageFilterService.resetFilters('site');
      this.router.navigate(['/secure/live-site/devices'], {
        queryParams: {
          site: this.siteId,
          building: this.buildingId,
          floor: this.selectedFloor.id,
          state: 'all'
        }, relativeTo: this.route
      });
    } else {
      let deviceOrTaskType: any;
      let installstatus: any = '';
      // this.eventSerivce.broadcast(EventEmitterType.menuChanged, { data: 'project' });
      // this.localStorageFilterService.resetFilters('project');
      if (isDeviceOnly) {
        deviceOrTaskType = 'device'
      } else if (isTaskOnly) {
        deviceOrTaskType = 'task'
      } else {
        deviceOrTaskType = 'all'
        state = 'punchList'
        installstatus = 'All Pending';
      }
      this.commonService.removeItem('menu')
      this.commonService.setLocalStorageString('menu', 'projectDevice')
      this.router.navigate(['/', 'secure', 'project', 'devices'], {
        queryParams: {
          project: this.projectId,
          building: this.buildingId,
          floor: this.selectedFloor.id,
          deviceOrTaskType: deviceOrTaskType,
          state: state,
          installstatus: installstatus
        }, relativeTo: this.route,

      });
    }
  }
  goToTickets() {
    // this.localStorageFilterService.resetFilters('ticket');
    // this.eventSerivce.broadcast(EventEmitterType.menuChanged, { data: 'ticket' });
    this.router.navigate(['/', 'secure', 'customer', 'ticket', 'list'], {
      queryParams: {
        siteid: this.siteId,
        buildingid: this.buildingId,
        floorid: this.selectedFloor.id,
      }, relativeTo: this.route
    });
  }
  private countTotalServiceTicket() {
    if (this.selectedFloor.serviceTicket) {
      this.serviceTicketCountForFloor = this.selectedFloor.serviceTicket.filter(e => e.status === FilterStatuses.INPROGRESS || e.status === FilterStatuses.OPEN).length;
    }
  }
  pinColorChanged(evt: any) {
    if (this.selectedFloor.id) {
      const id: any = this.selectedFloor.id || null;
      this.userService.savePinColor(id, this.pinColorType)?.subscribe({
        next: (r: any) => {
          this.pinColorValueUpdate.emit(this.pinColorType)
          // this.eventSerivce.broadcast(EventEmitterType.updateFloorDevicePinColor, this.pinColor);
        }, error: (e: any) => {
          console.log(e);
        }
      });
    }
  }
  assignedToChanged(event: any) {
    if (this.assignedTo == 'null') {
      this.assignedTo = null;
    }
    this.assignedToUserChanged.emit(this.assignedTo);
    // this.eventSerivce.broadcast(EventEmitterType.updateAssignToFilter, this.assignedTo);
  }
  filterLabel(filter: Category) {
    // if (filter.id === 1) {
    //     return 'show-part-number'
    // } else if (filter.id === -4) {
    //     return 'show-device-status'
    // }
    // else if (filter.id === -7) {
    //     return 'show-install-status'
    // } else {
    //     return ''
    // }
    return {
      'show-part-number': filter.id === 2,
      'show-device-status': filter.id === 8,
      'show-install-status': filter.id === 11
    }
  }
  filterCategoryChanged(item: any) {
    item.isChecked = !item.isChecked;
    this.filterCategoryChange.emit(this.filterCategory);
  }
  onClickSwitchChangeEvent(item: FilterCategory) {
    item.isChecked = !this.hideLiveDeviceToggle;
    this.hideLiveDeviceToggle = item.isChecked
    this.liveDeviceToggle.emit(item.isChecked);
  }

  pinSizeChange(e: any) {
    this.pinSizeValueChanged.emit(DBDevicePinSizeValue[this.pinSize]);
  }
}

<div class="equipment-counter" (click)="$event.stopPropagation();clicked($event)">
    <div class="counter-tabs equipment-count">
        <tabset type="tabs" #tabs>
            <tab [active]="selectedFilterCountTab === 'count'" (selectTab)="onSelect($event)" heading="Count"
                customClass="customCount" #asideCounter>
                <accordion [closeOthers]="false" class="counter-accordian">
                    <accordion-group *ngFor="let item of categoryCount;first as first" #mark
                        (click)="getAccordianClass(mark,item.name)"
                        [isOpen]="isAccordianOpen && isAccordianOpen.indexOf(item.name) > -1">
                        <div accordion-heading class="clearfix">
                            <div class="slide-toggle d-flex justify-content-between" title="{{item.name}}"
                                role="button">
                                <span> {{item.name | wrap:80}}
                                </span>
                                <a *ngIf="stage !== 'Archive';else archiveCategoryCount" href="javascript:void(0)"
                                    class="pull-right" (click)="keepDefault($event,item)">{{item.count}}</a>
                                <ng-template #archiveCategoryCount>
                                    <span class="pull-right">{{item.count}}</span>
                                </ng-template>
                            </div>
                        </div>
                        <div role=" tabpanel ">
                            <div class="part-number-listing clearfix listing-floor-buildings "
                                *ngFor="let part of item.parts ">
                                <span class="part-name " title="{{part.name}} ">{{part.name | wrap:18}}</span>
                                <span class="part-count ">{{part.count}}</span>
                            </div>
                        </div>
                    </accordion-group>
                </accordion>
                <div class="row">
                    <div class="col-xl-12 equip-total d-flex justify-content-between" *ngIf="projectId">
                        Devices Total
                        <div class="pull-right "
                            *ngIf="totalDeviceEquipmentCount> 0; else zeroDeviceEquipmentCountTemplate">
                            <a *ngIf="stage !== 'Archive';else archiveDeviceCount" href="javascript:void(0)">
                                <span (click)="totalClicked(true,false)">
                                    {{totalDeviceEquipmentCount}}
                                </span>
                            </a>
                            <ng-template #archiveDeviceCount>

                                {{totalDeviceEquipmentCount}}

                            </ng-template>
                        </div>
                        <ng-template #zeroDeviceEquipmentCountTemplate>
                            <div class="pull-right">
                                <a href="javascript:void(0)">

                                    {{totalDeviceEquipmentCount}}

                                </a>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-xl-12 total-counts d-flex justify-content-between" *ngIf="projectId">
                        Tasks Total
                        <div class="pull-right "
                            *ngIf="totalTaskEquipmentCount> 0; else zeroTaskEquipmentCountTemplate">
                            <a *ngIf="stage !== 'Archive';else archiveTaskCount" href="javascript:void(0)">
                                <span (click)="totalClicked(false,true)">
                                    {{totalTaskEquipmentCount}}
                                </span>
                            </a>
                            <ng-template #archiveTaskCount>

                                {{totalTaskEquipmentCount}}

                            </ng-template>
                        </div>
                        <ng-template #zeroTaskEquipmentCountTemplate>
                            <div class="pull-right">
                                <a href="javascript:void(0)">

                                    {{totalTaskEquipmentCount}}

                                </a>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-xl-12 total-counts d-flex justify-content-between" *ngIf="projectId">
                        Hours Total
                        <div class="pull-right ">
                            <span>
                                {{totalHours | number: '1.0-3'}}
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-12 total-counts d-flex justify-content-between">
                        <span *ngIf="!projectId">Total</span>
                        <span *ngIf="projectId" title="Planned and In Progress Devices and Tasks">All
                            Pending (Punch List)</span>
                        <div class="pull-right " *ngIf="totalEquipmentCount> 0; else zeroDeviceCountTemplate">
                            <a *ngIf="stage !== 'Archive';else archiveCount" href="javascript:void(0)">
                                <span (click)="totalClicked()">
                                    {{totalEquipmentCount}}
                                </span>
                            </a>
                            <ng-template #archiveCount>

                                {{totalEquipmentCount}}

                            </ng-template>
                        </div>
                        <ng-template #zeroDeviceCountTemplate>
                            <div class="pull-right">
                                <a href="javascript:void(0)">

                                    {{totalEquipmentCount}}

                                </a>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-xl-12 total-tickets d-flex justify-content-between" *ngIf="!projectId && isTicketAccessible">
                        <span>
                            Tickets
                        </span>
                        <div class="pull-right" *ngIf="serviceTicketCountForFloor > 0; else zeroTicketCountTemplate">
                            <a href="javascript:void(0)" (click)="goToTickets()">

                                {{serviceTicketCountForFloor}}

                            </a>
                        </div>
                        <ng-template #zeroTicketCountTemplate>
                            <div class="pull-right">
                                <a href="javascript:void(0)"> {{serviceTicketCountForFloor}}
                                </a>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </tab>
            <tab [active]="selectedFilterCountTab === 'filter'" (selectTab)="onSelect($event)" heading="Filters (21)"
                #asideFilter>
                <accordion>
                    <accordion-group class="accordin " [isOpen]="true">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Viewing Preferences
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li class="d-flex pin-slider-wrapper">
                                    <div class="pin-slider-card device-card d-flex align-items-center">
                                        <label class="label-width mt-3">
                                            Pin Size
                                        </label>
                                        <div class="form-group pin-color-filter-group">
                                            <ngx-slider [(value)]="pinSize" #ngxPinSizeSlider
                                                (userChangeEnd)="pinSizeChange($event)"
                                                [options]="pinSizeOptions"></ngx-slider>
                                        </div>
                                    </div>
                                </li>
                                <li class="d-flex pin-slider-wrapper">
                                    <div class="pin-slider-card device-card d-flex align-items-center">
                                        <label class="label-width">
                                            Pin Color
                                        </label>
                                        <div class="form-group pin-color-filter-group">
                                            <select class="form-control" [(ngModel)]="pinColorType" name="active"
                                                (change)="pinColorChanged($event)">
                                                <option value="Device Color"
                                                    [selected]="(pinColorType === 'Device Color'?true:false)">Device
                                                    Color</option>
                                                <option *ngIf="projectId &&projectId > 0" value="Install Status"
                                                    [selected]="(pinColorType === 'Install Status'?true:false)">Install
                                                    Status
                                                </option>
                                                <option value="Operational Status"
                                                    [selected]="(pinColorType === 'Operational Status'?true:false)">
                                                    Operational
                                                    Status</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="d-flex device-card-wrapper" *ngIf="projectId">
                                    <div class="pin-slider-card device-card d-flex align-items-center">
                                        <label class="label-width">
                                            Assigned To
                                        </label>
                                        <div class="form-group pin-color-filter-group">
                                            <select class="form-control" [(ngModel)]="assignedTo" name="active"
                                                (change)="assignedToChanged($event)">
                                                <option value="null" [selected]="(assignedTo === null?true:false)">
                                                    Anyone
                                                </option>
                                                <option [ngValue]="user?.user?.id"
                                                    [selected]="(assignedTo === user?.user?.id?true:false)">Me</option>
                                                <option *ngFor="let item of installedByUsers"
                                                    [ngStyle]="{'display': item.isRemoved? 'none': 'block'}"
                                                    [ngValue]="item.key">{{item.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <ng-container
                                    *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'vp', showPlanMarkupFilter: showPlanMarkupFilter};">
                                    <li *ngIf="item.category.id !== 6" [ngClass]="filterLabel(item.category)">
                                        <so-checkbox [isChecked]="item.isChecked"
                                            (onChange)="filterCategoryChanged(item)" [label]="item.category.displayName"
                                            [id]="'category' + item.category.id" [ngClass]="'lbl-check'"></so-checkbox>

                                    </li>
                                    <li *ngIf="item.category.id === 6 && isLiveSystemAccessForCustomer && isLiveDeviceAvailable"
                                        class="d-flex align-items-center pin-slider-wrapper" style="cursor: pointer;"
                                        (click)="onClickSwitchChangeEvent(item)">
                                        <ng-container *ngIf="item.category.name !== 'Show Full Size Device Icons'">
                                            <label class="label-width me-2 live-device-toggle-title">
                                                Live Devices
                                            </label>
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input" type="checkbox" role="switch"
                                                    id="flexSwitchCheckCheckedForLiveDevice"
                                                    [checked]="hideLiveDeviceToggle">
                                            </div>
                                            <label class="live-device-toggle-label ms-2">
                                                {{hideLiveDeviceToggle ? 'Visible' : 'Hidden'}}
                                            </label>
                                        </ng-container>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                System Type
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'st'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox *ngIf="item.category.name !== 'Show Plan Markup'"
                                        [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.name" [id]="'category_st_' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin ">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Operational Status
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'op'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.displayName" [id]="'category' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin " *ngIf="projectId">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Install Status
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'is'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.displayName" [id]="'category' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                </accordion>
            </tab>
        </tabset>
    </div>
</div>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import * as _ from 'lodash';
import { take } from 'rxjs';
import { EventEmitterType, EventService } from '@SiteOwl/core';

@Directive({
  selector: '[soCoverageRotate]'
})
export class CoverageRotateDirective implements OnChanges, OnDestroy, OnInit {

  timer = null;
  image: any = null;
  floor: any = null;
  prevX = -1;
  prevY = -1;
  newSVGWidth: any;
  calNewCoverageRangeForData: any;
  allowToDrop = false;


  //Rotate Variables
  _x = null;
  _y = null;
  rotateActive = false;
  angle: any = 0;
  rotation: any = 0;
  startAngle = 0;
  center = {
    x: 0,
    y: 0
  };
  R2D = 180 / Math.PI;
  degree: any = 0;
  div: any;
  rot: any;
  item: any;
  previousbb: any;
  isFirst: any = true;
  dragEventAdded = false;

  @Input() device: any;
  @Input() isEdit: any = true;
  @Output() updateCoverage = new EventEmitter();
  private readonly destroy$ = new Subject<void>();
  rotated = false;
  constructor(private elementRef: ElementRef,
    private eventService: EventService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.device = changes['device'].currentValue;
      this.getSetUpRotate();
    }
  }
  ngOnInit(): void {
    this.initDragEvents();
    this.elementRef.nativeElement.style.cursor = 'grab'
  }
  getSetUpRotate() {
    // this.elementRef.nativeElement.style.cursor = 'grab'
    // this.item = this.device;
    this.div = document.getElementById('main-svg-' + this.device.id + '-' + this.device.name);

    if (this.isFirst && this.div !== null) {
      this.isFirst = false;
      this.previousbb = this.div.getBoundingClientRect()
    } else {
      if (this.isFirst) {
        const timeOut = setTimeout(() => {
          this.getSetUpRotate();
          clearTimeout(timeOut)
        }, 100);
      }
    }
    if (this.div !== null) {
      this.div.style.position = 'absolute';
      // this.initDragEvents();
    }
  }
  initDragEvents() {
    this.dragEventAdded = true;
    const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
    // if (!this.device.parentId && !this.isNew) {
    const dragEnd$ = fromEvent<MouseEvent>(window, "mouseup")
    const dragElementEnd$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup")
    // }
    const drag$ = fromEvent<MouseEvent>(document.body, "mousemove").pipe(takeUntil(dragEnd$), takeUntil(dragElementEnd$), takeUntil(this.destroy$));


    dragStart$.pipe(takeUntil(this.destroy$), switchMap((start: any) => {
      dragEnd$.pipe(take(1))
        .subscribe(($event: any) => {
          this.dragEnd($event);
        });
      this.dragStart(start)
      return drag$.pipe(map(move => {
        move.preventDefault();
        this.dragging(move);
        return move;
      }), takeUntil(dragEnd$), takeUntil(dragElementEnd$));
    })).subscribe();

    dragElementEnd$.pipe(takeUntil(this.destroy$))
      .subscribe(($event: any) => {
        this.dragEnd($event);
      });
  }
  dragStart(event: any) {
    this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, true);
    event.stopPropagation();
    this.angle = 0;
    this.startAngle = this.device.coverageDirection;
    this.prevX = event.clientX;
    this.prevY = event.pageY;
    const bb: any = document.getElementById('main-svg-' + this.device.id + '-' + this.device.name)?.getBoundingClientRect();
    const bound: any = {
      top: bb.top === 0 ? this.previousbb.top : bb.top,
      left: bb.left === 0 ? this.previousbb.left : bb.left,
      height: bb.height === 0 ? this.previousbb.height : bb.height,
      width: bb.width === 0 ? this.previousbb.width : bb.width,
    }
    const t = bound.top
    const l = bound.left
    const h = bound.height
    const w = bound.width
    // let x, y;
    this.center = {
      x: l + (w / 2),
      y: t + (h / 2)
    };
    const x = event.clientX - this.center.x;
    const y = event.clientY - this.center.y;
    this.startAngle = this.R2D * Math.atan2(y, x);
    this.rotateActive = true;
    // this.startAngle = this.device.coverageDirection;
  }
  dragging(event: any) {
    if (this.rotateActive === true && (Math.abs(this.prevX - event.screenX) > 2 || Math.abs(this.prevY - event.screenY) > 2)) {
      this.rotate(event);
      this.rotated = true;
    }
  }
  dragEnd(event: any) {
    if (this.rotateActive && this.rotated) {
      this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, false);
      event.stopPropagation();
      event.preventDefault();
      this.angle += this.rotation;
      this.rotateActive = false;
      this.rotated = false;
      let calDirection = this.device.coverageDirection + this.angle;
      if (calDirection < 0) {
        calDirection = 360 + calDirection
      }
      if (calDirection > 360) {
        calDirection = calDirection - 360
      }
      this.device.coverageDirection = Math.round(calDirection);
      const svgEle: any = document.getElementById('main-svg-' + this.device.id + '-' + this.device.name);
      if (svgEle) {
        svgEle.style.transform = "";
        svgEle.style.webkitTransform = "";
      }
      const svgRotateEle: any = document.getElementById('arc_div_R_' + this.device.id + '_' + this.device.name);
      if (svgRotateEle) {
        svgRotateEle.style.opacity = '0';
      }
      const svgLeftEle: any = document.getElementById('arc_div_LeftSide_' + this.device.id + '_' + this.device.name);
      if (svgLeftEle) {
        svgLeftEle.style.opacity = '0';
      }
      const svgRightEle: any = document.getElementById('arc_div_RightSide_' + this.device.id + '_' + this.device.name);
      if (svgRightEle) {
        svgRightEle.style.opacity = '0';
      }
      const svgRangeEle: any = document.getElementById('arc_div_' + this.device.id + '_' + this.device.name);
      if (svgRangeEle) {
        svgRangeEle.style.opacity = '0';
      }
      this.updateCoverage.emit(this.device);
    }
  }

  rotate(event: any) {
    if (this.rotateActive) {
      event.stopPropagation();
      const x = event.clientX - this.center.x,
        y = event.clientY - this.center.y,
        d = this.R2D * Math.atan2(y, x);
      this.rotation = d - this.startAngle;
      this.degree = this.angle + this.rotation;
      const svgEle: any = document.getElementById('main-svg-' + this.device.id + '-' + this.device.name);
      const animationId = requestAnimationFrame(() => {
        svgEle.style.transform = "rotate(" + (this.angle + this.rotation) + "deg)";
        svgEle.style.webkitTransform = "rotate(" + (this.angle + this.rotation) + "deg)";
        cancelAnimationFrame(animationId);
      });
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

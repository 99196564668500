/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { map, switchMap, Subject, take } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { DraggingPinSize } from '../constant/floor.constant';
import { FloorUtils } from '../service/floor-utils';
import * as _ from 'lodash';
import { EquipmentStatusForDevice, EventEmitterType, EventService } from '@SiteOwl/core';
import * as Sentry from '@sentry/angular-ivy'
@Directive({
  selector: '[soDraggableDevice]'
})
export class DraggableDeviceDirective implements OnDestroy {
  @Input() device: any;
  @Input() pinSize: any
  @Input() isNew = false;
  @Input() isEdit: any;
  @Input() zoomFactor: any;
  @Input() pinColorStatus: any;
  @Input() isFav: any;
  @Input() isProject: any;
  @Output() deviceUpdate = new EventEmitter();
  isDragStart = false;
  leftTopIsSet = false;
  private readonly destroy$ = new Subject<void>();
  dragEnd$: any;
  isDragging!: boolean;
  mouseDownX: any;
  mouseDownY: any;
  dragHoverElement: any;
  isDragEventsInitialize = false;
  secondaryDiv: any;
  floorDeviceContainer: any;
  boundingClientRect: any;
  floorDeviceContainerHeight: any;
  floorDeviceContainerWidth: any;
  bodyWidth: any;

  constructor(private elementRef: ElementRef,
    private eventService: EventService
  ) {
    Sentry.metrics.increment("device.dragging.done.called", 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEdit']) {
      // this.isEdit = _.cloneDeep(changes['isEdit'].currentValue)
      if (this.isEdit && !changes['isEdit'].previousValue) { // switch edit ON
        if (this.elementRef) {
          this.elementRef.nativeElement.style.cursor = 'grab'; // ?
        }
        this.initDrag();
      }
      if (!this.isEdit && changes['isEdit'].previousValue) { // switch edit OFF
        if (this.elementRef) {
          this.elementRef.nativeElement.style.cursor = 'pointer';   // ??
        }
      }
    }
  }
  initDrag() {
    if (!this.isDragEventsInitialize) {
      this.floorDeviceContainer = document.getElementById('floorDeviceContainer');
      this.isDragEventsInitialize = true;
      const mouseout$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseout");
      const mouseover$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseover");
      mouseout$.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
        this.eventService.broadcast(EventEmitterType.floorEquipmentOver, false);
      })
      mouseover$.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
        this.eventService.broadcast(EventEmitterType.floorEquipmentOver, true);
      })
      const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
      if (!this.device.parentId && !this.isNew && !this.isFav) {
        this.dragEnd$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup")
      }

      const dragWindowEnd$ = fromEvent<MouseEvent>(document.body, "mouseup")
      const drag$ = fromEvent<MouseEvent>(document.body, "mousemove").pipe(takeUntil(this.destroy$));

      dragStart$.pipe(takeUntil(this.destroy$), switchMap((start) => {
        dragWindowEnd$.pipe(take(1)).subscribe((event: MouseEvent) => {
          if (this.isDragging) {
            this.deviceDragEnd(event);
          }
        });
        this.deviceDragStart(start)
        return drag$.pipe(
          map(move => {
            move.preventDefault();
            this.deviceDragging(move);
            return move;
          }), takeUntil(this.dragEnd$), takeUntil(this.destroy$));
      })).subscribe();

    }
  }
  deviceDragStart(event: any) {
    if (event.which === 1 && !document.getElementById('context-menu')?.contains(event.target) && event.target.id !== 'dragReOrderFavorite' && event.target.id !== 'dragReOrderFavoriteEm') {
      event.stopPropagation();
      if (this.isEdit) {
        this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, true);
        this.mouseDownX = event.screenX;
        this.mouseDownY = event.screenY;
        this.boundingClientRect = this.floorDeviceContainer.getBoundingClientRect();
        this.isDragStart = true;
        this.isDragging = false;
        event.target.style.zIndex = '2';
        this.leftTopIsSet = false;
        this.floorDeviceContainerWidth = this.floorDeviceContainer.clientWidth;
        this.floorDeviceContainerHeight = this.floorDeviceContainer.clientHeight;
        this.bodyWidth = document.body.clientWidth;
        // if (this.device.parentId || this.isNew || this.isFav) {
        this.generateSecondaryDiv(event);
        // }
      }
    }
  }

  deviceDragging(event: any) {
    if (this.isDragStart && (Math.abs(this.mouseDownX - event.screenX) > 10 || Math.abs(this.mouseDownY - event.screenY) > 10)) {
      this.isDragging = true;
      const animationId = requestAnimationFrame(() => {
        if (this.secondaryDiv) {
          this.secondaryDiv.style.opacity = '0.7';
          this.secondaryDiv.style.display = 'block';
        }
        cancelAnimationFrame(animationId)
      });
      if (!this.isNew && !this.isFav && !this.device.parentId) {
        this.elementRef.nativeElement.style.opacity = '0';
      }
      const deviceDoc: any = this.secondaryDiv;
      if (deviceDoc) {
        deviceDoc.hidden = true;
        const elemBelow: any = document.elementFromPoint(event.clientX, event.clientY);
        if (elemBelow) {
          const droppableBelow = elemBelow.closest('.drag-icon');
          if (this.dragHoverElement && droppableBelow !== this.dragHoverElement) {
            this.dragHoverElement.classList.remove('on-drag-hover')
          }
          this.dragHoverElement = droppableBelow;
          if (droppableBelow && deviceDoc != droppableBelow) {
            droppableBelow.classList.add('on-drag-hover')
          }
        }

        if (!this.leftTopIsSet) {
          const animationId = requestAnimationFrame(() => {
            deviceDoc.style.left = 0;
            deviceDoc.style.top = 0;
            deviceDoc.style.zIndex = '999'
            deviceDoc.style.opacity = '0.7';
            deviceDoc.classList.add('dragging')
            cancelAnimationFrame(animationId)
          });
          this.leftTopIsSet = true;
        }
        let draggingSize = DraggingPinSize[this.pinSize.toString()];
        draggingSize = this.zoomFactor > 1 ? (draggingSize / this.zoomFactor) : draggingSize / this.zoomFactor;
        const left = ((100 * (event.clientX - (this.boundingClientRect.left - 2)) / this.floorDeviceContainerWidth)).toFixed(5) + '%'
        const top = ((100 * (event.clientY - (this.boundingClientRect.top - 48)) / this.floorDeviceContainerHeight) - ((draggingSize * (1366)) / (this.bodyWidth)) + (2 / this.zoomFactor)).toFixed(5) + '%';
        const leftPercentage = parseFloat(left); // Example percentage for left
        const topPercentage = parseFloat(top); // Example percentage for top

        const containerWidth = this.floorDeviceContainerWidth;
        const containerHeight = this.floorDeviceContainerHeight;

        // Calculate pixel values from percentages
        const leftPixels = (leftPercentage / 100) * containerWidth;
        const topPixels = (topPercentage / 100) * containerHeight;
        const animationId = requestAnimationFrame(() => {
          deviceDoc.style.transform = `translate3d(${(leftPixels)}px, ${topPixels}px,0)`;
          deviceDoc.hidden = false;
          if (parseFloat(left) < 0 || parseFloat(left) > 100 || parseFloat(top) < 0 || parseFloat(top) > 100) {
            deviceDoc.style.cursor = 'not-allowed';
          } else {
            deviceDoc.style.cursor = 'grabbing';
          }
          cancelAnimationFrame(animationId)
        });
      }
    }
  }

  deviceDragEnd(event: any) {
    Sentry.metrics.increment("device.dragging.done.called", 1);
    this.isDragStart = false;
    this.leftTopIsSet = false;
    if (this.isDragging) {
      event.stopPropagation();
      const contextDropForm: any = document.getElementById('contextDropForm');
      if (contextDropForm) {
        contextDropForm.style.opacity = 0;
      }
      event.target.style.zIndex = '';
      const equipmentToolTip: any = document.getElementById('equipment-tooltip-' + this.device.id);
      if (equipmentToolTip) {
        equipmentToolTip.style.opacity = 1;
      }
      if (!this.isNew && !this.isFav && !this.device.parentId) {
        this.elementRef.nativeElement.style.opacity = '1';
      }
      const deviceDoc: any = document.getElementById('secondary_drag');
      if (deviceDoc) {
        deviceDoc.style.opacity = '1';
        deviceDoc.style.zIndex = '';
      }
      let isSecondaryDropped = false;
      let droppingTarget: any = '';
      if (this.dragHoverElement) {
        droppingTarget = this.dragHoverElement.id ? (this.dragHoverElement.id.split('_')[1]) : '';
        if (!isNaN(parseInt(droppingTarget)) && !isNaN(parseFloat(droppingTarget))) {

          if (droppingTarget !== '' && parseInt(droppingTarget) === 0) {
            droppingTarget = parseFloat(droppingTarget);
          } else {
            droppingTarget = parseInt(droppingTarget);
          }
          if (droppingTarget !== this.device.id) {
            isSecondaryDropped = true;
          }
        }
        this.dragHoverElement.classList.remove('on-drag-hover')
      }
      const deviceContainer: any = document.getElementById('floorDeviceContainer');
      let draggingSize = DraggingPinSize[this.pinSize.toString()];
      draggingSize = this.zoomFactor > 1 ? (draggingSize / this.zoomFactor) : draggingSize / this.zoomFactor;
      if (deviceDoc) {
        deviceDoc.style.left = ((100 * (event.clientX - (deviceContainer.getBoundingClientRect().left - 2)) / deviceContainer.clientWidth)).toFixed(5) + '%'
        deviceDoc.style.top = ((100 * (event.clientY - (deviceContainer.getBoundingClientRect().top - 48)) / deviceContainer.clientHeight) - ((draggingSize * (1366)) / (document.body.clientWidth)) + (2 / this.zoomFactor)).toFixed(5) + '%';
        const timeout = setTimeout(() => {
          deviceDoc.classList.remove('dragging')
          clearTimeout(timeout);
        }, 0)
      }
      const device: any = _.cloneDeep(this.device);
      if (this.isDragging && parseFloat(deviceDoc.style.left) >= 0 && parseFloat(deviceDoc.style.left) <= 100 && parseFloat(deviceDoc.style.top) >= 0 && parseFloat(deviceDoc.style.top) <= 100) {
        device.imageLeft = deviceDoc.style.left;
        device.imageTop = deviceDoc.style.top;
        if (this.isNew || this.isFav) {
          device.positionDroppingClient = {
            x: event.clientX,
            y: event.clientY
          };
          device['tempNewId'] = Math.random();
        }
        this.deviceUpdate.emit({ device: device, isSecondaryDropped: isSecondaryDropped, droppingTarget: droppingTarget, isFav: this.isFav })

      } else {// if device goes out of boundary i.e .image then we set it to previous left top.
        if (deviceDoc) {
          deviceDoc.style.left = this.device.imageLeft;
          deviceDoc.style.top = this.device.imageTop;
        }
      }
      if (deviceDoc) {
        deviceDoc.style.cursor = 'grab';
      }
    }
    this.isDragging = false;
    if (document.getElementById('secondary_drag')) {
      const dummyDragRemove: any = document.getElementById('secondary_drag');
      dummyDragRemove.innerHTML = ''
      dummyDragRemove.remove();
      this.secondaryDiv = undefined;
    }
    this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, false);
  }
  generateSecondaryDiv(event: any) {
    const div = document.createElement('div');
    div.setAttribute('id', 'secondary_drag');
    div.setAttribute('class', 'drag-container');
    div.style.opacity = '0';
    div.style.display = 'none';
    div.style.zIndex = '999';
    const divInner1 = document.createElement('div');
    divInner1.setAttribute('class', 'drag-scale scale-' + this.pinSize);

    const divInner = document.createElement('div');
    divInner.setAttribute('class', 'drag-icon');

    if (typeof this.device.applyClass === 'string') {
      divInner.classList.add(this.device.applyClass);
    } else if (typeof this.device.applyClass === 'object') {
      const keyValues = this.device.applyClass;
      for (const property in keyValues) {
        if (keyValues[property]) {
          divInner.classList.add(property);
        }
      }
    }
    if (!this.isNew && !this.isFav) {
      divInner.style.background = this.device.displayPinColor;
      div.style.top = this.device.imageLeft;
      div.style.left = this.device.imageTop;
    } else {
      this.device.isNew = true;
      this.device.installStatus = EquipmentStatusForDevice.PLANNED;
      this.device.status = this.isProject ? EquipmentStatusForDevice.NOTWORKING : EquipmentStatusForDevice.OPERATIONAL;
      divInner.style.background = FloorUtils.displayPinColor(this.device, this.device, this.pinColorStatus, false);
      const deviceContainer: any = document.getElementById('floorDeviceContainer')
      let draggingSize = DraggingPinSize[this.pinSize.toString()];
      draggingSize = this.zoomFactor > 1 ? (draggingSize / this.zoomFactor) : draggingSize / this.zoomFactor;
      div.style.left = ((100 * (event.clientX - (deviceContainer.getBoundingClientRect().left - 2)) / deviceContainer.clientWidth)).toFixed(5) + '%';
      div.style.top = ((100 * (event.clientY - (deviceContainer.getBoundingClientRect().top - 48)) / deviceContainer.clientHeight) - ((draggingSize * (1366 / this.zoomFactor)) / (document.body.clientWidth / this.zoomFactor))).toFixed(5) + '%';
    }
    if (this.device.type === 'task') {
      const css: any = {
        'Operation-task': FloorUtils.checkOperationalTask(this.device, true),
        'OperationalWithIssue-task': FloorUtils.checkOperationalWithIssueTask(this.device, true),
        'NotWorking-task': FloorUtils.checkNotWorkingTask(this.device, true),
        'Archive-task': FloorUtils.checkTaskArchived(this.device, true)
      }
      const keyValues = css;
      for (const property in keyValues) {
        if (keyValues[property]) {
          divInner.classList.add(property);
        }
      }
      const taskDiv = document.createElement('div');
      taskDiv.classList.add('task-arrow');
      taskDiv.style.borderTopColor = FloorUtils.displayPinColor(this.device, this.device, this.pinColorStatus, false);
      divInner.appendChild(taskDiv);
    }
    if (this.device.type === 'task') {
      divInner.style.transform = 'rotate(0deg)';
      divInner.style.webkitTransform = 'rotate(0deg)';
    } else {
      divInner.style.transform = 'rotate(-45deg)';
      divInner.style.webkitTransform = 'rotate(-45deg)';
    }
    const iTag = document.createElement('i');
    iTag.setAttribute('id', 'i_drag_' + this.device.id);
    const classForItag = this.isNew && !this.isFav ? this.device.iconName.split(' ') : this.device.equipment.iconName.split(' ');
    classForItag.forEach((element: any) => {
      iTag.classList.add(element);
    });
    if (this.device.type === 'task') {
      iTag.style.transform = 'rotate(0deg)';
      iTag.style.webkitTransform = 'rotate(0deg)';
    } else {
      iTag.style.transform = 'rotate(45deg)';
      iTag.style.webkitTransform = 'rotate(45deg)';
    }
    divInner.appendChild(iTag);
    divInner1.appendChild(divInner)
    div.appendChild(divInner1);
    document.getElementById('floorDeviceContainer')?.appendChild(div);
    this.secondaryDiv = div
    this.dragEnd$ = fromEvent<MouseEvent>(this.secondaryDiv, "mouseup");
    const dragEndElement$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup");
    dragEndElement$.pipe(take(1))
      .subscribe((event: MouseEvent) => {
        this.deviceDragEnd(event);

      });
    this.dragEnd$.pipe(take(1))
      .subscribe((event: MouseEvent) => {
        this.deviceDragEnd(event);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

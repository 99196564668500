import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'ticket'
})
export class TicketPipe implements PipeTransform {

  transform(items: any[], filter: any): any[] {
    if (filter && filter.equipmentDetails && !filter.equipmentDetails.isArchived) {
      items = items.filter(x => !x.isArchived);
      if (filter.canShowClosedTicket) {
        items = items.filter(x => x.status !== 'Closed');
      }
    } else if (filter && filter.equipmentDetails && filter.equipmentDetails.isArchived) {
      if (filter.canShowClosedTicket) {
        items = items.filter(x => x.status !== 'Closed');
      }
    }
    items = orderBy(items, ['subject'], ['asc']);

    return items;
  }

}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Location } from "@angular/common";
import {
  AppService,
  AuthService,
  ButtonEnableDisableService,
  CommonService,
  CustomerService,
  DevicePriority,
  DeviceTypes,
  EquipmentStatusForDevice,
  EventEmitterType,
  EventService,
  FloorDataTransferService,
  FloorEquipment,
  floorPlanDeviceUsedKeys,
  FloorPlanLoadService,
  liveSystemRoles,
  LoaderService,
  MessageService,
  MixpanelObjectService,
  OptimizedReport,
  PinColorStatus,
  PinColorStatusType,
  PrintOutType,
  ProjectPermissions,
  projectRoles,
  ProjectService,
  ProjectStage,
  Roles,
  SiteService,
  stateManage,
  Utilities
} from '@SiteOwl/core';
import * as _ from "lodash";
import { FloorUtils } from '../service/floor-utils';
import { FloorPlanService } from '../service/floor-plan.service';
import { DevicePinSizeValue, floorPlanFavDeviceUsedKeys } from '../constant/floor.constant';
import { EquipmentDetailsService } from '../service/equipment-details.service';
import { Discard } from '../constant/discard.constant';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CommonPopupComponent, MultiplanReportComponent, PixieComponent, ReportConfigColumnComponent } from '@SiteOwl/UI';
import { CoverageCone } from '../utils/coverage-cone';
import { FloorCreateTicketComponent } from './floor-create-ticket/floor-create-ticket.component';
import { FloorLinkTicketComponent } from './floor-link-ticket/floor-link-ticket.component';
import { AddFlagComponent } from './add-flag/add-flag.component';
import { RemoveFlag } from '../constant/remove-flag.constant';
import { RemoveHub } from '../constant/remove-hub.constant';
import { ArchiveHubWithChild } from '../constant/archive-hub-with-child.constant';
import { ArchiveHub } from '../constant/archive-hub.constant';
import { ArchiveDeviceWithChild } from '../constant/archive-device-with-child.constant';
import { ArchiveDevice } from '../constant/archive-device.constant';
import { UnarchiveDevice } from '../constant/unarchive-device.constant';
import { DeleteHub } from '../constant/delete-hub.constant';
import { concat, empty, fromEvent, Subject, Subscription, takeUntil } from 'rxjs';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { PrintOutPopupComponent } from '../component/print-out-popup/print-out-popup.component';
import { SyncPopupComponent } from '../sync-popup/sync-popup.component';
import { NumberedPlanReportComponent } from '../component/numbered-plan-report/numbered-plan-report.component';
import { IconPlanReportComponent } from '../component/icon-plan-report/icon-plan-report.component';
import moment from 'moment/moment';
import { SavePlanPopupComponent } from '../component/save-plan-popup/save-plan-popup.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { EquipmentHistoryComponent } from '../component/equipment-history/equipment-history.component';
import * as jsonpack from "jsonpack";
import { FloorIndexDbService } from '../service/floor-index-db.service';
import { FloorCategoryFilterPipe } from '../pipe/floor-category-filter.pipe';
import { DeleteDevice } from '../constant/delete-device.constant';
import { RoutingConfirmationComponent } from './routing-confirmation/routing-confirmation.component';
import { FloorDetailsGuard } from '../guard/floor-details.guard';
import * as Sentry from "@sentry/angular-ivy";
@Component({
  selector: 'so-floor-details',
  templateUrl: './floor-details.component.html',
  styleUrls: ['./floor-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorDetailsComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  imageZoomDefault = 100;
  zoomValue = '100%';
  imageZoom = 100;
  parentContainer: any;
  floorImageShadow: any;
  fitToScreen = true;
  zoomFactor = 1;
  prevImageZoom = 1;
  largerDimension!: number;
  floorImageContainer: any;
  isCoverage = false;
  planImage!: any;
  selectedFloor!: any;
  pinColorType: any = PinColorStatusType.DEVICECOLOR//'Operational Status';
  siteId: any;
  projectId: any;
  isProject = false;
  showDeviceName = true;
  showAbbreviatedName = true;
  showPartNumber = true;
  showArchived = false;
  filter: any;
  assignedToFilter: any;
  equipmentDetails: any = null;
  buildingDetails: any;
  deviceStatusData: any;
  floorId!: number;
  pinSize = 54;
  showCoverage: any;
  showConnectionLine: any;
  showArchivedDevice: any;
  hideClosedTickets: any;
  showPlanMarkup: any;
  showTicketAction: any;
  defaultFloorDetails: any;
  updatedFloorEquipmentValues: any[] = [];
  equipmentList: any = [];
  actionBtn = {
    edit: true,
    save: false,
  }
  permissions = {
    canEdit: false,
    canManage: false,
    canUpdateDevice: false,
    canDeleteOrArchive: false,
    canViewOnly: false
  }
  installedByUsers: any;
  buildingId: any;
  netWorkSwitches: any = [];
  baseNameCount: any = [];
  totalDiscardCount = 0;
  totalDeviceCount = 0;
  totalTaskCount = 0;
  bsModalRef?: BsModalRef;
  fontFamily = "'Font Awesome 5 Pro'";
  contextmenuX: any;
  contextmenuY: any;
  selectedItem: any;
  selectedChild: any;
  containerHeight!: number;
  containerWidth!: number;
  duplicatePos!: number;
  percentContextMenuPos!: number;
  unlinkedTicket: any;
  folderName: any;
  isStopApiCall = false;
  presignedURL: any;
  uploadNewFile: any = [];
  floorStage = 'Live Site';
  listDeviceData!: FloorEquipment;
  showFirstDragPopup!: boolean;
  showCountFilterPopup = false;
  tempEquipment!: FloorEquipment;
  private readonly destroy$ = new Subject<void>();
  @ViewChild(ContextMenuComponent) droppedContext!: ContextMenuComponent
  showPlanMarkupFilter: any;
  isLiveDeviceAvailable: any;
  selectedFilterCountTab: any;
  isCableEnable: any;
  filterCategoryData: any;
  hideLiveDevice = true;
  isLoadingDailyReport = false;
  countFilterClicked: any;
  floorImageUpdate = false;
  concatFilterChangedRequests: any;
  imgUploadedCnt = 0;
  uploadNewFloorFile: any = [];
  isConfigLoaded = false;
  feetValue!: any;
  bsModalPrintOutRef!: BsModalRef;
  bsModalConfigRef!: BsModalRef;
  columnConfig: any = [];
  contextOpen = false;
  defaultFavoriteEquipments: any = [];
  favoriteEquipmentData: any = [];
  toggleSecondary = false;
  isToNavigate = false;
  uploadFavJson: any = [];
  totalFavoriteCount: any;
  favUpload: any;
  isFavoriteUpdated = false;
  favReordered!: boolean;
  getTranslateCalledCount = 0;
  @ViewChild(EquipmentListComponent) equipmentFavs!: EquipmentListComponent;
  serviceTickets: any;
  liveDevicesFetched!: boolean;
  currentFloorLiveDevice: any;
  isLiveSystemAccessForCustomer: any;
  isLinkToTicket = false;
  initalBuildingFloorDropdownData: any;
  customerChangeAttemp: any;
  customerId: any;
  customerChangeAttemptFlag!: boolean;
  changedCustomerId!: number;
  isBuildingChanged = false;
  isPlanChanged = false;
  floorEquipmentSelectionFromHeader!: Subscription;
  logOutSubEvent!: Subscription;
  isLoggedOutAction!: boolean;
  equipmentId!: any;
  installedByUsersItems: any;
  defaultImageChangeBase64: any;
  previousImageLeft: any;
  previousImageTop: any;
  changeBuildingOrPlan: any = { change: false, e: null };
  updatingCoverageValue!: boolean;
  updateConnection = false;
  equipmentDetailsParent: any;
  isCameraOpenSub!: Subscription;
  isCameraOpen = false;
  unitOfMeasurements: any = 'Feet'
  isImageLoadingLocal = false;
  callNextDeviceEquipmentOpen = false;
  userSelectedNextDevice!: Subscription;
  callNextDeviceEquipmentDetails: any;
  floorPlanClicked = false;
  defaultFeetValue!: any;
  delAllFavorites!: Subscription;
  isShowLoader = false;
  routerSubscription: Subscription;
  syncStarted!: boolean;
  isTicketSubmitter = false;
  isProjectArchived = false;
  isSiteArchived = false;
  imageDraggedComplete = false;
  initCall = false;
  syncCompleted = false;
  isTicketAccessible = false;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private floorPlanService: FloorPlanService,
    private messageService: MessageService,
    private projectService: ProjectService,
    private siteService: SiteService,
    private customerService: CustomerService,
    private equipmentDetailService: EquipmentDetailsService,
    private authService: AuthService,
    public modalService: BsModalService,
    public loaderService: LoaderService,
    public btnService: ButtonEnableDisableService,
    private mixpanelService: MixpanelObjectService,
    private cdRef: ChangeDetectorRef,
    public route: ActivatedRoute,
    private eventService: EventService,
    private _location: Location,
    private floorIndexDBService: FloorIndexDbService,
    private floorDetailGuard: FloorDetailsGuard,
    private floorLoaderService: FloorPlanLoadService,
    private floorDataTransferService: FloorDataTransferService, private commonService: CommonService) {
    this.routerSubscription = router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          if (this.syncStarted) {
            return;
          } else {
            if (this.isProject) {
              this.router.navigate(['/secure/project', this.projectId, 'building', this.buildingId, 'floor', this.selectedFloor.id, 'details'], { replaceUrl: true });
            } else {
              this.router.navigate(['/secure/site', this.siteId, 'building', this.buildingId, 'floor', this.selectedFloor.id, 'details'], { replaceUrl: true });
            }
            this.checkNavigation();
          }
        }
      }
    })
  }


  ngOnInit() {
    localStorage.removeItem(stateManage.accordionState);
    localStorage.setItem(stateManage.scrollPosition, "0");
    localStorage.setItem(stateManage.accordionState, JSON.stringify({ 0: true }));
    Sentry.metrics.increment("set.cone.icon.called", 0);
    Sentry.metrics.distribution("open.equipment.panel", 0);
    this.initCall = true;
    this.floorLoaderService.isFloorLoading.next(this.initCall);
    this.planImage = undefined;
    this.cdRef.detectChanges();
    this.getInitialBuildingData();
    this.getDeviceStatusData();
    this.getEquipmentList();
    this.getEquipmentListData();
    this.stopSyncCallDueToNoInternet();
    this.isCableEnable = this.authService.getUserKeyData().isCabling;
    this.isTicketSubmitter = this.authService.getUserKeyData().customerRoleName === Roles.TicketSubmitter;
    this.isLiveSystemAccessForCustomer = this.authService.getUserKeyData().isLiveSystemAccessForCustomer;
    this.isTicketAccessible = this.authService.getUserKeyData().isTicketing;
    this.containerWidth = screen.width - (309 + 57);
    this.containerHeight = screen.height - 120;
    this.duplicatePos = (80 / this.containerWidth) * 100;
    this.customerId = this.authService.getCustomerId().customerId;
    const click$ = fromEvent(document, 'click');
    const mouseDown$ = fromEvent(document, 'mousedown');
    mouseDown$.pipe(takeUntil(this.destroy$)).subscribe(res => {

      this.previousImageLeft = this.floorImageContainer.style.left;
      this.previousImageTop = this.floorImageContainer.style.top
      this.closeFormContextMenu(res);
    });
    click$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.selectedChild = null;
      this.selectedItem = null;
      this.contextOpen = false;
      const imageLeft = this.floorImageContainer.style.left;
      const imageTop = this.floorImageContainer.style.top
      if (this.previousImageLeft === "" || this.previousImageTop === "" || this.previousImageLeft === null || this.previousImageTop === null || this.previousImageLeft === undefined || this.previousImageTop === undefined) {
        this.previousImageLeft = this.floorImageContainer.style.left;
        this.previousImageTop = this.floorImageContainer.style.top
      }
      if (this.equipmentDetails && !this.isCameraOpen && !this.updateConnection && !this.updatingCoverageValue && res.target.id !== '' && res.target.id !== 'parentContainer' && !this.imageDraggedComplete) {
        this.floorPlanClicked = true;
        this.closeEquipmentDetails();
      }
      this.imageDraggedComplete = false;
      if (imageLeft === this.previousImageLeft && imageTop === this.previousImageTop) {
        this.showCountFilterPopup = false;
        this.countFilterClicked = false;
      }
      this.cdRef.detectChanges();
    });
    this.customerChangeAttemp = this.eventService.subscribe(EventEmitterType.customerChangeAttemp, (payload: number) => {
      if (this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0 || this.isFavoriteUpdated) {
        this.eventService.broadcast(
          EventEmitterType.floorPlanSaveOnGoing,
          { data: true, isCallPending: true, customerId: this.customerId }
        );
        this.customerChangeAttemptFlag = true;

        const initialState: ModalOptions = {
          initialState: { totalDiscardCnt: this.totalDiscardCount }, class: Discard.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(RoutingConfirmationComponent, initialState)
        this.bsModalRef.content.saveFloorPlan.subscribe((p: any) => {
          if (p === true) {
            this.changedCustomerId = payload;
            this.bsModalRef?.hide();
            this.saveFloorPlan();
          } else {
            this.actionBtn.edit = true;
            this.actionBtn.save = false;
            if (this.equipmentDetails && this.equipmentDetails.id < 1) {
              this.equipmentDetails = null;
            }
            this.resetFloorPlan();
            this.bsModalRef?.hide();
            this.eventService.broadcast(EventEmitterType.floorPlanSaveOnGoing, { data: false, isCallPending: false, customerId: payload });
            this.customerChangeAttemptFlag = false;
          }
        })
      }
    }
    );
    this.floorEquipmentSelectionFromHeader = this.eventService.subscribe(EventEmitterType.floorEquipmentSelectionFromHeader, (res) => {
      if (res.parentId) {
        if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId).length > 0) {
          if (this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].categoryName).length > 0) {
            if (!this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].categoryName)[0].isChecked) {
              this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].categoryName)[0].isChecked = true;
            }
          }
          if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].type === 'device' && this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].status).length > 0) {
            if (!this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].status)[0].isChecked) {
              this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].status)[0].isChecked = true;
            }
          }
          if (this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].installStatus).length > 0) {
            if (!this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].installStatus)[0].isChecked) {
              this.filterCategoryData.filter((e: any) => e.category.name === this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0].installStatus)[0].isChecked = true;
            }
          }
        }
      }
      if (this.filterCategoryData.filter((e: any) => e.category.name === res.categoryName).length > 0) {
        if (!this.filterCategoryData.filter((e: any) => e.category.name === res.categoryName)[0].isChecked) {
          this.filterCategoryData.filter((e: any) => e.category.name === res.categoryName)[0].isChecked = true;
        }
      }
      if (res.type === 'device' && this.filterCategoryData.filter((e: any) => e.category.name === res.status).length > 0) {
        if (!this.filterCategoryData.filter((e: any) => e.category.name === res.status)[0].isChecked) {
          this.filterCategoryData.filter((e: any) => e.category.name === res.status)[0].isChecked = true;
        }
      }
      if (this.filterCategoryData.filter((e: any) => e.category.name === res.installStatus).length > 0) {
        if (!this.filterCategoryData.filter((e: any) => e.category.name === res.installStatus)[0].isChecked) {
          this.filterCategoryData.filter((e: any) => e.category.name === res.installStatus)[0].isChecked = true;
        }
      }
      this.filterCategoryChange(this.filterCategoryData);
      if (res.parentId) {
        if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId).length > 0) {
          if (!this.toggleSecondary) {
            this.openEquipmentDetail({ pageX: res.imageLeft, pageY: res.imageTop }, this.selectedFloor.floorEquipments.filter((e: any) => e.id === res.parentId)[0]);
          }
        }
        this.openEquipmentDetail({ pageX: res.imageLeft, pageY: res.imageTop }, res);
      } else {
        this.openEquipmentDetail({ pageX: res.imageLeft, pageY: res.imageTop }, res);
      }
    });
    this.logOutSubEvent = this.eventService.subscribe(
      EventEmitterType.beforeLogoutAction,
      (res) => {
        if (this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0) {
          const initialState: ModalOptions = {
            initialState: { totalDiscardCnt: this.totalDiscardCount }, class: Discard.dialogClass, ignoreBackdropClick: true
          };
          this.bsModalRef = this.modalService.show(RoutingConfirmationComponent, initialState)
          this.bsModalRef.content.saveFloorPlan.subscribe((p: any) => {
            if (p === true) {
              this.isLoggedOutAction = true;
              this.bsModalRef?.hide();
              this.saveFloorPlan();
            } else {
              this.actionBtn.edit = true;
              this.actionBtn.save = false;
              if (this.equipmentDetails && this.equipmentDetails.id < 1) {
                this.equipmentDetails = null;
              }
              if (this.authService.getUserKeyData().isLocalSaveEnable) {
                this.floorIndexDBService.deleteAllRecordsFromIndexDB();
              }
              this.isStopApiCall = false;
              this.totalDeviceCount = this.totalTaskCount = this.imgUploadedCnt = this.totalDiscardCount = 0;
              this.updatedFloorEquipmentValues = [];
              this.uploadFavJson = [];
              this.favUpload = [];
              this.uploadNewFile = [];
              this.presignedURL = [];
              this.isToNavigate = false;
              this.isFavoriteUpdated = false;
              this.bsModalRef?.hide();
              this.eventService.broadcast(EventEmitterType.logOutAction, true);
            }
          });
        } else {
          this.eventService.broadcast(EventEmitterType.logOutAction, true);
        }
      }
    );
    this.isCameraOpenSub = this.eventService.subscribe(EventEmitterType.cameraOpen, (res: any) => {
      this.isCameraOpen = true;
    })
    this.isCameraOpenSub = this.eventService.subscribe(EventEmitterType.endSynCallCompleted, (res: any) => {
      this.resetFloorPlan();
    })
    this.delAllFavorites = this.eventService.subscribe(EventEmitterType.deleteAllFavorites, (res: any) => {
      this.floorIndexDBService.deleteAllFavorites();
      this.getEquipmentListData();
      this.isFavoriteUpdated = false;
      this.uploadFavJson = [];
      this.favUpload = [];
      this.getUnSavedCount();
    })
    this.userSelectedNextDevice = this.eventService.subscribe(EventEmitterType.isImageLoadingLocal, (payload: any) => {
      this.isImageLoadingLocal = payload.isImageLoadingLocal;
      if (this.floorPlanClicked) {
        this.closeEquipmentDetails();
        this.floorPlanClicked = false;
      }
      if (this.callNextDeviceEquipmentOpen && this.callNextDeviceEquipmentDetails !== null && this.callNextDeviceEquipmentDetails !== undefined) {
        this.openEquipmentDetail(this.callNextDeviceEquipmentDetails.event, this.callNextDeviceEquipmentDetails.details);
      }
    })
    window.addEventListener('beforeunload', this.browserLeave.bind(this), { passive: true });
  }

  closeFormContextMenu(res?: any) {
    if (this.showFirstDragPopup && res) {
      const contextFormDiv: any = document.getElementById('contextDropForm');
      if (contextFormDiv) {
        if (!contextFormDiv.contains(res.target)) {
          localStorage.removeItem('stopDrag')
          this.droppedContext.saveDetails(false);
        }
      }
    }
  }
  ngAfterViewInit(): void {
    this.parentContainer = document.querySelector("#parentContainer");
    this.floorImageShadow = document.querySelector("#floorImageShadow");
    this.floorImageContainer = document.querySelector("#floorImageContainer");
    this.getFloorPlanConfiguration();

  }

  getInitialBuildingData() {
    this.activatedRoute.data.subscribe(
      ({ buildings }) => {
        this.planImage = undefined;
        this.cdRef.detectChanges();
        this.projectId = isNaN(parseInt(this.activatedRoute.snapshot.params['projectId'])) ? null : parseInt(this.activatedRoute.snapshot.params['projectId'])
        this.siteId = isNaN(parseInt(this.activatedRoute.snapshot.params['siteId'])) ? null : parseInt(this.activatedRoute.snapshot.params['siteId'])
        this.isProject = this.projectId ? true : false;
        this.permissions.canEdit = buildings.canEdit;
        this.permissions.canManage = buildings.canManage;
        this.permissions.canUpdateDevice = buildings.canUpdateDevice;
        if (buildings.isSiteArchived) {
          this.permissions.canViewOnly = buildings.canEdit && buildings.canManage && buildings.canUpdateDevice;
        } else {
          this.permissions.canViewOnly = !buildings.canEdit && !buildings.canManage && !buildings.canUpdateDevice;
        }
        this.floorStage = (!this.isProject && buildings.stage === null) ? 'Live Site' : buildings.stage

        let menu: any = 'site'
        if (this.isProject) {
          menu = this.floorStage === 'Design' ? 'design' : 'install'
        }
        this.commonService.removeItem('menu')
        this.commonService.setLocalStorageString('menu', menu)
        this.initialSetFloorPlan(buildings);
      });
    this.route.queryParams.subscribe((params) => {
      if (params["unsaved"] && localStorage.getItem('isSaveChangeRemains') && this.authService.getUserKeyData().isLocalSaveEnable) {
        if (this.isProject) {
          this._location.replaceState("/secure/project/" + `${this.buildingDetails.projectId}` + "/building/" + `${this.buildingId}` + "/floor/" + `${this.floorId}` + "/details");

        } else {
          this._location.replaceState("/secure/site/" + `${this.buildingDetails.siteId}` + "/building/" + `${this.buildingId}` + "/floor/" + `${this.floorId}` + "/details");
        }
        this.setDataFromIndexDB();
      }
      if (!Utilities.isEmpty(params["equipmentid"])) {
        this.equipmentId = +params["equipmentid"];
      }
    })
  }

  zoomChanged(event: any) {
    this.fitToScreen = false;
    this.zoomChangeWithButtonCall(event, false);
  }

  calculateFloorPlanZoom(fromDirective = false) {
    this.imageZoomDefault = Math.min(
      Math.round(
        (this.parentContainer.clientHeight * 100) /
        this.floorImageShadow.naturalHeight
      ),
      (this.parentContainer.clientWidth * 100) /
      this.floorImageShadow.naturalWidth
    );
    this.imageZoom = Math.min(
      Math.round(
        (this.parentContainer.clientHeight * 100) /
        this.floorImageShadow.naturalHeight
      ),
      (this.parentContainer.clientWidth * 100) /
      this.floorImageShadow.naturalWidth
    );
    this.checkScaleForFloorPlainInFeet();
    if (fromDirective && !this.syncCompleted) {
      this.initCall = true;
      this.floorLoaderService.isFloorLoading.next(this.initCall);
    } else if (fromDirective) {
      setTimeout(() => {
        this.syncCompleted = false;
      }, 0);
    }
    this.getZoomWidthFromImage(this.imageZoom);
    if (fromDirective) {
      const timer = setTimeout(() => {
        this.initCall = false;
        this.floorLoaderService.isFloorLoading.next(this.initCall);
        this.cdRef.detectChanges();
        clearTimeout(timer);
      }, 300);
    }
  }

  private getZoomWidthFromImage(data: any) {
    if (this.floorImageContainer && parseInt(this.floorImageContainer.clientHeight) > 0 && parseInt(this.floorImageContainer.clientWidth) > 0) {
      this.zoomValue = (data * this.floorImageShadow.naturalWidth) / 100 + "px";

      this.largerDimension = Math.max(parseInt(this.floorImageContainer.clientWidth), parseInt(this.floorImageContainer.clientHeight));
      if (this.initCall) {
        this.selectedFloor.floorEquipments = this.setDefaultValuesForSelectedFloor(this.selectedFloor.floorEquipments);
        this.detectFloorChanges();
      }
    }
  }

  zoomChangeWithButtonCall(isZoom: any, isFitToScreen: any) {
    if (isFitToScreen) {
      this.fitToScreen = true;
      this.zoomFactor = 1;
      this.imageZoom = _.cloneDeep(this.imageZoomDefault);
      if (this.equipmentDetails && this.equipmentDetails !== undefined && this.equipmentDetails.coverageArea) {
        const timeOut = setTimeout(() => {
          this.getTranslate(this.equipmentDetails);
          clearTimeout(timeOut);
        }, 1000);
      }
    } else {
      this.fitToScreen = false;
      if (typeof (isZoom) === 'object') {
        if (isZoom.isZoomIn) {
          this.zoomFactor = isZoom.tmpImageZoomFactor;
          this.imageZoom = isZoom.tmpImageZoom;
        }
        // zoom out
        else {
          this.prevImageZoom = this.imageZoom;
          this.imageZoom = isZoom.tmpImageZoom;
          if (this.prevImageZoom !== this.imageZoom) {
            this.zoomFactor = isZoom.tmpImageZoomFactor;
          }
        }
      } else {
        if (isZoom) {
          this.zoomFactor = this.zoomFactor * 1.08;
          this.imageZoom = this.imageZoom * 1.08;
        } else {
          this.prevImageZoom = this.imageZoom;
          this.imageZoom = this.imageZoom / 1.08;
          if (this.prevImageZoom !== this.imageZoom) {
            this.zoomFactor = this.zoomFactor / 1.08;
          }
        }
      }
      if (this.equipmentDetails && this.equipmentDetails.coverageArea && !this.actionBtn.edit && this.equipmentDetails && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice && ((this.projectId && this.equipmentDetails.projectId !== null) || !this.projectId) && (this.permissions.canEdit || this.permissions.canManage || this.permissions.canUpdateDevice)) {
        this.getTranslate(this.equipmentDetails);
      }
    }
    this.zoomValue = (this.imageZoom * this.floorImageShadow.naturalWidth) / 100 + "px";
    const timer = setTimeout(() => {

      this.previousImageLeft = this.floorImageContainer.style.left;
      this.previousImageTop = this.floorImageContainer.style.top;
      clearTimeout(timer);
    }, 0);
    this.checkScaleForFloorPlainInFeet(isZoom);
  }

  checkFloorContainsCoverageOrLine(isReset?: any) {
    this.isCoverage = this.selectedFloor.floorEquipments.filter((x: any) => (x.isCoverage && !x.isArchived) || (x.childFloorEquipments && x.childFloorEquipments.filter((y: any) => (y.isCoverage && !y.isArchived))).length > 0).length > 0 ? true : false;
    if (isReset) {
      this.selectedFloor.floorEquipments = _.cloneDeep(this.setDefaultValuesForSelectedFloor(this.selectedFloor.floorEquipments));
    }
  }

  setDefaultValuesForSelectedFloor(floorEquipments: any): any {
    console.log('setDefaultValuesForSelectedFloor', moment.now())
    const displaySize = this.largerDimension * 1;
    this.baseNameCount = [];
    floorEquipments.forEach((element: FloorEquipment) => {
      if (element.cableCoordinates !== null && element.cableCoordinates === "'[]'") {
        element.cableCoordinates = null;
      }
      element.isLiveDevice = element.isLiveDevice ? element.isLiveDevice : false;
      element = this.setDevices(element);
      element.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(element, element.imageLeft, element.imageTop)
      if (element.coverageArea) {
        element = this.setCoverageElements(element, displaySize)
      }
      if (element.networkSwitchLinkId) {
        element = this.setCableOrConnectionLine(element, null, true)
      }
      if (this.equipmentId) {
        if (this.equipmentId === element.id) {
          this.openEquipmentDetail({ pageX: element.imageLeft, pageY: element.imageTop }, element)
          this.equipmentId = null;
        }
      }
      if (!this.projectId || (this.projectId && element.projectId !== null)) {
        this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, element)
      }
      if (element.childFloorEquipments && element.childFloorEquipments.length > 0) {
        element.childFloorEquipments.forEach((child: FloorEquipment) => {
          if (child.cableCoordinates !== null && child.cableCoordinates === "'[]'") {
            child.cableCoordinates = null;
          }
          child = this.setDevices(child);
          if (child.coverageArea) {
            child = this.setCoverageElements(child, displaySize)
          }
          if (child.networkSwitchLinkId) {
            child = this.setCableOrConnectionLine(child, null, true)
          }
          child.isLiveDevice = child.isLiveDevice ? child.isLiveDevice : false;
          child.showChildTicketIcon = true;
          if (!this.isProject && FloorUtils.setTicketCustomClass(child).closed) {
            child.showChildTicketIcon = false;
          }
          if (!this.projectId || (this.projectId && element.projectId !== null)) {
            this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, child)
          }
          if (this.equipmentDetails && this.equipmentDetails.id === child.id) {
            this.equipmentDetails = child;
          }
          if (this.equipmentId) {
            if (this.equipmentId === child.id) {
              this.openEquipmentDetail({ pageX: child.imageLeft, pageY: child.imageTop }, child)
              this.toggleSecondary = true;
              this.equipmentId = null;
            }
          }
        });
      }
      if (this.equipmentDetails && this.equipmentDetails.id === element.id) {
        this.equipmentDetails = element;
      }
    });
    console.log('setDefaultValuesForSelectedFloor end', moment.now())
    return floorEquipments;
  }

  setDevices(device: FloorEquipment): FloorEquipment {
    Sentry.metrics.increment("set.devices.called", 1);
    let isSelectedDevice = false;
    if (device && !device.isArchived && this.equipmentDetails && this.equipmentDetails !== undefined && this.equipmentDetails !== null && (this.equipmentDetails.id === device.id || this.equipmentDetails.id === device.parentId)) {
      isSelectedDevice = true
    }
    const deviceObject = FloorUtils.equipmentDisplayObjects(device, this.pinColorType, this.isProject, this.showArchived, this.filterCategoryData, this.assignedToFilter, isSelectedDevice, this.isProjectArchived, this.isSiteArchived);
    device.displayPinColor = this.isProject && device.projectId === null ? 'rgba(0, 54, 74, var(--alpha))' : deviceObject.displayPinColor;
    device.displayBorderColor = deviceObject.displayBorderColor;
    device.displayCoverageConeColor = deviceObject.displayCoverageConeColor;
    device.displayPulse = deviceObject.displayPulse;
    device.tooltipClass = deviceObject.tooltipClass;
    device.isImage = device.images && device.images.length > 0 ? true : false;
    device.secondaryPopupPosition = {};
    device.secondaryDeviceCount = deviceObject.secondaryDeviceCount;
    device.classForSecondary = deviceObject.classForSecondary;
    device.deviceOpacity = deviceObject.deviceOpacity;


    device.badgeColor = deviceObject.badgeColor;
    device.customClass = deviceObject.applyClass;
    device.ticketCustomClass = deviceObject.ticketCustomClass
    device.showTicketIcon = deviceObject.showTicketIcon
    device.secondaryDeviceCount = 0;
    device.flagBadge = this.checkFlagBadge(device);
    if (device.childFloorEquipments && device.childFloorEquipments !== undefined) {
      device.secondaryDeviceCount = FloorUtils.filterDevices(device.childFloorEquipments, { devices: this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter, isChild: false }).length;
    }
    if (this.equipmentDetails && (this.equipmentDetails.id === device.id || this.equipmentDetails.parentId === device.id) && (device.parentId === "" || device.parentId === null || device.parentId === undefined)) {
      device.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(device, this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
      device.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(device, device.imageLeft, device.imageTop);
    }

    return device;
  }
  equipTrackBy(index: any, item: any) {
    return item.id
  }

  childTrackByFn(index: any, child: any) {
    return child.id;
  }
  updateDeviceLeftTop(deviceUpdates: any, isChild?: any) {
    if (deviceUpdates.device.tempNewId) {
      this.newDeviceDropped(deviceUpdates.device, deviceUpdates.droppingTarget, deviceUpdates.isFav);
    } else {
      if (deviceUpdates.isSecondaryDropped) {
        this.secondaryDrop(deviceUpdates.device, deviceUpdates.droppingTarget, isChild);
      } else {
        this.primaryDrop(deviceUpdates.device, isChild);
      }
    }
  }

  primaryDrop(device: any, isChild?: any) {
    if (!isChild && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id).length > 0) {
      let parentObj: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id)[0];
      parentObj.imageLeft = device.imageLeft;
      parentObj.imageTop = device.imageTop;
      if (this.equipmentDetailsParent && this.equipmentDetailsParent.id === parentObj.id) {
        this.equipmentDetailsParent.imageLeft = device.imageLeft;
        this.equipmentDetailsParent.imageTop = device.imageTop;
      }
      const updatedParent: any = { imageLeft: device.imageLeft, type: device.type, imageTop: device.imageTop, id: device.id, isUpdate: device.isNew ? false : true }
      if (parentObj.networkSwitchLinkId && parentObj.networkSwitchLinkId > 0 && parentObj.cableCoordinates !== null) {
        parentObj.cableCoordinates = this.updateCableCoordinates(parentObj, false);
        updatedParent['cableCoordinates'] = parentObj.cableCoordinates
      }
      if (parentObj.isHub) {
        this.selectedFloor.floorEquipments.forEach((hubDevice: any) => {
          if (hubDevice.networkSwitchLinkId === device.id) {
            if (hubDevice !== null && hubDevice.cableCoordinates !== null) {
              hubDevice.cableCoordinates[hubDevice.cableCoordinates.length - 1]['x'] = device.imageLeft;
              hubDevice.cableCoordinates[hubDevice.cableCoordinates.length - 1]['y'] = device.imageTop;
              if (this.equipmentDetails && this.equipmentDetails.id === hubDevice.id) {
                this.equipmentDetails.cableCoordinates = hubDevice.cableCoordinates
              }
              this.updatedKeyValueJson({ id: hubDevice.id, isUpdate: true, type: "device", cableCoordinates: hubDevice.cableCoordinates });
            } else {
              hubDevice.cableLinkImageLeft = device.imageLeft
              hubDevice.cableLinkImageTop = device.imageTop
              if (this.equipmentDetails && this.equipmentDetails.id === hubDevice.id) {
                this.equipmentDetails.cableLinkImageLeft = hubDevice.imageLeft
                this.equipmentDetails.cableLinkImageTop = hubDevice.imageTop
              }
            }
            if (hubDevice.networkSwitchLinkId > 0) {
              hubDevice = this.setCableOrConnectionLine(hubDevice, device)
            }
          }
          if (hubDevice.childFloorEquipments && hubDevice.childFloorEquipments.length > 0 && hubDevice.childFloorEquipments.filter((y: any) => y.networkSwitchLinkId === device.id).length > 0) {
            hubDevice.childFloorEquipments.filter((y: any) => y.networkSwitchLinkId === device.id && !y.isArchived).forEach((hubSec: any) => {
              if (hubSec !== null && hubSec.cableCoordinates !== null) {
                hubSec.cableCoordinates[hubSec.cableCoordinates.length - 1]['x'] = device.imageLeft;
                hubSec.cableCoordinates[hubSec.cableCoordinates.length - 1]['y'] = device.imageTop;
                if (this.equipmentDetails && this.equipmentDetails.id === hubSec.id) {
                  this.equipmentDetails.cableCoordinates = hubSec.cableCoordinates
                }
                this.updatedKeyValueJson({ parentId: hubSec.parentId, id: hubSec.id, isUpdate: true, type: "device", cableCoordinates: hubSec.cableCoordinates });
              } else {
                hubSec.cableLinkImageLeft = device.imageLeft
                hubSec.cableLinkImageTop = device.imageTop
                if (this.equipmentDetails && this.equipmentDetails.id === hubSec.id) {
                  this.equipmentDetails.cableLinkImageLeft = hubSec.imageLeft
                  this.equipmentDetails.cableLinkImageTop = hubSec.imageTop
                }
              }
              if (hubSec.networkSwitchLinkId > 0) {
                hubSec = this.setCableOrConnectionLine(hubSec, device)
              }
            });
          }
        });
      } else if (this.equipmentDetails && this.equipmentDetails.id === device.id && (device.imageLeft !== this.equipmentDetails.imageLeft || device.imageTop !== this.equipmentDetails.imageTop)) {
        this.equipmentDetails.imageLeft = device.imageLeft
        this.equipmentDetails.imageTop = device.imageTop
      }

      parentObj = this.setDevices(parentObj)

      if (parentObj.childFloorEquipments.length > 0) {
        parentObj.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(parentObj, parentObj.imageLeft, parentObj.imageTop);
        parentObj.childFloorEquipments.forEach((element: any) => {
          element.imageLeft = device.imageLeft;
          element.imageTop = device.imageTop;
          const updateChild: any = { imageLeft: device.imageLeft, imageTop: device.imageTop, parentId: device.id, id: element.id, isUpdate: device.isNew ? false : true };
          if (element.networkSwitchLinkId && element.networkSwitchLinkId > 0) {
            if (element.cableCoordinates !== null) {
              element.cableCoordinates = this.updateCableCoordinates(element, false);
              updateChild['cableCoordinates'] = element.cableCoordinates
            }
            if (element.isHub && element.networkSwitchLinkEquipments && element.networkSwitchLinkEquipments.length > 0) {
              this.updateHubConnectedDevices(element);
            }
            element = _.cloneDeep(this.setCableOrConnectionLine(element));
            if (element.parentId > 0) {
              this.selectedFloor.floorEquipments.filter((x: any) => x.id === element.parentId)[0].childFloorEquipments.filter((e: any) => e.id === element.id)[0].cableLinkImageLeft = _.cloneDeep(element.cableLinkImageLeft);
              this.selectedFloor.floorEquipments.filter((x: any) => x.id === element.parentId)[0].childFloorEquipments.filter((e: any) => e.id === element.id)[0].cableLinkImageTop = _.cloneDeep(element.cableLinkImageTop);
            }
          } else if (element.isHub && element.networkSwitchLinkEquipments && element.networkSwitchLinkEquipments.length > 0) {
            this.updateHubConnectedDevices(element);
          }
          this.updatedKeyValueJson(updateChild);
        });
      }
      if (parentObj.networkSwitchLinkId > 0) {
        parentObj = this.setCableOrConnectionLine(parentObj)
      }
      this.updatedKeyValueJson(updatedParent);
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id)[0] = _.cloneDeep(parentObj)
    } else {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId).length > 0 && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.filter((child: any) => child.id === device.id).length > 0) {
        if (device.isHub && device.networkSwitchLinkEquipments && device.networkSwitchLinkEquipments.length > 0) {
          const timeOut = setTimeout(() => {
            this.updateHubConnectedDevices(device);
            clearTimeout(timeOut);
          }, 100)
        }
        if (device.networkSwitchLinkId && device.networkSwitchLinkId > 0 && device.cableCoordinates !== null) {
          device.cableCoordinates = this.updateCableCoordinates(device, false);
          device = this.setCableOrConnectionLine(device);
        }
        // this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0] = _.cloneDeep(device)
        const parentDeviceUpdates: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0];

        if (this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id).length > 0) {
          const oldValues = this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id)[0];
          oldValues['imageLeft'] = device.imageLeft;
          oldValues['imageTop'] = device.imageTop;
          oldValues['isUpdate'] = true;
          delete oldValues.parentId
          if (device.cableCoordinates !== null) {
            oldValues['cableCoordinates'] = device.cableCoordinates
          }
          this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id)[0] = oldValues;
        } else {
          const childObject: any = { id: device.id, isUpdate: true, type: device.type, imageLeft: device.imageLeft, imageTop: device.imageTop };
          if (device.cableCoordinates !== null) {
            childObject['cableCoordinates'] = device.cableCoordinates
          }
          this.updatedFloorEquipmentValues.push(childObject)
        }
        parentDeviceUpdates.childFloorEquipments.splice(this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.findIndex((child: any) => child.id === device.id), 1);

        device.parentId = null;
        this.equipmentDetails = null;
        device = this.setDevices(device);
        this.selectedFloor.floorEquipments.push(device);
        this.updateDeviceCssSettings(parentDeviceUpdates, false);
        this.openEquipmentDetail(null, device);
        this.getUnSavedCount();
      }
    }
    this.detectFloorChanges();
  }
  async secondaryDrop(device: any, targetId: any, isChild: any = false) {
    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId).length > 0) {
      const tmpDeviceDrop: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId)[0];
      if (tmpDeviceDrop.isArchived) {
        targetId = 'floorDeviceContainer'
        this.primaryDrop(device);
        return;
      }
    }
    if (this.isProject) {
      if (device.type === DeviceTypes.Device) {
        if (!isNaN(targetId)) {
          if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId).length > 0) {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId)[0].type === 'task') {
              const deviceDoc: any = document.getElementById('device-' + device.id);
              const equipmentOld = FloorUtils.getEquipment(this.selectedFloor, device.id);
              deviceDoc.style.left = equipmentOld.imageLeft;
              deviceDoc.style.top = equipmentOld.imageTop;
              this.messageService.errorMessage('Error: ', 'Only tasks can be added to other tasks.')
              return;
            }
          }
        }
      }
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId).length > 0 && this.selectedFloor.floorEquipments.filter((e: any) => e.id == targetId)[0].projectId === null) {
        this.primaryDrop(device);
        return;
      }
    }
    if (this.equipmentDetails && (this.equipmentDetails.id === device.id || (this.equipmentDetails.parentId && this.equipmentDetails.parentId === device.id))) {
      this.equipmentDetails = null;
    }
    if (!isChild) {
      this.selectedFloor.floorEquipments.splice(this.selectedFloor.floorEquipments.findIndex((e: any) => e.id === device.id), 1);
    } else {
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.splice(this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.findIndex((e: any) => e.id === device.id), 1)
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0]);
    }

    const updatedPrimaryDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0];
    device.imageLeft = updatedPrimaryDevice.imageLeft
    device.imageTop = updatedPrimaryDevice.imageTop
    if (device.networkSwitchLinkId && device.networkSwitchLinkId > 0 && device.cableCoordinates !== null) {
      device.isConnectionLineOrCablePath = true;
      device.cableCoordinates = this.updateCableCoordinates(device, false);
    }
    if (device.cableCoordinates === null) {
      this.setCableOrConnectionLine(device);
    }
    device.parentId = targetId;
    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0] && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments !== undefined && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.length === 0) {
      device.orderWithinParent = 1;
    } else if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId) && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId).length > 0) {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments !== undefined) {
        device.orderWithinParent = this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments[this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.length - 1].orderWithinParent + 1;
      }
    }
    if (this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id).length > 0) {
      const oldValues = this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id)[0];

      if (updatedPrimaryDevice) {
        oldValues.parentId = targetId;
        oldValues['isUpdate'] = true;
        oldValues['orderWithinParent'] = device.orderWithinParent;
        oldValues['imageLeft'] = updatedPrimaryDevice.imageLeft;
        oldValues['imageTop'] = updatedPrimaryDevice.imageTop;
        if (device.networkSwitchLinkId && device.networkSwitchLinkId > 0 && device.cableCoordinates !== null) {
          oldValues['cableCoordinates'] = device.cableCoordinates;
        }
        this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id)[0] = oldValues;
      }
    } else {
      if (device.networkSwitchLinkId && device.networkSwitchLinkId > 0 && device.cableCoordinates !== null) {
        this.updatedFloorEquipmentValues.push({ id: device.id, orderWithinParent: device.orderWithinParent, type: device.type, parentId: targetId, isUpdate: true, imageLeft: device.imageLeft, imageTop: device.imageTop, cableCoordinates: device.cableCoordinates });
      } else {
        this.updatedFloorEquipmentValues.push({ id: device.id, orderWithinParent: device.orderWithinParent, type: device.type, parentId: targetId, isUpdate: true, imageLeft: device.imageLeft, imageTop: device.imageTop });
      }
      this.getUnSavedCount();
    }

    if (!isChild) {
      const childFloorEquipments = _.cloneDeep(device.childFloorEquipments);
      device.childFloorEquipments = [];
      const parentDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0];
      if (parentDevice !== undefined) {
        device.imageLeft = parentDevice.imageLeft;
        device.imageTop = parentDevice.imageTop;
        device = _.cloneDeep(this.setDevices(device));
        device.showChildTicketIcon = true;
        if (!this.isProject && FloorUtils.setTicketCustomClass(device).closed) {
          device.showChildTicketIcon = false;
        }
        parentDevice.childFloorEquipments.push(device);
        if (childFloorEquipments.length > 0) {
          childFloorEquipments.forEach(async (element: any, index: any) => {
            element.parentId = targetId;
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === element.parentId)[0].childFloorEquipments.length === 0) {
              element.orderWithinParent = 1;
            } else {
              element.orderWithinParent = this.selectedFloor.floorEquipments.filter((e: any) => e.id === element.parentId)[0].childFloorEquipments[this.selectedFloor.floorEquipments.filter((e: any) => e.id === element.parentId)[0].childFloorEquipments.length - 1].orderWithinParent + 1;
            }
            element.imageLeft = parentDevice.imageLeft;
            element.imageTop = parentDevice.imageTop;
            if (element.networkSwitchLinkId && element.networkSwitchLinkId > 0 && element.cableCoordinates !== null) {
              element.isConnectionLineOrCablePath = true;
              element.cableCoordinates = this.updateCableCoordinates(element, false);
            }
            if (this.updatedFloorEquipmentValues.filter((e: any) => e.id === element.id).length > 0) {
              const oldValues = this.updatedFloorEquipmentValues.filter((e: any) => e.id === element.id)[0];
              const updatedPrimaryDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0]
              if (updatedPrimaryDevice) {
                oldValues.parentId = targetId;
                oldValues['isUpdate'] = true;
                oldValues['orderWithinParent'] = element.orderWithinParent;
                oldValues['imageLeft'] = updatedPrimaryDevice.imageLeft;
                oldValues['imageTop'] = updatedPrimaryDevice.imageTop;
                if (element.networkSwitchLinkId && element.networkSwitchLinkId > 0 && element.cableCoordinates !== null) {
                  oldValues['cableCoordinates'] = element.cableCoordinates;
                }
                this.updatedFloorEquipmentValues.filter((e: any) => e.id === element.id)[0] = oldValues;
              }
            } else {
              if (element.networkSwitchLinkId && element.networkSwitchLinkId > 0 && element.cableCoordinates !== null) {
                this.updatedFloorEquipmentValues.push({ id: element.id, orderWithinParent: element.orderWithinParent, type: element.type, parentId: targetId, isUpdate: true, imageLeft: device.imageLeft, imageTop: device.imageTop, cableCoordinates: element.cableCoordinates });
              } else {
                this.updatedFloorEquipmentValues.push({ id: element.id, orderWithinParent: element.orderWithinParent, type: element.type, parentId: targetId, isUpdate: true, imageLeft: device.imageLeft, imageTop: device.imageTop });
              }
            }
            if (childFloorEquipments.length - 1 === index) {
              this.getUnSavedCount();
            }

            if (element.cableCoordinates === null) {
              this.setCableOrConnectionLine(element);
            }
            if (element.isHub && element.networkSwitchLinkEquipments && element.networkSwitchLinkEquipments.length > 0) {
              await this.updateHubConnectedDevices(element);
            }
            element = _.cloneDeep(this.setDevices(element))
            this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0].childFloorEquipments.push(element)
          });

        }
      }
    } else {
      device.imageLeft = this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0].imageLeft;
      device.imageRight = this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0].imageRight;
      device = this.setDevices(device);
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0].childFloorEquipments.push(device)
    }
    if (device.isHub && device.networkSwitchLinkEquipments && device.networkSwitchLinkEquipments.length > 0) {
      await this.updateHubConnectedDevices(device);
    }


    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId) && this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId).length > 0) {
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0])
      this.updateAllParentAndDChildConnections(targetId);
      this.updateDeviceCssSettings(this.selectedFloor.floorEquipments.filter((e: any) => e.id === targetId)[0], (this.equipmentDetails && this.equipmentDetails.id === targetId) ? true : false)
      this.detectFloorChanges();
    }
  }
  newDeviceDropped(device: any, dropTarget?: any, isFav?: any) {
    const detailEquip: any = this.equipmentList.find((parent: any) => {
      return parent.equipments.some((x: any) => x.id === (isFav ? device.equipment.id : device.id));
    }).equipments.filter((x: any) => x.id === (isFav ? device.equipment.id : device.id))[0]
    if (this.isProject) {
      if (device.type === DeviceTypes.Device) {
        if (!isNaN(dropTarget)) {
          if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget).length > 0) {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget)[0].type === 'task') {
              this.messageService.errorMessage('Error: ', 'Only tasks can be added to other tasks.')
              return;
            }
          }
        }
      }
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget).length > 0 && this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget)[0].projectId === null) {
        dropTarget = 'floorDeviceContainer'
      }
    }
    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget).length > 0) {
      const tmpDeviceDrop: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id == dropTarget)[0];
      if (tmpDeviceDrop.isArchived) {
        dropTarget = 'floorDeviceContainer'
      }
    }
    let deviceOrTaskType = DeviceTypes.Device;
    if (device.type === DeviceTypes.Task) {
      deviceOrTaskType = DeviceTypes.Task;
    }
    delete device.tempNewId;

    const parentDevice = this.selectedFloor.floorEquipments.filter((x: any) => x.id === dropTarget);
    let left: any = '';
    let top: any = '';
    if (isNaN(dropTarget) || dropTarget === "" || dropTarget === undefined) {
      left = device.imageLeft;
      top = device.imageTop;
    } else {
      left = parentDevice[0].imageLeft;
      top = parentDevice[0].imageTop;
    }
    let equipment: any = {
      type: deviceOrTaskType,
      equipment: detailEquip,
      categoryName: this.equipmentList.find((parent: any) => {
        return parent.equipments.some((x: any) => x.id === (isFav ? device.equipment.id : device.id));
      }).name,
      floorName: this.selectedFloor.name,
      buildingName: this.buildingDetails.name,
      floorId: this.floorId,
      imageLeft: left,
      imageTop: top,
      status: this.projectId ? EquipmentStatusForDevice.NOTWORKING : EquipmentStatusForDevice.OPERATIONAL,
      statusCode: 1,
      installStatus: EquipmentStatusForDevice.PLANNED,
      isParent: isNaN(parseFloat(dropTarget)) ? true : false,
      isNew: true,
      id: Math.random(),
      orderWithinParent: 0,
      siteName: this.buildingDetails.siteName,
      siteId: this.buildingDetails.siteId,
      isSelectedDevice: false,
      isEquipmentFavoriteForm: false,
      projectId: this.projectId || null,
      projectName: undefined,
      isPublishedToAMT: false,
      referenceFloorEquipmentId: null,
      canAccessReference: false,
      autoUpdateDeviceStatus: "By Monitoring Status",
      serviceTickets: [],
      pinColor: null,
      isHub: detailEquip.isHub,
      tempId: "temp-" + Date.now(),
      childFloorEquipments: [],
      images: [],
      parentId: isNaN(dropTarget) ? undefined : dropTarget,
      cableCoordinates: null,
      partNumber: null,
      manufacturer: null,
      installHours: null,
      dueDate: null,
      installationDate: null,
      serialNumber: null,
      ipAddress: null,
      macAddress: null,
      barcode: null,
      height: null,
      dataType: null,
      deviceConnectedTo: null,
      networkSwitchLinkId: null,
      port: null,
      description: null,
      installationNotes: null,
      laborWarrantyExpirationDate: null,
      deviceWarrantyExpirationDate: null,
      price: null,
      isLiveDevice: false,
      customDeviceId: null,
      createdTrigger: isFav && isFav !== undefined && isFav !== null ? 'drag-drop-favorite' : 'drag-drop-device'
    };
    equipment.typeName = equipment.equipment.name;
    equipment.priority = DevicePriority.Medium;
    equipment.positionDroppingClient = device.positionDroppingClient;
    if (equipment.parentId) {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.length === 0) {
        equipment.orderWithinParent = 1;
      } else {
        equipment.orderWithinParent = this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments[this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.length - 1].orderWithinParent + 1;
      }
    }
    if (!isFav) {
      const tmpPinColor = this.equipmentList.filter((x: any) => x.name === equipment.categoryName);
      if (tmpPinColor) {
        equipment.pinColor = tmpPinColor[0].pinColor;
      }
      if (equipment.isParent) {
        const autoName = FloorUtils.checkCountDevice(this.baseNameCount, equipment.equipment.name, false);
        if (autoName.length > 75) {
          const name = autoName.substring(0, autoName.length - (autoName.length - 75));
          equipment.name = FloorUtils.checkCountDevice(this.baseNameCount, name, false);
        } else {
          equipment.name = FloorUtils.checkCountDevice(this.baseNameCount, equipment.equipment.name, false);
        }
      } else {
        equipment.name = device.name
      }
      this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, equipment)
      equipment.abbreviatedName = Utilities.isNull(equipment.equipment.abbreviatedNamePrefix) ? undefined : equipment.equipment.abbreviatedNamePrefix;
    } else {
      equipment.pinColor = device.pinColor;
      if (equipment.isParent) {
        equipment.name = FloorUtils.checkCountDevice(this.baseNameCount, device.name ? device.name : equipment.equipment.name, false);
        this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, equipment)
      } else {
        equipment.name = Utilities.isNull(device.name) || device.name === ""
          ? equipment.equipment.name
          : device.name
      }
      equipment.abbreviatedName = device.abbreviatedName;
      equipment.type = device.type;
      equipment.status = this.projectId
        ? EquipmentStatusForDevice.NOTWORKING
        : EquipmentStatusForDevice.OPERATIONAL;
      equipment.installStatus = EquipmentStatusForDevice.PLANNED;
      equipment.partNumber = device.partNumber;
      equipment.manufacturer = device.manufacturer;
      equipment.price = device.price;
      if (this.isProject) {
        equipment.installHours = device.installHours;
        equipment.budgetedCost = device.budgetedCost;
      }
      equipment.dataType = device.dataType;
      equipment.deviceLocation = device.deviceLocation;
      equipment.description = device.description;
      equipment.isPublishedToAMT = false;
      equipment.referenceFloorEquipmentId = null;
      equipment.serviceTickets = [];
      equipment.canAccessReference = false;

      if (device.childFavouriteEquipments) {
        device.childFavouriteEquipments = device.childFavouriteEquipments.sort(function (item1: any, item2: any) {
          return item1.orderNumber - item2.orderNumber;
        });
        device.childFavouriteEquipments.forEach((child: any, index: any) => {
          if (!child.isDeleted) {
            child.name = Utilities.isNull(child.name) || child.name === ""
              ? child.equipment.name
              : child.name;
            child.abbreviatedName = Utilities.isNull(child.abbreviatedName) ||
              child.abbreviatedName === ""
              ? child.equipment.abbreviatedName
              : child.abbreviatedName;
            delete child.childFavouriteEquipments;
            child.childFloorEquipments = [];
            child.status = this.projectId
              ? EquipmentStatusForDevice.NOTWORKING
              : EquipmentStatusForDevice.OPERATIONAL;
            child.installStatus = EquipmentStatusForDevice.PLANNED;
            child.priority = DevicePriority.medium;
            child.serviceTickets = [];
            child.images = [];
            child.isEquipmentFavoriteForm = false;
            child.imageLeft = device.imageLeft;
            child.imageTop = device.imageTop;
            child.isPublishedToAMT = false;
            child.referenceFloorEquipmentId = null;
            child.canAccessReference = false;
            child.projectId = this.projectId || null;
            child.networkSwitchLinkId = null;
            child.id = Math.random();
            child.parentId = equipment.parentId ? equipment.parentId : equipment.id;
            child.autoUpdateDeviceStatus = "By Monitoring Status";
            child.isNew = true;
            child.tempId = "temp-" + Date.now();
            child.customDeviceId = null;
            child.createdTrigger = isFav && isFav !== undefined && isFav !== null ? 'drag-drop-favorite' : 'drag-drop-device';
            if (equipment.type === DeviceTypes.Task) {
              child.type = DeviceTypes.Task;
            }
            if (child.type === DeviceTypes.Task && !this.projectId) {
              return;
            }
            if (equipment.parentId) {
              child.orderWithinParent = (index + 1) + equipment.orderWithinParent;
            } else {
              child.orderWithinParent = index + 1;
            }
            if (!this.isProject) {
              delete child.budgetedCost;
              delete child.installHours;
            }
            this.updatedFloorEquipmentValues.push(child);
            if (equipment.parentId) {
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.push(child);
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0]);
            } else {
              equipment.childFloorEquipments.push(child);
            }
            if (child.equipmentId && this.equipmentList.find((x: any) => x.id === child.equipmentId).isHub) {
              this.netWorkSwitches.push({
                floorId: this.floorId,
                floorName: this.selectedFloor.name,
                id: child.id,
                name: child.name,
                networkSwitchLinkTempId: child.tempId
              });
              this.netWorkSwitches = _.orderBy(this.netWorkSwitches, ["name"]);
            }
            child = this.setDevices(child);
          }
        });
      }
    }
    if (equipment.isHub) {
      if (this.netWorkSwitches.filter((e: any) => e.id === equipment.id).length === 0) {
        this.netWorkSwitches.push({
          floorId: this.floorId,
          floorName: this.selectedFloor.name,
          id: equipment.id,
          name: equipment.name,
          networkSwitchLinkTempId: equipment.tempId
        });
      }
    }



    equipment = this.setDevices(equipment);
    const removeKeyEquipment: any = _.cloneDeep(this.setDevices(equipment));
    if (equipment.parentId) {

      this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.push(equipment);
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0]);
    } else {
      this.selectedFloor.floorEquipments.push(equipment);
      this.listDeviceData = equipment;
      this.showFirstDragPopup = true;
    }
    removeKeyEquipment.childFloorEquipments = [];
    this.updatedFloorEquipmentValues.push(removeKeyEquipment);
    this.getUnSavedCount();
    this.detectFloorChanges();
  }
  openEquipmentDetail(event: any, device: FloorEquipment) {
    const startTime = Date.now();
    if (event instanceof Event && document.getElementsByClassName('dropdown-menu') && document.getElementsByClassName('dropdown-menu').length > 0) {
      document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
    }
    this.callNextDeviceEquipmentOpen = this.isImageLoadingLocal;
    this.callNextDeviceEquipmentDetails = {
      details: device,
      event: event,
      isPrimary: true,
    }
    if (!this.isImageLoadingLocal) {
      this.updateConnection = this.isCameraOpen = false;
      this.contextOpen = false;
      if (this.showFirstDragPopup) {
        this.showFirstDragPopup = false
      }
      let selectedEquipmentId: any;
      if (this.equipmentDetails) {
        selectedEquipmentId = this.equipmentDetails.parentId ? this.equipmentDetails.parentId : this.equipmentDetails.id
      }
      if (!device.parentId && device.childFloorEquipments && device.childFloorEquipments.length > 0) {
        if (this.equipmentDetails && device.id === this.equipmentDetails.id) {
          if (!this.updatingCoverageValue) {
            this.toggleSecondary = !this.toggleSecondary;
          }
        } else {
          this.toggleSecondary = true;
        }
      } else {
        if (this.equipmentDetails && (this.equipmentDetails.id === device.parentId || this.equipmentDetails.parentId === device.parentId || (device.parentId && this.equipmentDetails.childFloorEquipments && this.equipmentDetails.childFloorEquipments.filter((e: any) => e.id === device.id)))) {
          this.toggleSecondary = true;
        } else {
          this.toggleSecondary = false;
        }
      }
      if (this.equipmentDetails && device.id === this.equipmentDetails.id) {
        return;
      }
      const deviceDom: any = document.getElementById('device-' + device.id.toString());
      if (!deviceDom || !deviceDom.classList.contains('dragging')) {
        if (selectedEquipmentId && this.selectedFloor.floorEquipments.filter((x: any) => x.id === selectedEquipmentId).length > 0) {
          this.selectedFloor.floorEquipments.filter((x: any) => x.id === selectedEquipmentId)[0].tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(this.selectedFloor.floorEquipments.filter((x: any) => x.id === selectedEquipmentId)[0], this.isProject, false, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
        }
        if (device.secondaryDeviceCount === undefined) {
          device.secondaryDeviceCount = FloorUtils.filterDevices(device.childFloorEquipments, { devices: this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter, isChild: false }).length;
        }
        if (device.secondaryDeviceCount > 0) {
          device.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(device, event.pageX, event.pageY)
        }
        this.equipmentDetailsParent = null;
        device.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(device, this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData);
        if (device.parentId && device.parentId > 0) {
          this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0], this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
          this.equipmentDetailsParent = FloorUtils.getEquipment(this.selectedFloor, device.parentId);
        }
        this.equipmentDetails = _.cloneDeep(device);
        if (this.equipmentDetails && this.equipmentDetails.coverageArea && !this.actionBtn.edit && this.equipmentDetails && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice && ((this.projectId && this.equipmentDetails.projectId !== null) || !this.projectId) && (this.permissions.canEdit || this.permissions.canManage || this.permissions.canUpdateDevice)) {
          this.getTranslate(this.equipmentDetails)
        }

        this.updateFloorObjectForEquipmentDetail(this.equipmentDetails);
        Sentry.metrics.distribution("open.equipment.panel", Date.now() - startTime);
        this.cdRef.detectChanges();
      }
    } else {
      this.messageService.warningMessage('Processing Images', 'Please wait while SiteOwl is processing your image attachments locally.');
    }
    this.showCountFilterPopup = false;
    this.countFilterClicked = false;
  }
  getDeviceStatusData() {
    this.appService.getStatusData()
      .pipe()
      .subscribe((r: any) => {
        this.deviceStatusData = r;
      });
  }

  closeSecondaryPopup(event: any) {
    event.stopPropagation();
    this.toggleSecondary = !this.toggleSecondary
  }

  getFloorPlanConfiguration(isReset?: any) {
    this.floorPlanService.getFloorConfiguration(this.floorId).subscribe({
      next: (res: any) => {
        this.pinSize = DevicePinSizeValue[res.showDeviceSize.toString()];
        if (res.showPinColor === null || Utilities.isNull(res.showPinColor)) {
          if (this.projectId && this.buildingDetails.stage === ProjectStage.Design) {
            this.pinColorType = PinColorStatus.DEVICECOLOR
          } else if (this.projectId && this.buildingDetails.stage === ProjectStage.Installation) {
            this.pinColorType = PinColorStatus.INSTALLSTATUS
          } else {
            this.pinColorType = PinColorStatus.OPERATIONALSTATUS
          }
        } else {
          if (!this.projectId && res.showPinColor === PinColorStatus.INSTALLSTATUS) {
            this.pinColorType = (res.showPinColor === PinColorStatus.INSTALLSTATUS && this.siteId > 0 ? PinColorStatus.OPERATIONALSTATUS : res.showPinColor)
          } else {
            this.pinColorType = res.showPinColor
          }
        }

        this.showDeviceName = res.showDeviceName == 1 ? true : false;
        this.showAbbreviatedName = res.showAbbreviatedName == 1 ? true : false;
        this.showPartNumber = res.showPartNumber == 1 ? true : false;
        this.showCoverage = res.showFieldOfView == 1 ? true : false;
        this.showConnectionLine = res.showConnectionLine == 1 ? true : false;
        this.showArchivedDevice = res.showArchivedDevice == 1 ? true : false;
        this.showPlanMarkup = res.showPlanMarkup == 1 ? true : false;
        this.showTicketAction = res.showTicketAction == 1 ? true : false;
        this.hideClosedTickets = res.hideClosedTickets;
        this.unitOfMeasurements = this.authService.getUserKeyData().unitOfMeasurements
        this.isConfigLoaded = true;
        if (this.zoomFactor === 1 && this.fitToScreen) {
          const timeOut = setTimeout(() => {
            this.calculateFloorPlanZoom();
            clearTimeout(timeOut);
          }, 300);
        } else if (this.equipmentDetails && this.equipmentDetails !== undefined && this.equipmentDetails.coverageArea) {
          const timeOut = setTimeout(() => {
            this.getTranslate(this.equipmentDetails);
            clearTimeout(timeOut);
          }, 1200);
        }
        this.checkFloorContainsCoverageOrLine(isReset);
        this.cdRef.detectChanges();
      },
      error: (e: any) => {
        console.log(e)
      }
    })
  }
  saveEquipmentDetails(device: any) {
    if (!device) { return; }
    device = this.removeExtraKeys(device);
    if (!device) { return; }
    if (this.equipmentDetails) {
      device['id'] = this.equipmentDetails.id;

      if (this.equipmentDetails.parentId) {
        device['parentId'] = this.equipmentDetails.parentId;
      }
      if (this.equipmentDetails.id >= 1) {
        device['isUpdate'] = true;
      }
      device['type'] = this.equipmentDetails.type;
    }
    if (device.networkSwitchLinkId && device.networkSwitchLinkId > 0) {
      if (this.equipmentDetails && this.equipmentDetails.networkSwitchLinkId && this.equipmentDetails.networkSwitchLinkId !== device.networkSwitchLinkId) {
        const oldHub = FloorUtils.getEquipment(this.selectedFloor, this.equipmentDetails.networkSwitchLinkId)
        if (oldHub && oldHub.networkSwitchLinkEquipments.filter((e: any) => e.id === device.id).length > 0) {
          oldHub.networkSwitchLinkEquipments.splice(oldHub.networkSwitchLinkEquipments.findIndex((e: any) => e.id === device.id), 1)
        }
      }
    }
    if (Object.keys(device).length > 0 && this.equipmentDetails && this.updatedFloorEquipmentValues.filter((e: any) => e.id === this.equipmentDetails.id).length === 0) {
      this.updatedFloorEquipmentValues.push(device);
      this.updateFloorObjectForEquipmentDetail(device);
    } else {
      this.updatedKeyValueChecks(device);
    }

    if (this.equipmentDetails && this.equipmentDetails.coverageArea) {
      if (device.coverageDirection || device.coverageAngle || device.coverageRange || Object.keys(device).filter(x => x === 'coverageArea').length > 0) {
        this.updateCoverage(FloorUtils.getEquipment(this.selectedFloor, device.id), true);
      }
    }
    if (device.cableCoordinates === null && this.equipmentDetails !== null && this.equipmentDetails.networkSwitchLinkId > 0) {
      this.equipmentDetails = _.cloneDeep(this.setCableOrConnectionLine(this.equipmentDetails));
    }
    if (Object.keys(device).filter((x: any) => x === 'networkSwitchLinkId' || x === 'cableCoordinates' || x === 'status' || x === 'installStatus' || x === 'pinColor').length > 0) {
      const floorEquipments = _.cloneDeep(this.selectedFloor);
      this.selectedFloor = _.cloneDeep(floorEquipments);
    }
    if (this.equipmentDetails && this.equipmentDetails.coverageArea) {
      if (!(device.coverageDirection || device.coverageAngle || device.coverageRange || Object.keys(device).filter(x => x === 'coverageArea').length > 0)) {
        const timeout = setTimeout(() => {
          this.showIconsForCoverage();
          clearTimeout(timeout);
        }, 100);
      }
    }
    this.getUnSavedCount();
  }

  updateFloorObject(device: any, fromIndexdDB = false) {
    const id: any = (this.equipmentDetails === undefined || this.equipmentDetails === null) ? device.id : (this.equipmentDetails.parentId && this.equipmentDetails.parentId !== null && this.equipmentDetails.parentId > 0) ? this.equipmentDetails.parentId : this.equipmentDetails.id;
    if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === id).length > 0) {
      Object.keys(device).forEach(element => {
        if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === id).length > 0 && element !== "id" && element !== "images") {
          this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0][element] = device[element];
        }
        if (this.equipmentDetails && element !== "id" && element !== "images" && (this.equipmentDetails.parentId === null || this.equipmentDetails.parentId === undefined || this.equipmentDetails.parentId === '')) {
          this.equipmentDetails[element] = device[element];
        }
        if (element === "images") {
          device[element].forEach((elementImg: any) => {
            if (elementImg.id && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].images.filter((x: any) => x.id === elementImg.id).length > 0) {
              this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].images[this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].images.findIndex((x: any) => x.id === elementImg.id)] = elementImg;
            } else {
              this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].images.push(elementImg);
            }
          });
        }
        if (element === 'pinColor' || element === 'status' || element === 'installStatus' || element === 'images' || element === 'isArchived') {
          this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0]);
        }

      });
      if (fromIndexdDB && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].networkSwitchLinkId > 0) {
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0] = this.setCableOrConnectionLine(this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0])
      }
    } else {
      Object.keys(device).forEach(element => {
        if (this.equipmentDetails && this.equipmentDetails.parentId !== null && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0) {
          if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0 && element !== "id" && element !== 'images') {
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0][element] = device[element];
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0]);
          }
          if (this.equipmentDetails && element !== "id" && element !== 'images') {
            this.equipmentDetails[element] = device[element];
          }
          if (element === "images") {
            device[element].forEach((elementImg: any) => {
              if (elementImg.id && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0].images.filter((x: any) => x.id === elementImg.id).length > 0) {
                this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0].images.filter((x: any) => x.id === elementImg.id)[0] = elementImg;
              } else {
                this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0].images.push(elementImg);
              }
            });
          }
          if (element === 'pinColor' || element === 'status' || element === 'installStatus' || element === 'images') {
            this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0]);
          }
        } else {
          if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId) && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id).length > 0 && element !== "id") {
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0][element] = device[element];
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0]);
          }
          if (element === "images") {
            device[element].forEach((elementImg: any) => {
              if (elementImg.id && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0].images.filter((x: any) => x.id === elementImg.id).length > 0) {
                this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0].images.filter((x: any) => x.id === elementImg.id)[0] = elementImg;
              } else {
                this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0].images.push(elementImg);
              }
            });
          }
          if (element === 'pinColor' || element === 'status' || element === 'installStatus' || element === 'images') {
            this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0]);
          }
        }
      });
      if (fromIndexdDB && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0].networkSwitchLinkId > 0) {
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0] = this.setCableOrConnectionLine(this.selectedFloor.floorEquipments.filter((x: any) => x.id === id)[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0]);
      }
    }
    if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId) && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] && (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.length === 0 || this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id).length === 0)) {
      if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.id).length > 0 && device.parentId) {
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.push(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.id)[0]);
        this.selectedFloor.floorEquipments.splice(this.selectedFloor.floorEquipments.findIndex((x: any) => x.id === device.id), 1)
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0]['parentId'] = device['parentId'];
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0]);
      }
    }
    this.detectFloorChanges();
  }

  updatedKeyValueChecks(deviceValues: any) {
    const id: any = (this.equipmentDetails === undefined || this.equipmentDetails === null) ? deviceValues.id : this.equipmentDetails.id;
    if (Object.keys(deviceValues).length > 0) {
      this.updateFloorObjectForEquipmentDetail(deviceValues);
      Object.keys(deviceValues).forEach((element: any) => {
        if (this.updatedFloorEquipmentValues.filter((e: any) => e.id === id).length > 0 && element !== "id") {
          if (deviceValues["coverageArea"] !== undefined && !deviceValues["coverageArea"]) {
            delete this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0]["coverageDirection"];
            delete this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0]["coverageAngle"];
            delete this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0]["coverageRange"];
          }
          this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0][element] = deviceValues[element]
        }
      });
      if (this.updatedFloorEquipmentValues.filter((e: any) => e.id === id).length > 0 && id >= 1) {
        this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0] = this.updatedFloorEquipmentValues.filter((e: any) => e.id === id)[0];
      }
    }
  }
  removeExtraKeys(res: any, isChild?: any) {
    Object.keys(res).forEach(keys => {
      if (floorPlanDeviceUsedKeys.filter(e => e === keys).length === 0) {
        delete res[keys];
      }
    });
    if (isChild) {
      if (Object.keys(res).filter(e => e === 'imageLeft' || e === 'imageTop').length > 0) {
        delete res.imageLeft;
        delete res.imageTop;
        if (Object.keys(res).filter(e => e === 'isUpdate' || e === 'parentId' || e === 'id').length === Object.keys(res).length) {
          return null;
        }
      }
    }
    if ((res.equipment || res.equipment === 'NA' || (res.equipment && res.equipment.length === 0) || res.equipment === undefined || res.equipment === null) && res.id !== null && res.id >= 1) {
      delete res.equipment;
    }
    if (res.images && res.images.length === 0) {
      delete res.images;
    }
    if (Object.keys(res).length > 0) {

      return res;
    } else {
      return null;
    }
  }
  getEquipmentList() {
    this.floorPlanService.getCategories().subscribe({
      next: (res: any) => {
        this.equipmentList = [];
        if (!this.projectId) {
          res = res.filter((x: any) => x.type !== "task");
        }
        this.equipmentList = res;
        this.cdRef.detectChanges();
      },
      error: (e: any) => {
        console.log(e)
      }
    })
  }
  getProjectUsers() {
    this.projectService.getUsers().pipe().subscribe((result: any) => {
      let tempArray = null;
      tempArray = result;
      const activeProjectUser = tempArray.filter((x: any) => x.user.isActive === 1);
      this.createInstalledByList(activeProjectUser);
    });
  }
  createInstalledByList(users: any) {
    this.installedByUsers = [];
    if (!Utilities.isNull(this.projectId)) {
      const userPermissions = this.buildingDetails.permissionLevels;
      users.forEach((user: any) => {
        if (!Utilities.isNull(userPermissions.find((projectUser: any) => projectUser.user_id === user.user.id && projectUser.permissionLevel !== ProjectPermissions.VIEWPROJECT))) {
          this.installedByUsers.push(user.user);
        }
        if (
          user.projectRole.name === projectRoles.Manager &&
          this.installedByUsers.indexOf(user) < 0
        ) {
          this.installedByUsers.push(user.user);
        }
      });
    }
    this.setRemovedUser();
  }
  getAMTUsers() {
    this.installedByUsers = [];
    let permissions: any;
    let userData: any[] = [];
    this.siteService.getUsers().pipe().subscribe((result: any) => {
      this.siteService.getResources(this.siteId).pipe().subscribe((resources: any) => {
        permissions = _.cloneDeep(resources);
        userData = result.filter((user: any) => user.user.isActive === 1 && user.role.name !== liveSystemRoles.ViewAll);
        const user: any = _.cloneDeep(userData);
        user.forEach((installUser: any) => {
          if (permissions.filter((e: any) => e.userId === installUser.user.id).length === 0 && installUser.role.name != liveSystemRoles.Manager) {
            userData.splice(userData.findIndex((e: any) => e.user.id === installUser.user.id), 1)
          }
        })
        userData.forEach((element: any) => {
          this.installedByUsers.push(element.user);
        })

        this.setRemovedUser();
      })
    });
  }
  setRemovedUser() {
    this.selectedFloor.floorEquipments.forEach((primaryDevice: FloorEquipment) => {
      if (!Utilities.isNull(primaryDevice.installedByUserName)) {
        if (this.installedByUsers.filter((e: any) => e.id === primaryDevice.installedByUser).length === 0) {
          const dataToPush: any = { id: primaryDevice.installedByUser, name: primaryDevice.installedByUserName };
          dataToPush['isRemoved'] = true;
          this.installedByUsers.push(dataToPush);
        }
      }
      if (!Utilities.isNull(primaryDevice.assignedToUserName)) {
        if (this.projectId && this.installedByUsers.filter((e: any) => e.id === primaryDevice.assignedToUser).length === 0) {
          const dataToPush: any = { id: primaryDevice.assignedToUser, name: primaryDevice.assignedToUserName };
          dataToPush['isRemoved'] = true;
          this.installedByUsers.push(dataToPush);
        }
      }
      primaryDevice.childFloorEquipments.forEach(childDevice => {
        if (!Utilities.isNull(childDevice.installedByUserName)) {
          if (this.installedByUsers.filter((e: any) => e.id === childDevice.installedByUser).length === 0) {
            const dataToPush: any = { id: childDevice.installedByUser, name: childDevice.installedByUserName };
            dataToPush['isRemoved'] = true;
            this.installedByUsers.push(dataToPush);
          }
        }
        if (!Utilities.isNull(childDevice.assignedToUserName)) {
          if (this.projectId && this.installedByUsers.filter((e: any) => e.id === childDevice.assignedToUser).length === 0) {
            const dataToPush: any = { id: childDevice.assignedToUser, name: childDevice.assignedToUserName };
            dataToPush['isRemoved'] = true;
            this.installedByUsers.push(dataToPush);
          }
        }
      })
    })
    this.installedByUsers = _.sortBy(this.installedByUsers, (a) => {
      return (a.name && a.name.toLowerCase()) || (a.email && a.email.toLowerCase());
    })
    this.installedByUsersItems = this.equipmentDetailService.getDropDownOptions('installbyuser', _.cloneDeep(this.installedByUsers), true);
    this.installedByUsers = this.equipmentDetailService.getDropDownOptions('installbyuser', this.installedByUsers);
  }
  getNetworkSwitchDevice() {
    if (!Utilities.isEmpty(this.projectId) && this.projectId !== undefined) {
      this.floorPlanService.getNetworkSwitchDevice(this.projectId, false, this.buildingId).pipe().subscribe((res) => {
        this.netWorkSwitches = _.orderBy(res, ["name"]);
      });
    } else {
      this.floorPlanService.getNetworkSwitchDevice(this.buildingId, true).pipe().subscribe((res) => {
        this.netWorkSwitches = _.orderBy(res, ["name"]);
      });
    }
  }

  updateDeviceCssSettings(parentDeviceUpdates: any, isDeviceSelected = false) {
    parentDeviceUpdates = this.setDevices(parentDeviceUpdates);
    parentDeviceUpdates.secondaryDeviceCount = FloorUtils.filterDevices(parentDeviceUpdates.childFloorEquipments, { devices: this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter, isChild: false }).length;
    parentDeviceUpdates.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(parentDeviceUpdates, this.isProject, isDeviceSelected, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
    parentDeviceUpdates.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(parentDeviceUpdates, parentDeviceUpdates.imageLeft, parentDeviceUpdates.imageTop);
  }

  updatedKeyValueJson(device: any) {
    this.addUpdatedValueJsonForDevice(device);
    this.getUnSavedCount();
  }
  changeBtn(btnName: any) {
    if (document.getElementsByClassName('dropdown-menu') && document.getElementsByClassName('dropdown-menu').length > 0) {
      document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
    }
    if (btnName === 'edit') {
      this.actionBtn.edit = false;
      this.showIconsForCoverage();
    } else if (btnName === 'stop') {
      this.actionBtn.edit = true;
      this.actionBtn.save = false;
      this.totalDeviceCount = this.totalTaskCount = this.totalDiscardCount = this.imgUploadedCnt = 0;
    } else if (btnName === 'save') {
      if (this.totalDiscardCount === 1 && this.updatedFloorEquipmentValues.length === 0 && !this.isFavoriteUpdated && this.uploadNewFloorFile.length === 0) {
        this.actionBtn.edit = false;
        this.actionBtn.save = false;
        this.totalDeviceCount = this.totalTaskCount = this.totalDiscardCount = this.imgUploadedCnt = 0;
        this.messageService.successMessage("Plan Details", "Saved Successfully.");
      } else {
        this.saveFloorPlan();
      }
    }
  }

  discardChanges() {
    const siteDiscardText = '<strong>' + this.totalDeviceCount + '</strong> device' + (this.totalDeviceCount > 1 ? 's' : '')

    let projectDiscardText = '<strong>' + this.totalDeviceCount + '</strong> device' + (this.totalDeviceCount > 1 ? 's' : '')

    if (this.totalTaskCount > 0) {
      projectDiscardText += ' and <strong>' + this.totalTaskCount + '</strong> task' + (this.totalTaskCount > 1 ? 's' : '')
    }

    const dataReplacer = {
      '{{#count}}': this.isProject ? projectDiscardText : siteDiscardText
    }
    const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(Discard));
    modalData.btnSuccessText = (this.totalDeviceCount > 1 ? 'Discard Changes' : modalData.btnSuccessText)
    const initialState: ModalOptions = {
      initialState: modalData, class: Discard.dialogClass, ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res !== 'Cancel') {
        this.actionBtn.edit = true;
        this.actionBtn.save = false;
        if (this.equipmentDetails && this.equipmentDetails.id < 1) {
          this.equipmentDetails = null;
        }
        this.resetFloorPlan();
      }
    });
  }

  getUnSavedCount(isFromFav = false, isFromIndexDB = false) {
    if (this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0 || this.uploadFavJson.length > 0) {
      this.actionBtn.edit = false;
      this.actionBtn.save = true;
      this.totalFavoriteCount = this.uploadFavJson.length;
      this.totalDeviceCount = this.updatedFloorEquipmentValues.filter((x: any) => x.type === DeviceTypes.Device).length;
      this.totalTaskCount = this.updatedFloorEquipmentValues.filter((x: any) => x.type === DeviceTypes.Task).length;
      this.totalDiscardCount = this.totalFavoriteCount + this.totalDeviceCount + this.totalTaskCount + this.uploadNewFloorFile.length;
      if (this.authService.getUserKeyData().isLocalSaveEnable && !isFromIndexDB) {
        const floorDetails: any = _.cloneDeep(this.selectedFloor);
        delete floorDetails.floorEquipments;
        const floor: any = jsonpack.pack(floorDetails);
        const floorImage: any = this.uploadNewFloorFile && this.uploadNewFloorFile.length > 0 ? this.uploadNewFloorFile[0] : undefined
        const commonData: any = {
          customerId: this.authService.getUserKeyData().customerId,
          customerName: this.authService.getUserKeyData().customerName,
          isProject: this.isProject, siteId: this.siteId, projectId: this.projectId, floor: floor, fileData: floorImage, name: this.isProject ? this.buildingDetails.projectName : this.buildingDetails.siteName,
          floorId: this.floorId, floorName: this.selectedFloor.name, buildingId: this.buildingId, buildingName: this.buildingDetails.name,
          userId: this.authService.getUserKeyData().userId
        }

        const favUpdatedData: any = [];
        if (this.favoriteEquipmentData.filter((x: any) => (x.isFavorateUpdate || x.isDeleted || x.id < 1 || (x.childFavouriteEquipments && x.childFavouriteEquipments.length > 0 && x.childFavouriteEquipments.filter((y: any) => y.isDeleted || y.id < 1 || y.isFavorateUpdate).length > 0))).length > 0) {
          this.favoriteEquipmentData.filter((x: any) => (x.isFavorateUpdate || x.isDeleted || x.id < 1 || (x.childFavouriteEquipments && x.childFavouriteEquipments.length > 0 && x.childFavouriteEquipments.filter((y: any) => y.isDeleted || y.id < 1 || y.isFavorateUpdate).length > 0))).forEach((element: any) => {
            const favData: any = _.cloneDeep(element);
            favUpdatedData.push(favData)
          });
        }
        this.floorIndexDBService.getSpecificDeviceKeyFromIndexDB("commonDetails", this.floorId, commonData, isFromFav ? true : false, favUpdatedData, this.updatedFloorEquipmentValues);
        this.setSearchDataInHeader();
      }
    } else {
      this.actionBtn.edit = false;
      this.actionBtn.save = false;
      this.totalDiscardCount = 0;
      if (this.authService.getUserKeyData().isLocalSaveEnable) {
        this.floorIndexDBService.deleteAllRecordsFromIndexDB();
      }
    }
    if (isFromIndexDB) {
      this.isShowLoader = false
    }
  }

  closeEquipmentDetails() {
    if (!this.isImageLoadingLocal && !this.floorPlanClicked) {
      let equipment = FloorUtils.getEquipment(this.selectedFloor, this.equipmentDetails.parentId > 0 ? this.equipmentDetails.parentId : this.equipmentDetails.id);
      if (equipment) {
        equipment = this.setDevices(equipment);
        equipment.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(equipment, this.isProject, false, this.pinColorType, this.assignedToFilter, this.filterCategoryData);
      }
      this.equipmentDetails = null;
      this.toggleSecondary = false;
      this.updateConnection = false;
      this.isCameraOpen = false;
    } else {
      if (this.isImageLoadingLocal && !this.floorPlanClicked) {
        this.messageService.warningMessage('Processing Images', 'Please wait while SiteOwl is processing your image attachments locally.');
      } else if (!this.isImageLoadingLocal && this.floorPlanClicked) {
        let equipment = FloorUtils.getEquipment(this.selectedFloor, this.equipmentDetails.parentId > 0 ? this.equipmentDetails.parentId : this.equipmentDetails.id);
        this.equipmentDetails = null;
        if (equipment) {
          equipment = this.setDevices(equipment);
          equipment.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(equipment, this.isProject, false, this.pinColorType, this.assignedToFilter, this.filterCategoryData);
        }
        this.toggleSecondary = false;
        this.updateConnection = false;
        this.isCameraOpen = false;
      }
      this.floorPlanClicked = true;
    }
    this.cdRef.detectChanges();
  }
  setCoverageElements(device: any, displaySize: any) {
    if (device.coverageArea) {
      let angle = device.coverageAngle;
      if (device.coverageAngle === 360 || device.coverageAngle === 0) {
        angle = 359.9999;
      }
      device["dimension"] = CoverageCone.describeArc(0, 0, 100, device.coverageDirection - (1 / 2) * angle, device.coverageDirection + (1 / 2) * angle);
      const coneLength = (device.coverageRange * displaySize) / 200;
      device["dimensionWidth"] = coneLength.toString() + "px";

      const SvgWidth = document.getElementById('coveragesvg1_' + device.id + '_' + device.name);
      if (SvgWidth) {
        SvgWidth.style.width = device["dimensionWidth"];
      }

    }
    return device;
  }
  getTranslate(deviceItem: any) {
    Sentry.metrics.increment("set.cone.icon.called", 1);
    console.log('this.getTranslate called', this.getTranslateCalledCount)
    if (this.equipmentDetails && this.equipmentDetails.id === deviceItem.id && !this.actionBtn.edit) {
      if (deviceItem && (deviceItem.dimensionWidth === undefined || deviceItem.dimensionWidth === null)) {
        deviceItem = this.setCoverageElements(deviceItem, this.largerDimension);
      }
      this.getTranslateCalledCount += 1;
      const checkCssOpacity = document.getElementById("equipemnt_" + deviceItem.id);
      let isParentTransparent = false
      if (checkCssOpacity && checkCssOpacity.className.includes('show-primary-pin')) {
        isParentTransparent = true;
      }
      if (deviceItem && document.getElementById("arc1_" + deviceItem.id + "_" + deviceItem.name) && document.getElementById('arc_g1_div_' + deviceItem.id + "_" + deviceItem.name)) {

        const myNewSvg: any = document.getElementById("coveragesvg_dev_" + deviceItem.id + "_" + deviceItem.name);
        const myarc: any = document.getElementById("arc1_" + deviceItem.id + "_" + deviceItem.name);
        const widthRadius = _.cloneDeep(parseInt(deviceItem.dimensionWidth));
        let radius;
        if ((this.zoomFactor * parseInt(deviceItem.dimensionWidth, 10) + 30) > 100) {
          radius = ((widthRadius * this.zoomFactor) + 30)
        } else {
          radius = 100;
        }
        const start = deviceItem.coverageDirection;
        const end = deviceItem.coverageDirection;
        const spacerX = (22 * Math.sin(Math.PI * 2 * (start - 80) / 360));
        const spacerY = (22 * Math.cos(Math.PI * 2 * (end - 80) / 360));
        const x = (radius * Math.sin(Math.PI * 2 * start / 360) + spacerX);
        const y = (radius * Math.cos(Math.PI * 2 * end / 360) + spacerY);
        let scale: any = 1
        this.rotateDomChanges(_.cloneDeep(deviceItem), start, end, radius, scale, isParentTransparent);
        this.leftSideDomChanges(_.cloneDeep(deviceItem), radius, isParentTransparent);
        this.rightSideDomChanges(_.cloneDeep(deviceItem), radius, isParentTransparent);
        if (!isNaN(x) && !isNaN(y) && myarc !== undefined && myarc !== null) {
          scale = this.zoomFactor > 0.7 ? this.zoomFactor : 0.7;

          const rotateG = document.getElementById('arc_g1_div_' + deviceItem.id + "_" + deviceItem.name)
          if (rotateG) {
            rotateG.style.transform = 'rotate(' + (deviceItem.coverageDirection - 50) + 'deg)'
          }
          const text = document.getElementById('arc_text_div_' + deviceItem.id + "_" + deviceItem.name);
          if (text) {
            text.setAttribute("x", (x).toString());
            text.setAttribute("y", (-y).toString());
          }

          const circle = document.getElementById('arc_div_circle_' + deviceItem.id + "_" + deviceItem.name);
          if (circle) {
            circle.setAttribute("cx", (x).toString());
            circle.setAttribute("cy", (-y).toString());
          }
          if (myNewSvg) {
            const coverageRange: any = document.getElementById('arc_div_' + deviceItem.id + '_' + deviceItem.name);
            coverageRange.style.opacity = myNewSvg.style.opacity = '1'
            if (isParentTransparent) {
              myNewSvg.style.opacity = '0.5'
            }
          }
        }
        console.log('get translate end', moment.now())
      } else {
        const timeOut = setTimeout(() => {
          this.getTranslate(deviceItem)
          clearTimeout(timeOut);
        }, 100);
      }
    }
  }
  updateCoverage(device: any, isFromEquipmentDetails = false) {

    this.updatingCoverageValue = true;
    device = this.setCoverageElements(device, this.largerDimension);
    let coverageDiff: any
    if (this.equipmentDetails && !isFromEquipmentDetails) {
      coverageDiff = Utilities.difference(_.cloneDeep(device), _.cloneDeep(this.equipmentDetails));
    } else {
      coverageDiff = Utilities.difference(_.cloneDeep(device), _.cloneDeep(FloorUtils.getEquipment(this.selectedFloor, device.id)));
    }
    if (coverageDiff && coverageDiff.childFloorEquipments && coverageDiff.childFloorEquipments.length > 0) {
      delete coverageDiff.childFloorEquipments;
    }
    coverageDiff['type'] = DeviceTypes.Device;
    if (this.equipmentDetails && !isFromEquipmentDetails) {
      this.equipmentDetails = _.cloneDeep(device);
    }
    coverageDiff['id'] = this.equipmentDetails.id
    coverageDiff['isUpdate'] = true
    if (this.equipmentDetails.parentId && this.equipmentDetails.parentId > 0) {
      coverageDiff['parentId'] = this.equipmentDetails.parentId
    }
    coverageDiff = this.removeExtraKeys(coverageDiff);
    const floorDevice: any = FloorUtils.getEquipment(this.selectedFloor, device.id);
    if (floorDevice) {
      Object.keys(coverageDiff).forEach(element => {
        if (element !== "id") {
          floorDevice[element] = coverageDiff[element];
        }
      });
      floorDevice.dimension = device.dimension;
      floorDevice.dimensionWidth = device.dimensionWidth;
    }
    this.updatedKeyValueJson(coverageDiff)
    if (isFromEquipmentDetails) {
      let deviceUpdate = FloorUtils.getEquipment(this.selectedFloor, device.id);
      deviceUpdate = device;
    }
    this.showCountFilterPopup = false;
    this.countFilterClicked = false;
    this.showIconsForCoverage();
  }
  rotateDomChanges(item: any, start: any, end: any, radius: any, scale: any, isParentTransparent: any) {
    FloorUtils.rotateDomChanges(item, start, end, radius, scale, isParentTransparent)
  }
  leftSideDomChanges(item: any, radius: any, isParentTransparent: any) {
    FloorUtils.leftSideDomChanges(item, radius, isParentTransparent)
  }
  rightSideDomChanges(item: any, radius: any, isParentTransparent: any) {
    FloorUtils.rightSideDomChanges(item, radius, isParentTransparent)

  }
  setCableOrConnectionLine(device: FloorEquipment, connectedDevice?: any, isFirst = false) {
    device.isConnectionLineOrCablePath = (device.isHub || (device.networkSwitchLinkId && device.networkSwitchLinkId > 0)) ? true : false
    device.cableLinkImageLeft = "";
    device.cableLinkImageTop = "";
    const tmpDevice: any = connectedDevice ? connectedDevice : FloorUtils.getEquipment(this.selectedFloor, device.networkSwitchLinkId);
    if (device.isConnectionLineOrCablePath && tmpDevice) {
      if (device.cableCoordinates !== null && typeof device.cableCoordinates === "string") {
        device.cableCoordinates = _.cloneDeep(JSON.parse(device.cableCoordinates));
        if (device.cableCoordinates.filter((e: any) => e.index === 1).length === 0) {
          device.cableCoordinates.unshift({ x: device.imageLeft, y: device.imageTop, index: 1, id: device.id });
        }
      }
      if (tmpDevice !== undefined && tmpDevice !== null) {
        const cableDevice = FloorUtils.filterDevices([tmpDevice], { devices: this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter, isChild: false });
        if (cableDevice && cableDevice.length > 0) {
          const cableLink = cableDevice[0];
          if (cableLink !== undefined) {
            let ifChildElement: any = cableLink;
            if (cableLink.parentId) {
              ifChildElement = FloorUtils.getEquipment(this.selectedFloor, cableLink.parentId);
            }
            device.cableLinkImageLeft = ifChildElement.imageLeft;
            device.cableLinkImageTop = ifChildElement.imageTop;
            if (device.cableCoordinates && device.cableCoordinates.filter((e: any) => (e.x === ifChildElement.imageLeft && e.y === ifChildElement.imageTop) || e.id === cableLink.id).length === 0) {
              device.cableCoordinates.push({ x: ifChildElement.imageLeft, y: ifChildElement.imageTop, index: device.cableCoordinates.length, id: cableLink.id });
            }
            if (this.equipmentDetails && this.equipmentDetails.id === device.id) {
              this.equipmentDetails.cableLinkImageLeft = ifChildElement.imageLeft;
              this.equipmentDetails.cableLinkImageTop = ifChildElement.imageTop;
            }
          }
        } else {
          device.isConnectionLineOrCablePath = false;
        }
      }
      if (!isFirst && device !== undefined && device !== null && tmpDevice !== undefined && tmpDevice !== null) {
        device.connectionLength = this.getConnectionLength(device, tmpDevice);
      }
    } else {
      device.isConnectionLineOrCablePath = false
    }

    const deviceChild: any = device.childFloorEquipments;
    if (deviceChild && deviceChild.length > 0) {
      deviceChild.forEach((c: any) => {
        c.cableLinkImageLeft = ""
        c.cableLinkImageTop = ""
        c.isConnectionLineOrCablePath = (c.networkSwitchLinkId && c.networkSwitchLinkId > 0) ? true : false
        const tmpChildDevice: any = FloorUtils.getEquipment(this.selectedFloor, c.networkSwitchLinkId);
        if (c.isConnectionLineOrCablePath) {
          if (c.cableCoordinates !== null && typeof c.cableCoordinates === "string") {
            c.cableCoordinates = _.cloneDeep(JSON.parse(c.cableCoordinates));
            if (c.cableCoordinates.filter((e: any) => e.index === 1).length === 0) {
              c.cableCoordinates.unshift({ x: device.imageLeft, y: device.imageTop, index: 1, id: c.id });
            }
          }
          if (tmpChildDevice !== undefined && tmpChildDevice !== null) {
            const cableLinkChild: any = FloorUtils.filterDevices([tmpChildDevice], { devices: this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter, isChild: false });
            if (cableLinkChild !== undefined && cableLinkChild.length > 0) {
              c.isConnectionLineOrCablePath = _.cloneDeep(c.isConnectionLineOrCablePath);
              let ifChildElement: any = cableLinkChild[0];
              if (cableLinkChild[0].parentId) {
                ifChildElement = FloorUtils.getEquipment(this.selectedFloor, cableLinkChild[0].parentId);
              }
              c.cableLinkImageLeft = ifChildElement.imageLeft;
              c.cableLinkImageTop = ifChildElement.imageTop;
              if (c.cableCoordinates && c.cableCoordinates.filter((e: any) => (e.x === ifChildElement.imageLeft && e.y === ifChildElement.imageTop) || e.id === cableLinkChild[0].id).length === 0) {
                c.cableCoordinates.push({ x: ifChildElement.imageLeft, y: ifChildElement.imageTop, index: c.cableCoordinates.length, id: cableLinkChild[0].id });
              }
              if (this.equipmentDetails && this.equipmentDetails.id === c.id) {
                this.equipmentDetails.cableLinkImageLeft = ifChildElement.imageLeft;
                this.equipmentDetails.cableLinkImageTop = ifChildElement.imageTop;
              }
            }
          } else {
            c.isConnectionLineOrCablePath = false;
          }
          if (!isFirst && c !== undefined && c !== null && tmpChildDevice !== undefined && tmpChildDevice !== null) {
            c.connectionLength = (device.isUpdate || c.isUpdate || c.tempId !== null) ? this.getConnectionLength(c, tmpChildDevice) : c.connectionLength;
          }
          if (this.equipmentDetails && this.equipmentDetails.id === c.id) {
            this.equipmentDetails.connectionLength = c.connectionLength;
            this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
          }
        }
        if (c.isConnectionLineOrCablePath && (c.cableLinkImageLeft === "" || c.cableLinkImageTop === "")) {
          c.isConnectionLineOrCablePath = _.cloneDeep(false);
        }
      });
    }
    if (device.isConnectionLineOrCablePath && (device.cableLinkImageLeft === "" || device.cableLinkImageTop === "")) {
      device.isConnectionLineOrCablePath = _.cloneDeep(false);
    }
    if (this.equipmentDetails && this.equipmentDetails.id === device.id) {
      this.equipmentDetails.connectionLength = device.connectionLength;
      this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
    }
    this.cdRef.detectChanges();
    return device;
  }

  callAPIForPlan() {
    if (!this.isProject) {
      this.floorPlanService.getSiteFloorDetails(this.buildingId, this.floorId).subscribe({
        next: (res: any) => {
          this.initialSetFloorPlan(res);
          this.getEquipmentListData();
          this.getFloorPlanConfiguration(true);
        },
        error: (e: any) => {
          console.log(e)
        }
      })
    } else {
      this.floorPlanService.getProjectFloorDetails(this.projectId, this.buildingId, this.floorId).subscribe({
        next: (res: any) => {
          this.initialSetFloorPlan(res);
          this.getEquipmentListData();
          this.getFloorPlanConfiguration(true);
        },
        error: (e: any) => {
          console.log(e)
        }
      })
    }
  }

  initialSetFloorPlan(buildings: any) {
    this.buildingDetails = buildings;
    this.isProjectArchived = this.buildingDetails.isProjectArchived;
    this.isSiteArchived = this.buildingDetails.isSiteArchived;
    this.defaultFloorDetails = _.cloneDeep(buildings.floors.find((x: any) => x.id === parseInt(this.activatedRoute.snapshot.params['floorId'])))
    this.selectedFloor = _.cloneDeep(buildings.floors.find((x: any) => x.id === parseInt(this.activatedRoute.snapshot.params['floorId'])));
    this.setSearchDataInHeader();
    this.showPlanMarkupFilter = this.selectedFloor.isfloorImageEdited;
    this.isLiveDeviceAvailable = this.selectedFloor.isLiveDeviceAvailable;
    this.planImage = this.selectedFloor.awsImageURL;
    this.floorId = this.selectedFloor.id;
    this.buildingId = buildings.id;
    this.isLinkToTicket = false;
    if (this.selectedFloor.serviceTicket && this.selectedFloor.serviceTicket.length > 0 && this.selectedFloor.serviceTicket.filter((t: any) => t.status !== 'Closed').length > 0) {
      this.isLinkToTicket = true;
    }
    if (this.projectId) {
      this.getProjectUsers();
      if (this.currentFloorLiveDevice && this.currentFloorLiveDevice.length > 0) {
        this.setLiveDevices();
      }
    } else {
      this.getAMTUsers();
    }
    this.getNetworkSwitchDevice();
    if (this.equipmentDetails && this.equipmentDetails.id >= 1) {
      if (this.equipmentDetails && this.equipmentDetails.coverageArea && !this.actionBtn.edit && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice && (this.permissions.canEdit || this.permissions.canManage || this.permissions.canUpdateDevice)) {
        this.equipmentDetails = this.setCoverageElements(this.equipmentDetails, this.largerDimension);
        this.showIconsForCoverage();
      }
    } else {
      if (this.equipmentDetails) {
        const checkSencondary = this.selectedFloor.floorEquipments.filter((e: any) => e.imageLeft === this.equipmentDetails.imageLeft && e.imageTop === this.equipmentDetails.imageTop)[0];
        const checkChildData = checkSencondary && checkSencondary.childFloorEquipments.filter((e: any) => e.orderWithinParent === this.equipmentDetails.orderWithinParent && e.name === this.equipmentDetails.name);
        if (checkChildData && checkChildData.length > 0) {
          this.equipmentDetails = _.cloneDeep(checkChildData[0]);
        } else if (this.selectedFloor.floorEquipments.filter((e: any) => e.imageLeft === this.equipmentDetails.imageLeft && e.imageTop === this.equipmentDetails.imageTop && e.name === this.equipmentDetails.name).length > 0) {
          const checkData = this.selectedFloor.floorEquipments.filter((e: any) => e.imageLeft === this.equipmentDetails.imageLeft && e.imageTop === this.equipmentDetails.imageTop && e.name === this.equipmentDetails.name)[0];
          this.equipmentDetails = _.cloneDeep(checkData);
        } else {
          this.equipmentDetails = null;
        }
      } else {
        this.equipmentDetails = null;
      }
    }
    if (this.selectedChild && this.selectedChild.id >= 1) {
      this.selectedChild = FloorUtils.getEquipment(this.selectedFloor, this.selectedChild.id);
    } else {
      if (this.selectedChild && this.selectedItem) {
        const checkSencondary = this.selectedFloor.floorEquipments.filter((e: any) => e.imageLeft === this.selectedItem.imageLeft && e.imageTop === this.selectedItem.imageTop)[0];
        const checkChildData = checkSencondary && checkSencondary.childFloorEquipments.filter((e: any) => e.orderWithinParent === this.selectedChild.orderWithinParent && e.name === this.selectedChild.name);
        if (checkChildData && checkChildData.length > 0) {
          this.selectedChild = _.cloneDeep(checkChildData[0]);
        } else {
          this.selectedChild = null;
        }
      } else {
        this.selectedChild = null;
      }
    }
    if (this.selectedItem && this.selectedItem.id >= 1) {

      this.selectedItem = FloorUtils.getEquipment(this.selectedFloor, this.selectedItem.id)

    } else {
      if (this.selectedItem) {
        const updatedDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.imageLeft === this.selectedItem.imageLeft && e.imageTop === this.selectedItem.imageTop)[0];
        if (updatedDevice) {
          this.selectedItem = updatedDevice;
        }
      }
    }
    this.cdRef.detectChanges();
    if (this.isBuildingChanged || this.isPlanChanged) {
      const timeOut = setTimeout(() => {
        this.zoomFactor = 1;
        this.fitToScreen = true;
        this.ngAfterViewInit();
        this.getFloorPlanConfiguration(true);
        this.isBuildingChanged = false;
        this.isPlanChanged = false;
        clearTimeout(timeOut)
      }, 100);
    }
    this.cdRef.detectChanges();
  }

  saveFloorPlan() {
    this.syncStarted = true;
    this.btnService.disable();
    this.presignedURL = [];
    if (this.authService.getUserKeyData().isLocalSaveEnable) {
      this.floorIndexDBService.deleteSyncDetails();
    }
    this.updatedFloorEquipmentValues.forEach((element: any) => {
      if (element.images && element.images.length > 0) {
        // eslint-disable-next-line no-prototype-builtins
        element.images = _.cloneDeep(element.images.filter((obj: any) => (!obj.hasOwnProperty('id') || obj.isNewFile || obj.isUpdate || obj.isDeleted)));
      }
      if (element.cableCoordinates && typeof element.cableCoordinates !== 'string') {
        element.cableCoordinates.shift();
        element.cableCoordinates.pop();
        if (element.cableCoordinates.length === 0) {
          element.cableCoordinates = null;
        }
        element.cableCoordinates = JSON.stringify(element.cableCoordinates);
      }
      if (element.childFloorEquipments && element.childFloorEquipments.length > 0) {
        element.childFloorEquipments.forEach((elementChild: any) => {
          if (elementChild.cableCoordinates && typeof elementChild.cableCoordinates !== 'string') {
            elementChild.cableCoordinates.shift();
            elementChild.cableCoordinates.pop();
            elementChild.cableCoordinates = JSON.stringify(elementChild.cableCoordinates);
            if (elementChild.cableCoordinates.length === 0) {
              elementChild.cableCoordinates = null;
            }
          }
          elementChild = _.cloneDeep(this.removeExtraKeys(elementChild, true));
        });
      }
      element = _.cloneDeep(this.removeExtraKeys(element, element.parentId ? true : false));
    });
    const serverImgs = _.cloneDeep(this.updatedFloorEquipmentValues.filter((x: any) => x.images && x.images.filter((y: any) => y.isNewFile || y.isUpdate)));
    if (serverImgs && serverImgs.length > 0) {
      this.uploadNewFile = []
      serverImgs.forEach((simg: any) => {
        simg.images.forEach((imgUpload: any, index: any) => {
          if (imgUpload.fileData !== undefined && !imgUpload.isDeleted && imgUpload.fileLocation !== undefined) {
            this.presignedURL.push(imgUpload.fileLocation);
            const imgData: any = _.cloneDeep(imgUpload);
            this.uploadNewFile.push(imgData)

            delete imgUpload['createdDateTime']
            delete imgUpload['imgId']
            delete imgUpload['tempId']
            if (imgUpload.isNewFile) {
              delete imgUpload['thumbnailAwsImageURL']
              delete imgUpload['awsImageURL']
            }
            if (imgUpload.isUpdate) {
              delete imgUpload['isImageExsits']
              delete imgUpload['isImageLoading']
              delete imgUpload['modifiedDateTime']
              delete imgUpload['updatedBy']
              delete imgUpload['updatedByUser']
              delete imgUpload['createdBy']
              delete imgUpload['floorId']
              delete imgUpload['imageCheckCnt']
              delete imgUpload['tempId']
            }

          } else if (imgUpload.id > 0 && imgUpload.isDeleted && imgUpload.isNewFile) {
            const removeImage: any = this.updatedFloorEquipmentValues.filter((x: any) => x.id === simg.id)[0]['images'];
            if (removeImage && removeImage.filter((y: any) => y.id === imgUpload.id).length > 0) {
              const removeExtraElements: any = removeImage.filter((y: any) => y.id === imgUpload.id)[0];
              delete removeExtraElements['awsImageURL']
              delete removeExtraElements['createdBy']
              delete removeExtraElements['createdByUser']
              delete removeExtraElements['createdDateTime']
              delete removeExtraElements['fileData']
              delete removeExtraElements['fileLocation']
              delete removeExtraElements['fileName']
              delete removeExtraElements['floorId']
              delete removeExtraElements['imageCheckCnt']
              delete removeExtraElements['isImageExsits']
              delete removeExtraElements['isImageLoading']
              delete removeExtraElements['isNewFile']
              delete removeExtraElements['isUpdate']
              delete removeExtraElements['mode']
              delete removeExtraElements['modifiedDateTime']
              delete removeExtraElements['source']
              delete removeExtraElements['stage']
              delete removeExtraElements['thumbnailAwsImageURL']
              delete removeExtraElements['updatedBy']
              delete removeExtraElements['updatedByUser']
            }
          }
        });
      });
    }
    if (this.uploadNewFloorFile.length > 0) {
      this.uploadNewFile.push(this.uploadNewFloorFile[0]);
      this.uploadNewFile = _.uniqBy(this.uploadNewFile, 'fileLocation')
      this.presignedURL.push("FLOOR_IMAGE_" + this.uploadNewFloorFile[0].fileName)
    }
    if (this.isFavoriteUpdated) {
      this.prepareFavJsonForServer();
      if (this.favUpload.length > 0) {
        this.presignedURL.push("favorite_favorite.json");
      }
    }
    if (this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0) {
      this.presignedURL.push("floorDetail_floorDetail.json");
    }
    const syncData: any = {
      deviceCnt: this.totalDeviceCount + this.totalFavoriteCount,
      taskCnt: this.totalTaskCount,
      imageCnt: this.uploadNewFile && this.uploadNewFile.length > 0 ? this.uploadNewFile.length : 0,
      imgUploadedCnt: 0,
      totalDiscardCount: this.totalDiscardCount - this.uploadNewFloorFile.length,
      endSyncCallStarted: false,
      stopEndApiCall: false
    }
    const initialState: ModalOptions = {
      initialState: syncData, class: '', ignoreBackdropClick: true
    };

    if (this.presignedURL.length > 0) {
      this.bsModalRef = this.modalService.show(SyncPopupComponent, initialState);
      this.bsModalRef.content.closeEvent.subscribe((res: any) => {
        if (res === 'cancel') {
          if (this.bsModalRef) {
            this.bsModalRef.content.stopEndApiCall = true;
          }
          this.isStopApiCall = _.cloneDeep(false);
          this.bsModalRef?.hide();
          this.btnService.enable();
          this.syncStarted = false;
        }
      });

      this.floorPlanService.startSynCall(this.isProject ? this.projectId : this.siteId, this.projectId, this.floorId).subscribe({
        next: (r: any) => {
          this.isStopApiCall = true;
          this.folderName = r.message;
          const syncId = r.syncId;
          if (this.authService.getUserKeyData().isLocalSaveEnable) {
            this.floorIndexDBService.syncDataIndexDB('syncDetails', this.floorId, syncId, this.folderName, this.totalDiscardCount, this.uploadNewFile.length, false, this.presignedURL)
          }
          const reqData = {
            image_location: "DEVICE_TEMP_IMAGE",
            temp_folder: r.message,
          };
          //getting presingedURLs
          this.floorPlanService.getPresignedURLs(reqData, this.presignedURL).subscribe({
            next: (r: any) => {
              if (this.uploadNewFile && this.uploadNewFile.length > 0) {
                let imgCnt = 0;
                this.imgUploadedCnt = 0
                r.forEach((element: any) => {
                  if (!element.match("_floorDetail.json") && !element.match("_favorite.json")) {
                    if ((this.uploadNewFile && this.uploadNewFile.length > 0)) {
                      this.uploadNewFile.forEach((img: any) => {
                        if (element.match(img.fileLocation)) {
                          Utilities.getCanvasBlob(img.fileData.extension, img.awsImageURL).then(
                            (blob: any) => {
                              if (this.bsModalRef && !this.bsModalRef.content.stopEndApiCall) {
                                this.floorPlanService.uploadImageToS3Bucket(element, blob, this.isStopApiCall).subscribe({
                                  next: (res: any) => {
                                    imgCnt = imgCnt + 1
                                    this.imgUploadedCnt = this.imgUploadedCnt + 1;
                                    if (this.bsModalRef) {
                                      this.bsModalRef.content.imgUploadedCnt = this.imgUploadedCnt;
                                    }
                                    if (imgCnt === this.uploadNewFile.length) {
                                      if (this.favUpload && this.favUpload.length > 0 && this.isStopApiCall && r.filter((u: any) => u.includes(this.folderName + '/favorite_favorite.json'))) {
                                        this.uploadFavJsonFile(r.filter((u: any) => u.includes(this.folderName + '/favorite_favorite.json'))[0], r);
                                      } else {
                                        this.uploadFloorDetailsJson(r);
                                      }
                                    }
                                  }, err: (e: any) => {
                                    this.btnService.enable();
                                    this.isStopApiCall = _.cloneDeep(false);
                                    this.syncStarted = false;
                                    this.bsModalRef?.hide();
                                  }
                                })
                              }
                            })
                        }
                      })
                    }
                  }
                });
              } else {
                if (this.favUpload && this.favUpload.length > 0 && this.isStopApiCall && r.filter((u: any) => u.includes(this.folderName + '/favorite_favorite.json'))) {
                  this.uploadFavJsonFile(r.filter((u: any) => u.includes(this.folderName + '/favorite_favorite.json'))[0], r);
                } else {
                  this.uploadFloorDetailsJson(r);
                }
              }
            }, err: (pe: any) => {
              this.btnService.enable();
              this.isStopApiCall = _.cloneDeep(false);
              this.syncStarted = false;
              this.bsModalRef?.hide();
              console.log(pe)
            }
          })
        },
        error: (e: any) => {
          this.btnService.enable();
          this.isStopApiCall = _.cloneDeep(false);
          this.syncStarted = false;
          this.bsModalRef?.hide();
          if (e.status === 401) {
            this.totalDiscardCount = 0;
          }
        }
      })
    } else {
      this.btnService.enable();
      this.totalDiscardCount = 0;
      this.isStopApiCall = _.cloneDeep(false);
      this.syncStarted = false;
      this.actionBtn.edit = this.actionBtn.save = false;
    }
  }

  uploadJsonFile(url: any) {
    if (this.bsModalRef && !this.bsModalRef.content.stopEndApiCall) {
      const changeData: any = _.cloneDeep(this.updatedFloorEquipmentValues)
      Utilities.convertNullToNA(changeData).then((r: any) => {
        const reqData: any = {
          id: this.floorId,
          floorEquipments: r
        }
        if (this.uploadNewFloorFile && this.uploadNewFloorFile.length > 0) {
          reqData['fileName'] = "FLOOR_IMAGE_" + this.uploadNewFloorFile[0].fileName;
          reqData['isNewFile'] = true
        }
        this.isProject ? reqData['projectId'] = this.projectId : reqData['siteId'] = this.siteId;
        this.floorPlanService.uploadJsonFileToServer(url, reqData).subscribe({
          next: (r: any) => {
            this.endSyncCall();
          }, err: (j: any) => {
            this.btnService.enable();
            this.isStopApiCall = _.cloneDeep(false);
            this.syncStarted = false;
            this.bsModalRef?.hide();
            console.log("JSON Upload error", j)
          }
        })
      })
    }
  }

  contextClick(device: any, event: any, type: any, selectedChild?: any) {
    event.preventDefault();
    if (document.getElementsByClassName('dropdown-menu') && document.getElementsByClassName('dropdown-menu').length > 0) {
      document.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
    }
    this.percentContextMenuPos = ((event.clientX - 366) / this.containerWidth) * 100;
    this.contextmenuX = _.cloneDeep(event.clientX);
    this.contextmenuY = _.cloneDeep(event.clientY);
    this.contextOpen = true;
    this.selectedItem = device;
    this.selectedChild = selectedChild || null;
    this.cdRef.detectChanges();
  }
  contextAction(event: any) {
    if (event === 'duplicate') {
      this.duplicateDevice(event);
    } else if (event === 'createTicket') {
      this.createTicket(event);
    } else if (event === 'linkTicket') {
      this.filterTickets(this.selectedChild ? this.selectedChild : this.selectedItem);
      this.linkTicket(this.selectedChild ? this.selectedChild : this.selectedItem);
    } else if (event === 'createHub') {
      this.createHub(event);
    } else if (event === 'removeHub') {
      this.removeHub(event);
    } else if (event === 'addFlag') {
      this.addFlag(event);
    } else if (event === 'removeFlag') {
      this.removeFlag(false);
    } else if (event === 'archive') {
      this.archiveDevice();
    } else if (event === 'unArchive') {
      this.unarchiveDevice();
    } else if (event === 'delete') {
      this.deleteDevice();
    } else if (event === 'createFavorite') {
      this.createFav();
    } else if (event === 'viewHistory') {
      this.deviceHistory(this.selectedChild ? this.selectedChild : this.selectedItem)
    }
    this.contextOpen = false;
  }
  removeFlag(event: any) {
    let deviceData: any;
    if (event) {
      deviceData = _.cloneDeep(this.equipmentDetails);
    } else {
      deviceData = this.selectedChild ? _.cloneDeep(this.selectedChild) : _.cloneDeep(this.selectedItem);
    }
    // Resolve Flag
    if (deviceData.isFlag && deviceData.flagCreatedBy !== undefined && deviceData.flagCreatedBy !== null && deviceData.flagCreatedBy.name !== null) {
      const initialState: ModalOptions = {
        initialState: {
          isShowNofiy: deviceData.flagCreatedBy.id !== this.authService.getUserKeyData().userId ? true : false,
          resolveFlag: true,
          deviceDetails: deviceData !== null ? _.cloneDeep(deviceData) : (this.selectedItem !== null ? _.cloneDeep(this.selectedItem) : _.cloneDeep(this.selectedChild))
        }, class: 'modal-dialog modal-md', ignoreBackdropClick: true
      };

      this.bsModalRef = this.modalService.show(AddFlagComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res) {
          // const deviceData = this.selectedChild ? _.cloneDeep(this.selectedChild) : _.cloneDeep(this.selectedItem);
          if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
            this.equipmentDetails.isFlag = false;
            this.equipmentDetails.isFlagAdd = false;
            this.equipmentDetails.flagNotes = res.flagNote;
            this.equipmentDetails.isUpdate = true;
            this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
          }
          if (deviceData.parentId) {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
              && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
              this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, parentId: deviceData.parentId, resolveFlagNotes: res.flagNote, isUpdate: true })
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].isChildFlag = true;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isFlag = false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isFlagAdd = false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagBadge = false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagNotes = res.flagNote;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate = true;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0]);
            }
          } else {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
              this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, resolveFlagNotes: res.flagNote, isUpdate: true })
              let parentDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0];
              parentDevice.isFlag = false;
              parentDevice.isFlagAdd = false;
              parentDevice.flagNotes = res.flagNote;
              parentDevice.isUpdate = true;
              parentDevice = this.setDevices(parentDevice);
              parentDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(parentDevice, parentDevice.imageLeft, parentDevice.imageTop);
            }
          }
          this.getUnSavedCount();
        }
        this.selectedItem = null;
        this.selectedChild = null;
        this.detectFloorChanges();
      });
    } else { // Remove Flag
      const dataReplacer = {
        '{{#name}}': event ? _.escape(deviceData.name) : this.selectedChild ? _.escape(this.selectedChild.name) : _.escape(this.selectedItem.name)
      }
      const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(RemoveFlag));
      const initialState: ModalOptions = {
        initialState: modalData, class: Discard.dialogClass, ignoreBackdropClick: true
      }
      this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);

      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res !== 'Cancel') {
          if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
            this.equipmentDetails.isFlag = false;
            this.equipmentDetails.flagNotes = null;
            this.equipmentDetails.isFlagAdd = false;
            this.equipmentDetails.isUpdate = this.equipmentDetails.isUpdate ? true : false;
            this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
          }
          if (deviceData.parentId) {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
              && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
              if (this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id).length > 0) {
                this.removeFlagUpdatedValues(deviceData);
              } else {

                this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, parentId: deviceData.parentId, isFlag: false, isFlagAdd: false, flagNotes: null, isUpdate: deviceData.isUpdate ? true : false })
              }
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].isChildFlag = true;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isFlag = false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isFlagAdd = false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagNotes = null;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate ? true : false;
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
              this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0]);
            }
          } else {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
              if (this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id).length > 0) {
                this.removeFlagUpdatedValues(deviceData);
              } else {

                this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isFlag: false, isFlagAdd: false, flagNotes: null, isUpdate: true })
              }
              let parentDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0];
              parentDevice.isFlag = false;
              parentDevice.isFlagAdd = false;
              parentDevice.flagNotes = null;
              parentDevice.isUpdate = true;
              parentDevice = this.setDevices(parentDevice);
              parentDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(parentDevice, parentDevice.imageLeft, parentDevice.imageTop);
            }
          }
          this.getUnSavedCount();
          this.selectedItem = null;
          this.selectedChild = null;
          this.detectFloorChanges();
        }
      })
    }
  }
  addFlag(event: any) {
    const deviceData = this.selectedChild ? _.cloneDeep(this.selectedChild) : _.cloneDeep(this.selectedItem);
    const initialState: ModalOptions = {
      initialState: {
        resolveFlag: false,
        deviceDetails: this.selectedChild ? _.cloneDeep(this.selectedChild) : _.cloneDeep(this.selectedItem)
      }, class: 'modal-dialog modal-md', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(AddFlagComponent, initialState);
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res) {
        // const deviceData = this.selectedChild ? _.cloneDeep(this.selectedChild) : _.cloneDeep(this.selectedItem);
        if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
          this.equipmentDetails.isFlag = true;
          this.equipmentDetails.isFlagAdd = true;
          this.equipmentDetails.flagNotes = res.flagNote;
          this.equipmentDetails.flagCreatedBy = null;
          this.equipmentDetails.flagCreatedDateTime = null;
          this.equipmentDetails.isUpdate = true;
          this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
        }
        if (deviceData.parentId) {
          if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
            && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, parentId: deviceData.parentId, isFlag: true, isFlagAdd: true, flagNotes: res.flagNote, isUpdate: true })
            let updateSecDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0];
            updateSecDevice.isChildFlag = true;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isFlag = true;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagNotes = res.flagNote;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate = true;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagCreatedBy = null;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].flagCreatedDateTime = null;
            updateSecDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
            updateSecDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(updateSecDevice, updateSecDevice.imageLeft, updateSecDevice.imageTop);
            updateSecDevice.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(updateSecDevice, this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
            updateSecDevice = this.setDevices(updateSecDevice);
          }
        } else {
          if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
            let updateDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0]
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isFlag: true, isFlagAdd: true, flagNotes: res.flagNote, isUpdate: true })
            updateDevice.isFlag = true;
            updateDevice.isFlagAdd = true;
            updateDevice.flagNotes = res.flagNote;
            updateDevice.flagCreatedBy = null;
            updateDevice.flagCreatedDateTime = null;
            updateDevice.isUpdate = true;
            updateDevice = this.setDevices(updateDevice);
            updateDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(updateDevice, updateDevice.imageLeft, updateDevice.imageTop);
          }
        }
        this.getUnSavedCount();
      }
      this.selectedItem = null;
      this.selectedChild = null;
      this.detectFloorChanges();
    });
  }
  removeHub(event: any) {
    const deviceData = this.selectedChild ? this.selectedChild : this.selectedItem;
    if (!deviceData.networkSwitchLinkEquipments || (deviceData.networkSwitchLinkEquipments && deviceData.networkSwitchLinkEquipments.length === 0)) {
      this.removeDeviceAsHub(deviceData);
      this.detectFloorChanges();
    } else {
      const dataReplacer = {
        '{{#name}}': this.selectedChild ? _.escape(this.selectedChild.name) : _.escape(this.selectedItem.name),
        '{{#showHubConnectedCnt}}': deviceData.networkSwitchLinkEquipments.length
      }
      const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(RemoveHub));
      const initialState: ModalOptions = {
        initialState: modalData, class: Discard.dialogClass, ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res !== 'Cancel') {
          this.removeDeviceAsHub(this.selectedChild ? this.selectedChild : this.selectedItem);
          this.removeHubConnections(this.selectedChild ? this.selectedChild : this.selectedItem);
          this.detectFloorChanges();
        }
        this.selectedChild = null;
        this.selectedItem = null;
      });
    }

  }
  removeDeviceAsHub(device: any) {
    if (this.equipmentDetails && this.equipmentDetails.id === device.id) {
      this.equipmentDetails.isHub = false;
      this.equipmentDetails.isUpdate = true;
    }
    if (this.equipmentDetails && this.equipmentDetails.networkSwitchLinkId === device.id) {
      this.equipmentDetails.connectionLength = 0;
      this.equipmentDetails.networkSwitchLinkId = null;
    }
    if (device.parentId) {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId).length > 0
        && this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.filter((x: any) => x.id === device.id).length > 0) {
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.filter((x: any) => x.id === device.id)[0].isHub = false;
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.parentId)[0].childFloorEquipments.filter((x: any) => x.id === device.id)[0].isUpdate = true;
      }
    } else {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id).length > 0) {
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id)[0].isHub = false;
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.id)[0].isUpdate = false;
      }
    }
    if (this.netWorkSwitches.filter((e: any) => e.id === device.id).length > 0) {
      this.netWorkSwitches.splice(this.netWorkSwitches.findIndex((e: any) => e.id === device.id), 1)
    }
    if (device.parentId) {
      this.updatedKeyValueJson({ id: device.id, isUpdate: true, type: "device", isHub: false, parentId: device.parentId });
    } else {
      this.updatedKeyValueJson({ id: device.id, isUpdate: true, type: "device", isHub: false });
    }
  }
  removeHubConnections(hubDevice: any) {
    hubDevice.networkSwitchLinkEquipments.forEach((element: any, index: any) => {
      let connectedDevice = FloorUtils.getEquipment(this.selectedFloor, element.id);
      if (connectedDevice && ((connectedDevice !== undefined && connectedDevice !== null) || connectedDevice.length > 0)) {
        connectedDevice = this.removeDeviceConnections(connectedDevice);
        this.updatedKeyValueJson({
          id: connectedDevice.id, type: connectedDevice.type, parentId: connectedDevice.parentId, networkSwitchLinkFloorId: null,
          networkSwitchLinkName: null,
          networkSwitchLinkId: null,
          cableCoordinates: null, isUpdate: true
        })
        if (this.equipmentDetails && connectedDevice && connectedDevice.id === this.equipmentDetails.id) {
          this.equipmentDetails = connectedDevice;
        }
        if (index === (hubDevice.networkSwitchLinkEquipments.length - 1)) {
          hubDevice.networkSwitchLinkEquipments = []
          const updatedFloorEquipment = _.cloneDeep(this.selectedFloor.floorEquipments);
          this.selectedFloor.floorEquipments = _.cloneDeep(updatedFloorEquipment);
          this.cdRef.detectChanges();
        }
      }
    });
  }
  createHub(event: any) {
    const deviceData: any = this.selectedChild ? this.selectedChild : this.selectedItem;
    if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
      this.equipmentDetails.isHub = true;
      this.equipmentDetails.isUpdate = true;
    }
    if (this.selectedChild) {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
        && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isHub = true;
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate = true;
        this.updatedKeyValueJson({ id: this.selectedChild.id, type: deviceData.type, parentId: this.selectedChild.parentId, isHub: true, isUpdate: true })
      }
    } else {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0].isHub = true;
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0].isUpdate = true;
        this.updatedKeyValueJson({ id: this.selectedItem.id, type: deviceData.type, isHub: true, isUpdate: true })
      }
    }
    if (this.netWorkSwitches.filter((e: any) => e.id === deviceData.id).length === 0) {
      this.netWorkSwitches.push({
        floorId: this.floorId,
        floorName: this.selectedFloor.name,
        id: deviceData.id,
        name: deviceData.name,
        networkSwitchLinkTempId: deviceData.tempId
      });
    }
    this.detectFloorChanges();
    this.selectedChild = null;
    this.selectedItem = null;
  }
  linkTicket(equipment: any) {
    if (this.selectedFloor && this.selectedFloor.serviceTicket.length > 0) {
      const initialState: ModalOptions = {
        initialState: {
          unLinkedTickets: this.unlinkedTicket,
          equipmentId: equipment.id,
          equipmentDetails: equipment,
          buildingDetails: this.buildingDetails,
          floorDetails: this.selectedFloor,
          categoryData: this.filter
        }, class: 'modal-dialog modal-lg', ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(FloorLinkTicketComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res) {
          if (res.newTicket) {
            this.createTicket(res);
          } else {
            if (res.ticket) {
              this.btnService.enable()
              equipment.serviceTickets.push(res.ticket);
              equipment = this.setDevices(equipment);
              if (this.equipmentDetails) {
                if (this.equipmentDetails.id === equipment.id && this.equipmentDetails.serviceTickets.filter((x: any) => x.id === res.ticket.id).length === 0) {
                  this.equipmentDetails.serviceTickets.push(res.ticket);
                }
                this.equipmentDetails = _.cloneDeep(this.setDevices(this.equipmentDetails));
                if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id).length > 0) {
                  if (this.equipmentDetails.id === equipment.id) {
                    this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id)[0].serviceTickets = _.cloneDeep(equipment.serviceTickets);
                  }
                  this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id)[0].showTicketIcon = this.equipmentDetails.showTicketIcon;
                  this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id)[0].ticketCustomClass = this.equipmentDetails.ticketCustomClass;
                  this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id)[0] = _.cloneDeep(this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.id)[0])
                }

                if (this.equipmentDetails.parentId && this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.parentId)[0].childFloorEquipments.filter((x: any) => x.id === this.equipmentDetails.id).length > 0) {
                  this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.parentId)[0].childFloorEquipments.filter((x: any) => x.id === this.equipmentDetails.id)[0].ticketCustomClass = this.equipmentDetails.ticketCustomClass;
                  this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.equipmentDetails.parentId)[0].childFloorEquipments.filter((x: any) => x.id === this.equipmentDetails.id)[0].serviceTickets = this.equipmentDetails.serviceTickets
                }

              }
            }
            this.selectedChild = null;
            this.selectedItem = null;
          }

          this.cdRef.detectChanges();
        }
      });
    }
  }
  createTicket(event: any) {
    const initialState: ModalOptions = {
      initialState: {
        equipmentId: this.selectedChild ? this.selectedChild.id : this.selectedItem.id,
        equipmentDetails: this.selectedChild ? this.selectedChild : this.selectedItem,
        priority: this.selectedChild ? this.selectedChild.priority : this.selectedItem.priority,
        buildingDetails: this.buildingDetails,
        selectedFloor: this.selectedFloor,
        categoryData: this.filter
      }, class: 'modal-dialog modal-xxxl', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(FloorCreateTicketComponent, initialState);
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res) {
        if (this.selectedFloor.serviceTicket.length === 0) {
          this.selectedFloor.serviceTicket = [];
        }
        this.btnService.enable()
        if (this.selectedChild) {
          const floorDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedChild.parentId)
          if (floorDevice.length > 0 && floorDevice[0].childFloorEquipments.filter((x: any) => x.id === this.selectedChild.id).length > 0) {
            const childFloorDevice: any = floorDevice[0].childFloorEquipments.filter((x: any) => x.id === this.selectedChild.id);
            childFloorDevice[0].status = "NotWorking";
            childFloorDevice[0].statusCode = 3;
            childFloorDevice[0].serviceTickets.push(res.ticket);
            childFloorDevice[0] = this.setDevices(childFloorDevice[0]);
            floorDevice[0] = this.setDevices(floorDevice[0]);

          }
        } else {
          const id: any = this.selectedItem !== null ? this.selectedItem.id : (this.equipmentDetails !== null ? this.equipmentDetails.id : res.ticket.serviceTicketFloorEquipment[0]['floorEquipment']['id'])
          const floorDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === id)
          if (floorDevice.length > 0) {
            if (floorDevice[0].serviceTickets === null || floorDevice[0].serviceTickets === undefined) {
              floorDevice[0].serviceTickets = []
            }
            floorDevice[0].serviceTickets.push(res.ticket);
            floorDevice[0].status = "NotWorking";
            floorDevice[0].statusCode = 3;
            floorDevice[0] = this.setDevices(floorDevice[0]);
          }
        }

        this.selectedChild = null;
        this.selectedItem = null;
        if (res !== undefined) {
          this.selectedFloor.serviceTicket.push(res.ticket)
          this.serviceTickets = _.cloneDeep(this.selectedFloor.serviceTicket);
          this.isLinkToTicket = this.serviceTickets.length > 0 ? true : false
          if (this.equipmentDetails && res && res.ticket && res.ticket.serviceTicketFloorEquipment && this.equipmentDetails.id === res.ticket.serviceTicketFloorEquipment[0]['floorEquipment']['id']) {
            if (this.equipmentDetails.serviceTickets === null || this.equipmentDetails.serviceTickets === undefined) {
              this.equipmentDetails.serviceTickets = []
            } else if (this.equipmentDetails.serviceTickets.filter((x: any) => x.id === res.ticket.id).length === 0) {
              this.equipmentDetails.serviceTickets.push(res.ticket);
            }
            this.equipmentDetails.status = "NotWorking";
            this.equipmentDetails.statusCode = 3;
            this.equipmentDetails = _.cloneDeep(this.setDevices(this.equipmentDetails));
          }
        }
        this.detectFloorChanges();
      }
    })
  }
  duplicateDevice(event: any) {
    let data: any;
    if (this.selectedChild) {
      data = this.selectedChild;
    } else {
      data = this.selectedItem;
    }
    let equipment: FloorEquipment = {
      type: data.type || DeviceTypes.Device,
      equipment: data.equipment,
      categoryName: data.categoryName,
      floorName: this.selectedFloor.name,
      buildingName: this.buildingDetails.name,
      floorId: this.floorId,
      imageLeft: (parseFloat(data.imageLeft) + (this.duplicatePos / this.zoomFactor)).toFixed(5) + "%",
      imageTop: data.imageTop,
      status: this.projectId
        ? EquipmentStatusForDevice.NOTWORKING
        : EquipmentStatusForDevice.OPERATIONAL,
      installStatus: EquipmentStatusForDevice.PLANNED,
      isNew: true,
      id: Math.random(),
      siteName: this.buildingDetails.siteName,
      siteId: this.buildingDetails.siteId,
      isSelectedDevice: false,
      isEquipmentFavoriteForm: false,
      projectId: this.projectId || null,
      priority: data.priority || DevicePriority.MEDIUM,
      isPublishedToAMT: false,
      referenceFloorEquipmentId: null,
      canAccessReference: false,
      abbreviatedName: data.abbreviatedName,
      partNumber: data.partNumber,
      deviceLocation: data.deviceLocation,
      manufacturer: data.manufacturer,
      price: data.price,
      dataType: data.dataType,
      description: data.description,
      autoUpdateDeviceStatus: data.autoUpdateDeviceStatus,
      installedByUser: data.installedByUser,
      assignedToUser: data.assignedToUser,
      height: data.height,
      deviceConnectedTo: data.deviceConnectedTo,
      installationNotes: data.installationNotes,
      dueDate: data.dueDate,
      coverageArea: data.coverageArea,
      coverageDirection: data.coverageDirection,
      coverageAngle: data.coverageAngle,
      coverageRange: data.coverageRange,
      pinColor: data.pinColor,
      isHub: data.isHub,
      parentId: data.parentId,
      tempId: 'temp-' + Date.now(),
      childFloorEquipments: [],
      orderWithinParent: 0,
      cableCoordinates: null,
      images: [],
      isLiveDevice: false,
      createdTrigger: 'device-duplicate'
    };
    if (this.isProject) {
      equipment.budgetedCost = data.budgetedCost;
      equipment.installHours = data.installHours;
    }
    if (equipment.coverageArea) {
      equipment = this.setCoverageElements(equipment, this.largerDimension);
    }
    equipment.typeName = equipment.equipment.name;
    if (equipment.imageLeft) {
      if (parseFloat(equipment.imageLeft) >= 100) {
        equipment.imageLeft = ((parseFloat(data.imageLeft)) - (this.duplicatePos / this.zoomFactor)).toFixed(5) + "%";
      }
    }
    if (this.selectedChild) {
      equipment.name = data.name;
    } else {
      const autoName = FloorUtils.checkCountDevice(this.baseNameCount, data.name, false);
      if (autoName.length > 75) {
        const name = autoName.substring(0, autoName.length - (autoName.length - 75));
        equipment.name = FloorUtils.checkCountDevice(this.baseNameCount, name, false);
      } else {
        equipment.name = FloorUtils.checkCountDevice(this.baseNameCount, data.name, false);
      }
    }
    this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, equipment)
    if (equipment.abbreviatedName === null) {
      equipment.abbreviatedName = Utilities.isNull(equipment.equipment.abbreviatedNamePrefix)
        ? undefined
        : equipment.equipment.abbreviatedNamePrefix;
    }
    if (!data.childFloorEquipments) {
      data.childFloorEquipments = [];
    }
    data.childFloorEquipments.forEach((x: any, i: any) => {
      let device = new FloorEquipment(x);
      if (!x.isArchived) {
        device.imageLeft = (parseFloat(data.imageLeft) + this.duplicatePos).toFixed(5) + "%";
        device.imageTop = data.imageTop;
        device.isNew = true;
        device.id = Math.random();
        device.name = x.name;
        device.abbreviatedName = Utilities.isNull(x.abbreviatedName) ? "" : x.abbreviatedName;
        device.partNumber = x.partNumber;
        device.type = x.type || DeviceTypes.Device;
        device.coverageArea = x.coverageArea;
        device.coverageDirection = x.coverageDirection;
        device.coverageAngle = x.coverageAngle;
        device.coverageRange = x.coverageRange;
        device.manufacturer = x.manufacturer;
        device.price = x.price;
        if (this.isProject) {
          device.installHours = x.installHours;
          device.budgetedCost = x.budgetedCost;
        }
        device.dataType = x.dataType;
        device.priority = x.priority || DevicePriority.medium;
        device.description = x.description;
        device.categoryName = x.categoryName;
        device.typeName = x.equipment.name;
        device.projectId = this.projectId || null;
        device.installedByUser = x.installedByUser;
        device.assignedToUser = x.assignedToUser;
        device.height = x.height;
        device.deviceConnectedTo = x.deviceConnectedTo;
        device.installationNotes = x.installationNotes;
        device.dueDate = x.dueDate;
        device.parentId = equipment.id;
        device.pinColor = x.pinColor;
        device.status = this.projectId ? EquipmentStatusForDevice.NOTWORKING : EquipmentStatusForDevice.OPERATIONAL;
        device.isHub = x.isHub
        device.autoUpdateDeviceStatus = "By Monitoring Status";
        device.tempId = 'temp-' + Date.now();
        device.orderWithinParent = (i + 1);
        device = this.setDevices(device);
        device.createdTrigger = 'device-duplicate';
        if (device.imageLeft) {
          if (parseFloat(device.imageLeft) >= 100 && device.imageTop) {
            if ((parseFloat(device.imageTop) + 5) > 100) {
              device.imageTop = '7%';
            } else {
              device.imageTop = (parseFloat(device.imageTop) + 5) + '%';
            }
          }
          device.imageLeft = parseFloat(device.imageLeft) >= 100 ? 5 + '%' : device.imageLeft;
        }
        if (device.coverageArea) {
          device = this.setCoverageElements(device, this.largerDimension);
        }
        equipment.childFloorEquipments.push(device);
        const updatingDevice = _.cloneDeep(device);
        this.updatedFloorEquipmentValues.push(updatingDevice);
        if (device.isHub) {
          this.netWorkSwitches.push({
            floorId: this.floorId,
            floorName: this.selectedFloor.name,
            id: device.id,
            name: device.name,
            networkSwitchLinkTempId: device.tempId
          });
          this.netWorkSwitches = _.orderBy(this.netWorkSwitches, ["name"]);
        }
      }
    });
    if (equipment.isHub) {
      this.netWorkSwitches.push({
        floorId: this.floorId,
        floorName: this.selectedFloor.name,
        id: equipment.id,
        name: equipment.name,
        networkSwitchLinkTempId: equipment.tempId
      });
      this.netWorkSwitches = _.orderBy(this.netWorkSwitches, ["name"]);
    }
    if (equipment.parentId) {
      if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.length === 0) {
        equipment.orderWithinParent = 1;
      } else {
        equipment.orderWithinParent = this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments[this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0].childFloorEquipments.length - 1].orderWithinParent + 1;
      }
    }
    equipment = this.setDevices(equipment);
    equipment.tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(equipment, this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
    equipment.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(equipment, equipment.imageLeft, equipment.imageTop);
    const removeKeyEquipment: any = _.cloneDeep(this.setDevices(equipment));
    if (this.selectedChild) {
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedItem.id)[0].childFloorEquipments.push(equipment);
      this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedItem.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === equipment.parentId)[0]);

    } else {
      this.selectedFloor.floorEquipments.push(equipment);
    }
    removeKeyEquipment.childFloorEquipments = [];
    this.updatedFloorEquipmentValues.push(removeKeyEquipment);
    if (this.selectedChild) {
      this.openEquipmentDetail({ pageX: equipment.imageLeft, pageY: equipment.imageTop }, this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedItem.id)[0].childFloorEquipments[this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedItem.id)[0].childFloorEquipments.length - 1]);
    } else {
      this.openEquipmentDetail({ pageX: equipment.imageLeft, pageY: equipment.imageTop }, this.selectedFloor.floorEquipments[this.selectedFloor.floorEquipments.length - 1]);
    }
    this.selectedItem = null;
    this.selectedChild = null;
    this.getUnSavedCount();
    this.detectFloorChanges();
  }
  filterTickets(equipment: any) {
    this.unlinkedTicket = [];
    if (this.selectedFloor && this.selectedFloor.serviceTicket && this.selectedFloor.serviceTicket.length > 0) {
      this.selectedFloor.serviceTicket.forEach((floorTicket: any) => {
        if (equipment && equipment.serviceTickets && equipment.serviceTickets.every((ticket: any) => ticket.id !== floorTicket.id && !floorTicket.isArchived)) {
          this.unlinkedTicket.push(floorTicket);
        }
      });
    }
    this.cdRef.detectChanges();
  }
  endSyncCall() {
    if (this.bsModalRef && !this.bsModalRef.content.stopEndApiCall) {
      this.bsModalRef.content.endSyncCallStarted = true;
      this.floorPlanService.endSynCall(this.isProject ? this.projectId : this.siteId, this.isProject, this.floorId, this.folderName, this.presignedURL, this.isStopApiCall).subscribe({
        next: (r: any) => {
          if (this.floorDetailGuard.canDeactivateCall) {
            this.eventService.broadcast(EventEmitterType.floorDetailRoutChangeCall, true);
          }
          this.btnService.enable();
          this.bsModalRef?.hide();
          if (r.code === "FAILED") {
            if (r.toasterErrorMessages != null && r.toasterErrorMessages.length > 0) {
              this.messageService.errorMessage("Transaction failed:", r.toasterErrorMessages[0]);
            } else {
              this.messageService.errorMessage("Transaction failed:", "Please try again later.");
            }
          } else {
            this.actionBtn.edit = this.actionBtn.save = false;
            this.messageService.successMessage("Plan Details", r.message);
            this.eventService.broadcast(EventEmitterType.floorPlanSaveOnGoing, { data: false, isCallPending: false, customerId: this.changedCustomerId, });
            if (this.isLoggedOutAction) {
              this.eventService.broadcast(EventEmitterType.logOutAction, true);
            } else {
              if (this.isToNavigate || this.customerChangeAttemp) {
                if (this.isToNavigate) {
                  this.navigateToListing();
                }
                this.isToNavigate = false;
                this.customerChangeAttemptFlag = false;
              }
            }
            if (this.zoomFactor === 1) {
              this.syncCompleted = true;
            }
            this.resetFloorPlan();
          }
        }, err: (j: any) => {
          this.btnService.enable();
          this.isStopApiCall = _.cloneDeep(false);
          this.syncStarted = false;
          this.bsModalRef?.hide();
          console.log("JSON Upload error", j)
        }
      })
    }
  }

  resetFloorPlan() {
    if (this.authService.getUserKeyData().isLocalSaveEnable) {
      this.floorIndexDBService.deleteAllRecordsFromIndexDB();
    }
    this.isStopApiCall = this.updateConnection = false;
    this.syncStarted = false;
    this.totalDeviceCount = this.totalTaskCount = this.imgUploadedCnt = this.totalDiscardCount = 0;
    this.updatedFloorEquipmentValues = [];
    this.uploadFavJson = [];
    this.favUpload = [];
    this.uploadNewFile = [];
    this.uploadNewFloorFile = [];
    this.presignedURL = [];
    this.isToNavigate = false;
    this.isFavoriteUpdated = false;
    this.isBuildingChanged = this.isPlanChanged = this.isCameraOpen = false
    this.baseNameCount = [];
    this.isImageLoadingLocal = false;
    this.floorPlanClicked = false;
    if (this.floorDetailGuard.canDeactivateCall) {
      this.eventService.broadcast(EventEmitterType.floorDetailRoutChangeCall, true);
    }
    if (this.changeBuildingOrPlan.change) {
      this.isBuildingChanged = this.isPlanChanged = true;
      this.syncCompleted = false;
      this.cdRef.detectChanges();
      this.initCall = true;
      this.getEquipmentListData();
      this.getNewPlanFromSameSite(this.changeBuildingOrPlan.e);
    } else {
      this.callAPIForPlan();
    }
  }

  checkHasSecondaryDevice() {
    return new Promise((resolve, reject) => {
      if (this.updatedFloorEquipmentValues.filter((x: any) => (x.parentId > 0 && x.id !== x.parentId)).length > 0) {
        const tmpUpdatedDevice: any = this.updatedFloorEquipmentValues.filter((x: any) => (x.parentId > 0 && x.id !== x.parentId));
        const removeChildDevices: any = [];
        tmpUpdatedDevice.forEach((element: any) => {
          const dummyParentId = element.parentId;
          if (dummyParentId !== null && dummyParentId !== undefined) {
            delete element.parentId;
            if (Object.keys(element).filter(e => e === 'isUpdate' || e === 'id').length < Object.keys(element).length) {

              if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === dummyParentId).length > 0) { //parent 
                removeChildDevices.push(element.id)
                if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === dummyParentId)[0].childFloorEquipments === undefined) {
                  this.updatedFloorEquipmentValues.filter((x: any) => x.id === dummyParentId)[0]['childFloorEquipments'] = [element]
                } else {
                  if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === dummyParentId)[0]['childFloorEquipments'].filter((y: any) => y.id === element.id).length === 0) {
                    this.updatedFloorEquipmentValues.filter((x: any) => x.id === dummyParentId)[0]['childFloorEquipments'].push(element)
                  }
                }
                delete element.parentId;
              } else {
                const index = this.updatedFloorEquipmentValues.map(function (img) { return img.id; }).indexOf(element.id)
                if (index > -1) { // only splice array when item is found
                  this.updatedFloorEquipmentValues.splice(index, 1); // 2nd parameter means remove one item only
                }
                this.updatedFloorEquipmentValues.push({ id: dummyParentId, childFloorEquipments: [element] })
              }
            } else {
              removeChildDevices.push(element.id)
            }
          } else {
            removeChildDevices.push(element.id)
            const child: any = [];
            child.push(element);
            this.updatedFloorEquipmentValues.push({ id: dummyParentId, childFloorEquipments: child })
          }
        });
        if (removeChildDevices) {
          removeChildDevices.forEach((element: any) => {
            const index = this.updatedFloorEquipmentValues.map(function (img) { return img.id; }).indexOf(element)
            if (index > -1) { // only splice array when item is found
              this.updatedFloorEquipmentValues.splice(index, 1); // 2nd parameter means remove one item only
            }
          });
        }
      }
      resolve(true);
    })
  }
  archiveDevice() {
    const deviceData = this.selectedChild ? this.selectedChild : this.selectedItem;
    this.tempEquipment = _.cloneDeep(deviceData);
    const archiveHubDeviceNetworkLinkCnt = deviceData.networkSwitchLinkEquipments ? deviceData.networkSwitchLinkEquipments.length : 0;
    if (deviceData.isHub && archiveHubDeviceNetworkLinkCnt > 0) {
      if (deviceData.childFloorEquipments && deviceData.childFloorEquipments.length > 0 && deviceData.childFloorEquipments.filter((e: any) => !e.isArchived).length > 0) {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
          '{{#archiveHubDeviceNetworkLinkCnt}}': archiveHubDeviceNetworkLinkCnt,
          '{{#deviceText}}': archiveHubDeviceNetworkLinkCnt > 1 ? 'devices' : 'device',
          '{{#archiveSecCnt}}': deviceData.childFloorEquipments.filter((e: any) => !e.isArchived).length
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(ArchiveHubWithChild));
        const initialState: ModalOptions = {
          initialState: modalData, class: ArchiveHubWithChild.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
              this.equipmentDetails = null;
            }
            if (deviceData.isHub) {
              this.removeDeviceAsHub(deviceData);
            }
            this.removeHubConnections(deviceData);
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: true, isUpdate: true });
            deviceData.childFloorEquipments.forEach((element: any) => {
              if (element.isHub && !element.equipment.isHub) {
                this.removeDeviceAsHub(element);
                this.removeHubConnections(element);
              }

              this.updatedKeyValueJson({ id: element.id, type: deviceData.type, parentId: element.parentId, isArchived: true, isUpdate: true });
            });
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0].isArchived = true;
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0].childFloorEquipments.forEach((element: any) => {
              element.isArchived = true;
              element = this.setDevices(element)
            });
            this.selectedItem = null;
            this.selectedChild = null;
            if (this.equipmentDetails) {
              this.equipmentDetails = null;
            }
            this.detectFloorChanges();
          }
        });
      } else {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
          '{{#archiveHubDeviceNetworkLinkCnt}}': archiveHubDeviceNetworkLinkCnt,
          '{{#deviceText}}': archiveHubDeviceNetworkLinkCnt > 1 ? 'devices' : 'device'
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(ArchiveHub));
        const initialState: ModalOptions = {
          initialState: modalData, class: ArchiveHub.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            if (deviceData.isHub) {
              this.removeDeviceAsHub(deviceData);
            }
            this.removeHubConnections(deviceData);
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: true, isUpdate: true });
            let device;
            if (this.selectedChild) {
              if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.parentId).length > 0
                && this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
                device = this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0];
              }
            } else {
              device = this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0];
            }
            device.isArchived = true;
            device = this.setDevices(device);
            this.selectedItem = null;
            this.selectedChild = null;
            if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
              this.equipmentDetails = null;
            }
            this.detectFloorChanges();
          }
        });
      }
    } else {
      if (deviceData.childFloorEquipments.filter((x: any) => !x.isArchived).length > 0) {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
          '{{#childCount}}': deviceData.childFloorEquipments.filter((x: any) => !x.isArchived).length,
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(ArchiveDeviceWithChild));
        const initialState: ModalOptions = {
          initialState: modalData, class: ArchiveDeviceWithChild.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
              this.equipmentDetails = null;
            }
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0].isArchived = true;
            this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
            if (deviceData.childFloorEquipments.length > 0) {
              this.selectedFloor.floorEquipments.filter((x: any) => x.id === deviceData.id)[0].childFloorEquipments.forEach((element: any) => {
                element.isArchived = true;
                element = this.setDevices(element);
                if (element.isHub) {
                  this.removeDeviceAsHub(element);
                  this.removeHubConnections(element);
                }
                this.addUpdatedValueJsonForDevice({ id: element.id, type: element.type, parentId: element.parentId, isArchived: true, isUpdate: true });
              });
            }
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: true, isUpdate: true });
            this.selectedItem = null;
            this.selectedChild = null;
            if (this.equipmentDetails) {
              this.equipmentDetails = null;
            }
            this.detectFloorChanges();
          }
        });
      } else {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(ArchiveDevice));
        const initialState: ModalOptions = {
          initialState: modalData, class: ArchiveDevice.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
              this.equipmentDetails = null;
            }
            if (this.selectedChild) {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0 && this.selectedFloor.floorEquipments.filter((e: any) => e.id === this.selectedChild.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id).length > 0) {
                const parentDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0];
                parentDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isArchived = true;
                parentDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isUpdate = true;
                parentDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0] = this.setDevices(parentDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0]);
                this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0] = this.setDevices(parentDevice);
                this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, parentId: deviceData.parentId, isArchived: true, isUpdate: true });
              }
            } else {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
                const updateDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0];
                updateDevice.isArchived = true;
                if (this.equipmentDetails && this.equipmentDetails !== null && updateDevice.id === this.equipmentDetails.id) {
                  this.equipmentDetails.isArchived = true;
                }
                updateDevice.isUpdate = true;
                this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0] = this.setDevices(updateDevice);
                this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: true, isUpdate: true });
              }
            }
            this.selectedItem = null;
            this.selectedChild = null;

            if (deviceData.isHub && this.netWorkSwitches && this.netWorkSwitches.length > 0) {
              const updatedSwitches = this.netWorkSwitches.filter((x: any) => x.id !== deviceData.id);
              if (updatedSwitches) {
                this.netWorkSwitches = [];
                this.netWorkSwitches = updatedSwitches;
                this.cdRef.detectChanges();
              }
            }
            this.detectFloorChanges();
          }
        });
      }
    }
  }
  removeDeviceConnections(connectedDevice: any) {
    connectedDevice.networkSwitchLinkId = null;
    connectedDevice.networkSwitchLinkFloorId = null;
    connectedDevice.networkSwitchLinkName = null;
    connectedDevice.networkSwitchLinkFloorName = null;
    connectedDevice.cableCoordinates = null;
    connectedDevice.cableLinkImageLeft = null;
    connectedDevice.cableLinkImageTop = null;
    if (connectedDevice.isConnectionLineOrCablePath) {
      connectedDevice.isConnectionLineOrCablePath = false;
    }
    return connectedDevice;
  }
  unarchiveDevice() {
    const deviceData = this.selectedChild ? this.selectedChild : this.selectedItem;
    this.tempEquipment = _.cloneDeep(deviceData);
    if (this.selectedChild && this.selectedItem && this.selectedItem.isArchived) {
      const dataReplacer = {
        '{{#parentName}}': _.escape(this.selectedItem.name),
        '{{#name}}': _.escape(deviceData.name)
      }
      const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(UnarchiveDevice));
      const initialState: ModalOptions = {
        initialState: modalData, class: UnarchiveDevice.dialogClass, ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res !== 'Cancel') {
          const parentElement = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId);
          const childElement = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id);
          if (parentElement.length > 0 && childElement.length > 0) {
            childElement[0].isArchived = false;
            parentElement[0].isArchived = false;
            if (childElement[0].equipment && childElement[0].equipment.isHub) {
              childElement[0].isHub = true;
              if (this.netWorkSwitches.filter((e: any) => e.id === childElement[0].id).length === 0) {
                this.netWorkSwitches.push({
                  floorId: this.floorId,
                  floorName: this.selectedFloor.name,
                  id: childElement[0].id,
                  name: childElement[0].name,
                  networkSwitchLinkTempId: childElement[0].tempId
                });
              }
              this.updatedKeyValueJson({ id: childElement[0].id, type: childElement[0].type, parentId: childElement[0].parentId, isHub: true, isArchived: false, isUpdate: true });
            } else {
              this.updatedKeyValueJson({ id: childElement[0].id, type: childElement[0].type, parentId: childElement[0].parentId, isArchived: false, isUpdate: true });
            }
            if (parentElement[0].equipment && parentElement[0].equipment.isHub) {
              parentElement[0].isHub = true;
              if (this.netWorkSwitches.filter((e: any) => e.id === parentElement[0].id).length === 0) {
                this.netWorkSwitches.push({
                  floorId: this.floorId,
                  floorName: this.selectedFloor.name,
                  id: parentElement[0].id,
                  name: parentElement[0].name,
                  networkSwitchLinkTempId: parentElement[0].tempId
                });
              }
              this.updatedKeyValueJson({ id: parentElement[0].id, type: parentElement[0].type, isArchived: false, isUpdate: true, isHub: true })
            } else {
              this.updatedKeyValueJson({ id: parentElement[0].id, type: parentElement[0].type, isArchived: false, isUpdate: true })
            }
            if (this.equipmentDetails && (this.equipmentDetails.id === deviceData.id || this.equipmentDetails.id === deviceData.parentId)) {
              this.equipmentDetails.isArchived = false;
              this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
            }
            parentElement[0] = this.setDevices(parentElement[0]);
            childElement[0] = this.setDevices(childElement[0]);
          }
          this.detectFloorChanges();
        }
        this.selectedChild = null;
        this.selectedItem = null;
      });
    } else {
      let device: any;
      if (this.selectedChild) {
        if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0) {
          device = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0];

          if (device.equipment && device.equipment.isHub) {
            device.isHub = true;
            if (this.netWorkSwitches.filter((e: any) => e.id === device.id).length === 0) {
              this.netWorkSwitches.push({
                floorId: this.floorId,
                floorName: this.selectedFloor.name,
                id: device.id,
                name: device.name,
                networkSwitchLinkTempId: device.tempId
              });
            }
            this.updatedKeyValueJson({ id: deviceData.id, parentId: deviceData.parentId, type: deviceData.type, isArchived: false, isUpdate: true, isHub: true })
          } else {
            this.updatedKeyValueJson({ id: deviceData.id, parentId: deviceData.parentId, type: deviceData.type, isArchived: false, isUpdate: true })
          }
        }
      } else {
        device = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id)[0]
        if (device.equipment && device.equipment.isHub) {
          device.isHub = true;
          if (this.netWorkSwitches.filter((e: any) => e.id === device.id).length === 0) {
            this.netWorkSwitches.push({
              floorId: this.floorId,
              floorName: this.selectedFloor.name,
              id: device.id,
              name: device.name,
              networkSwitchLinkTempId: device.tempId
            });
          }
          this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: false, isUpdate: true, isHub: true })
        } else {
          this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isArchived: false, isUpdate: true })
        }
      }
      device.isArchived = false;
      device = this.setDevices(device);
      if (this.selectedChild) {
        this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0]);
      }
      if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
        this.equipmentDetails.isArchived = false;
        this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
        this.getTranslate(this.equipmentDetails);
      }
      this.cdRef.detectChanges();
      this.detectFloorChanges();
    }
    this.selectedItem = null;
    this.selectedChild = null;
  }
  deleteDevice() {
    const deviceData = this.selectedChild ? this.selectedChild : this.selectedItem;
    if (deviceData.isNew) {
      if (deviceData.isHub && deviceData.networkSwitchLinkEquipments !== undefined && deviceData.networkSwitchLinkEquipments !== null && deviceData.networkSwitchLinkEquipments.length > 0) {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
          '{{#deleteNewDeviceNetworkLinkCnt}}': deviceData.networkSwitchLinkEquipments.length,
          '{{#deviceText}}': deviceData.networkSwitchLinkEquipments.length > 1 ? 'devices' : 'device',
          '{{#secondaryCount}}': deviceData.childFloorEquipments.filter((x: any) => !x.isArchived).length
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(DeleteHub));
        const initialState: ModalOptions = {
          initialState: modalData, class: DeleteHub.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            this.removeDeviceAsHub(deviceData);
            this.removeHubConnections(deviceData);
            if (this.selectedChild) {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
                && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)) {
                let primaryDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0];
                primaryDevice.childFloorEquipments.splice(primaryDevice.childFloorEquipments.findIndex((x: any) => x.id === deviceData.id), 1);
                primaryDevice = this.setDevices(primaryDevice);
                primaryDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(primaryDevice, primaryDevice.imageLeft, primaryDevice.imageTop);
              }
            } else {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
                this.selectedFloor.floorEquipments.splice(this.selectedFloor.floorEquipments.findIndex((e: any) => e.id === deviceData.id), 1)
              }
            }
            if (this.updatedFloorEquipmentValues.findIndex((x: any) => x.id === deviceData.id) > -1) {
              if (deviceData.childFloorEquipments.length > 0) {
                deviceData.childFloorEquipments.forEach((delChild: any) => {
                  this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex((x: any) => x.parentId === delChild.id), 1)
                });
              }
              this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex((x: any) => x.id === deviceData.id), 1)
            }
            this.detectFloorChanges();
            this.getUnSavedCount();
            this.recheckDeviceNameCount();
          }
          this.selectedItem = null;
          this.selectedChild = null;
        });
      } else {
        const dataReplacer = {
          '{{#name}}': _.escape(deviceData.name),
          '{{#secondaryCount}}': deviceData.childFloorEquipments.filter((x: any) => !x.isArchived).length
        }
        const deviceDelete = _.cloneDeep(DeleteDevice);
        if ((deviceData.childFloorEquipments.filter((x: any) => !x.isArchived).length > 0)) {
          deviceDelete.content = DeleteDevice.content1
        }
        const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(deviceDelete));
        const initialState: ModalOptions = {
          initialState: modalData, class: DeleteDevice.dialogClass, ignoreBackdropClick: true
        };
        this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
        this.bsModalRef.content.event.subscribe((res: any) => {
          if (res !== 'Cancel') {
            if (this.selectedChild) {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
                && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)) {
                let primaryDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0];
                primaryDevice.childFloorEquipments.splice(primaryDevice.childFloorEquipments.findIndex((x: any) => x.id === deviceData.id), 1);
                primaryDevice = this.setDevices(primaryDevice);
                primaryDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(primaryDevice, primaryDevice.imageLeft, primaryDevice.imageTop);
              }
            } else {
              if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id).length > 0) {
                this.selectedFloor.floorEquipments.splice(this.selectedFloor.floorEquipments.findIndex((e: any) => e.id === deviceData.id), 1)
              }
            }
            if (this.updatedFloorEquipmentValues.findIndex((x: any) => x.id === deviceData.id) > -1) {
              if (deviceData.childFloorEquipments.length > 0) {
                deviceData.childFloorEquipments.forEach((delChild: any) => {
                  this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex((x: any) => x.parentId === deviceData.id), 1)
                });
              }
              this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex((x: any) => x.id === deviceData.id), 1)
            }

            this.getUnSavedCount();
            this.recheckDeviceNameCount();
            this.detectFloorChanges();
            if (this.equipmentDetails && (this.equipmentDetails.id === deviceData.id || this.equipmentDetails.parentId === deviceData.id)) {
              this.closeEquipmentDetails();
            }
          }
          this.selectedItem = null;
          this.selectedChild = null;

        });
      }
    } else {
      const dataReplacer = {
        '{{#name}}': _.escape(deviceData.name),
      }
      const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(DeleteDevice));
      const initialState: ModalOptions = {
        initialState: modalData, class: DeleteDevice.dialogClass, ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res !== 'Cancel') {
          if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
            this.equipmentDetails = null;
          }
          if (this.selectedChild) {
            if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId).length > 0
              && this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0].childFloorEquipments.filter((x: any) => x.id === deviceData.id)) {
              let primaryDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.parentId)[0];
              primaryDevice.childFloorEquipments.filter((x: any) => x.id === deviceData.id)[0].isDeleted = true;
              primaryDevice = this.setDevices(primaryDevice);
              primaryDevice.secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(primaryDevice, primaryDevice.imageLeft, primaryDevice.imageTop);
            }
            this.addUpdatedValueJsonForDevice({ id: deviceData.id, type: deviceData.type, parentId: deviceData.parentId, isDeleted: deviceData.isDeleted })
          } else {
            const deleteDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === deviceData.id);
            if (deleteDevice && deleteDevice.length > 0) {
              deleteDevice[0].isDeleted = true;
              if (deleteDevice[0].childFloorEquipments && deleteDevice[0].childFloorEquipments.length > 0) {
                deleteDevice[0].childFloorEquipments.forEach((cDel: any) => {
                  cDel.isDeleted = true;
                  if (this.equipmentDetails && this.equipmentDetails.id === cDel.id) {
                    this.equipmentDetails = null
                  }
                  this.addUpdatedValueJsonForDevice({ id: cDel.id, parentId: deviceData.id, type: deviceData.type, isDeleted: true })
                });
              }
            }
            this.updatedKeyValueJson({ id: deviceData.id, type: deviceData.type, isDeleted: true })
          }
          this.getUnSavedCount();
          this.detectFloorChanges();
        }
        this.selectedItem = null;
        this.selectedChild = null;
      });
    }
    // if (this.equipmentDetails && this.equipmentDetails.id === deviceData.id) {
    //   this.equipmentDetails = null
    // }
  }
  saveImageEquipmentDetails(value: any) {
    this.isCameraOpen = false;
    if (value.id) {
      const imgDevice: any = _.cloneDeep(FloorUtils.getEquipment(this.selectedFloor, value.id));
      if (imgDevice) {
        if (value.deletedImages) {
          const tmpImgDevice: any = _.cloneDeep(FloorUtils.getEquipment(this.defaultFloorDetails, value.id));
          if (tmpImgDevice) {
            this.imageDeleted(value, tmpImgDevice.images);
          } else {
            value.deletedImages.forEach((del: any) => {
              const timer = setTimeout(() => {
                if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] && this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].length > 0 && this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].filter((y: any) => y.imgId === del.id)) {
                  this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].splice(this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].filter((y: any) => y.imgId === del.id), 1);
                }
                clearTimeout(timer);
              }, 100)
            });
          }

        }
        if (value.newImages) {
          this.newImagesToUpload(value, imgDevice.images);
        }
        if (value.updatedImages) {
          this.updatedImagesToUpload(value, imgDevice.images);
        }
        if (value.deletedImages === undefined && value.newImages === undefined && value.updatedImages === undefined) {
          const delNewImg: any = imgDevice.filter((k: any) => k.isNewFile);
          delNewImg.forEach((img: any) => {
            const updateJsonImg: any = this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'];
            const index: any = imgDevice.findIndex((k: any) => k.imgId === img.imgId);
            if (index > -1) {
              imgDevice.splice(index, 1);
              this.equipmentDetails.images = _.cloneDeep(imgDevice);
            }

            const indexUpdate: any = updateJsonImg.findIndex((k: any) => k.imgId === img.imgId);
            if (indexUpdate > -1) {
              updateJsonImg.splice(indexUpdate, 1);
              if (updateJsonImg.length > 0) {
                this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
              } else {
                this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = [];
              }
            }
          });
        }
        if (value.parentId === undefined || value.parentId === "" || value.parentId === null) {
          imgDevice.images = _.cloneDeep(_.uniqBy(imgDevice.images, function (e: any) {
            return e.tempId;
          }));
          const imgSetDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === value.id);
          if (imgSetDevice) {
            imgSetDevice[0].images = _.cloneDeep(this.equipmentDetails.images);
            imgSetDevice[0] = this.setDevices(imgSetDevice[0])
            imgSetDevice[0].secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(imgSetDevice[0], imgSetDevice[0].imageLeft, imgSetDevice[0].imageTop);
            if (imgDevice.images && imgDevice.images.length > 1) {
              this.equipmentDetails.images = [];
              this.equipmentDetails.images = _.cloneDeep(imgDevice.images);
            }
          }
        } else {
          imgDevice.images = _.cloneDeep(_.uniqBy(imgDevice.images, function (e: any) {
            return e.tempId;
          }));
          const imgSetDevice: any = this.selectedFloor.floorEquipments.filter((e: any) => e.id === value.parentId)[0].childFloorEquipments.filter((x: any) => x.id === value.id);
          if (imgSetDevice) {
            imgSetDevice[0].images = _.cloneDeep(this.equipmentDetails.images);
            imgSetDevice[0] = this.setDevices(imgSetDevice[0])
            imgSetDevice[0].secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(imgSetDevice[0], imgSetDevice[0].imageLeft, imgSetDevice[0].imageTop);
            if (imgDevice.images && imgDevice.images.length > 1) {
              this.equipmentDetails.images = [];
              this.equipmentDetails.images = _.cloneDeep(imgDevice.images);
            }
          }
        }
        this.equipmentDetails = this.setDevices(this.equipmentDetails);
        this.getUnSavedCount();
        this.detectFloorChanges();
      }
    }
  }
  getFileExtension(file: any) {
    const regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    const extension = file.match(regexp);
    return extension && extension[1];
  }
  newImagesToUpload(value: any, imgDevice: any) {
    value.newImages.forEach((element: any) => {
      element.isNewFile = true;
      if (element.fileLocation === undefined || element.fileLocation === null) {
        element.fileLocation = Utilities.getCurrentDateTime() + element.fileData.extension;
      }
      if ((imgDevice && imgDevice.filter((k: any) => k.imgId !== element.imgId).length > 0) || imgDevice.length === 0) {
        imgDevice.push(element)
        if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id).length > 0) {
          if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images']) {
            const updateJsonImg: any = this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'];
            if (updateJsonImg && updateJsonImg.filter((elem: any) => elem.imgId === element.imgId).length === 0) {
              const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
              this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
              updateJsonImg.push(element);
              this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
            } else if (updateJsonImg && updateJsonImg.filter((elem: any) => elem.imgId === element.imgId).length > 0) {
              const ind = updateJsonImg.findIndex((elem: any) => elem.imgId === element.imgId);
              if (ind > -1) {
                updateJsonImg.splice(ind, 1);
                this.floorIndexDBService.deleteSpecificDeviceImageIndexdDb(element.imgId);
                const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
                this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
                updateJsonImg.push(element);
                this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
              }
            }
          } else {
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = [];
            const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
            this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].push(element)
          }
        } else {
          const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
          this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
          if (value.parentId && value.parentId !== null && value.parentId !== undefined) {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, parentId: value.parentId, isUpdate: true, images: [element] })
          } else {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, images: [element], isUpdate: true })
          }
        }
      } else if (imgDevice.filter((k: any) => k.imgId === element.imgId).length > 0) {
        if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].filter((y: any) => y.imgId === element.imgId).length > 0) {
          const tmpImg: any = _.cloneDeep(this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images']);
          this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = [];
          tmpImg.forEach((img: any) => {
            if (img.imgId !== element.imgId) {
              const blob = this.base64ToBlob(img.awsImageURL, 'image/jpeg');
              this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, img.imgId, blob);
              this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].push(img)
            } else {
              const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
              this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
              this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].push(element)
            }
          });
        }
      }
    });
  }
  updatedImagesToUpload(value: any, imgDevice: any) {
    value.updatedImages.forEach((element: any) => {
      if (element.isUpdate && imgDevice.filter((k: any) => k.id === element.id).length > 0) {
        element.isNewFile = true;
        imgDevice.filter((k: any) => k.id === element.id)[0] = element;
        if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id).length > 0) {
          if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images']) {
            const updateJsonImg: any = this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'];
            if (updateJsonImg && updateJsonImg.filter((elem: any) => elem.id === element.id).length === 0) {
              const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
              this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.id, blob);
              updateJsonImg.push(element);
              this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
            }
            else if (updateJsonImg && updateJsonImg.filter((elem: any) => elem.id === element.id).length > 0) {
              const ind = updateJsonImg.findIndex((elem: any) => elem.imgId === element.imgId);
              if (ind > -1) {
                if (element.imgId !== undefined) {
                  this.floorIndexDBService.deleteSpecificDeviceImageIndexdDb(element.imgId);
                  const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
                  this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId, blob);
                }
                updateJsonImg.push(element)
                updateJsonImg.splice(ind, 1);
                this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
              }
            }
          } else {
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = [];
            const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
            this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.imgId ? element.imgId : element.id, blob);
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].push(element)
          }
        } else {
          const blob = this.base64ToBlob(element.awsImageURL, 'image/jpeg');
          this.floorIndexDBService.syncDeviceImageIndexdDb(this.floorId, value.id, element.id, blob);
          if (value.parentId && value.parentId !== null && value.parentId !== undefined) {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, parentId: value.parentId, isUpdate: true, images: [element] })
          } else {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, images: [element], isUpdate: true })
          }
        }
      }
    });
  }
  imageDeleted(value: any, imgDevice: any) {
    value.deletedImages.forEach((element: any) => {
      if (imgDevice.filter((i: any) => i.id === element.id || (i.imgId !== undefined && i.imgId === element.id)).length > 0) {
        imgDevice.filter((i: any) => i.id === element.id || (i.imgId !== undefined && i.imgId === element.id))[0].isDeleted = true;
        if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id).length > 0) {
          let updateJsonImg: any = this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'];
          if (element.id > 0 && updateJsonImg && updateJsonImg.filter((elem: any) => elem.id === element.id).length === 0) {
            updateJsonImg.push(element);
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
          } else {
            const index: any = updateJsonImg.findIndex((r: any) => r.imgId === element.id);
            if (index > -1) {
              this.floorIndexDBService.deleteSpecificDeviceImageIndexdDb(element.imgId);
              updateJsonImg.splice(index, 1);
              if (updateJsonImg.length === 0) {
                updateJsonImg = [];
              }
              this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] = _.cloneDeep(updateJsonImg)
            }
          }
        } else {
          if (value.parentId && value.parentId !== null && value.parentId !== undefined) {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, parentId: value.parentId, isUpdate: true, images: [{ id: element.id, isDeleted: true }] })
          } else {
            this.updatedFloorEquipmentValues.push({ id: value.id, type: value.type, isUpdate: true, images: [{ id: element.id, isDeleted: true }] })
          }
        }
      } else if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)) {
        const timeOut = setTimeout(() => {
          if (this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'] && this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].length > 0 && this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].filter((y: any) => y.imgId === element.id)) {
            this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].splice(this.updatedFloorEquipmentValues.filter((x: any) => x.id === value.id)[0]['images'].filter((y: any) => y.imgId === element.id), 1);
          }
          clearTimeout(timeOut)
        }, 100);
      }
    });
  }

  imageDragged() {
    this.fitToScreen = false;
    this.imageDraggedComplete = true;
    const timer = setTimeout(() => {

      this.previousImageTop = this.floorImageContainer.style.top
      this.previousImageLeft = this.floorImageContainer.style.left;

      clearTimeout(timer)
    }, 100);
    this.cdRef.detectChanges();
  }
  detectFloorChanges() {
    //     this.cdRef.markForCheck();
    // return;
    const floorEquipments = _.cloneDeep(this.selectedFloor);
    this.selectedFloor = _.cloneDeep(floorEquipments);
    this.netWorkSwitches = _.cloneDeep(this.netWorkSwitches);
    this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
    this.cdRef.detectChanges();
  }

  updateCableCoordinates(device: any, isHub: boolean) {
    const cable: any = _.cloneDeep(device.cableCoordinates);
    if (!isHub) {
      cable[0].x = device.imageLeft
      cable[0].y = device.imageTop
    } else {
      cable[cable.length - 1].x = device.imageLeft
      cable[cable.length - 1].y = device.imageTop
    }
    if (this.equipmentDetails && this.equipmentDetails.cableCoordinates !== null && this.equipmentDetails.id === device.id) {
      this.equipmentDetails.cableCoordinates = _.cloneDeep(cable);
      this.equipmentDetails = _.cloneDeep(this.equipmentDetails);
    }
    return cable;
  }
  updateHubConnectedDevices(device: any) {
    device.networkSwitchLinkEquipments.forEach((k: any) => {
      let equip: any = FloorUtils.getEquipment(this.selectedFloor, k.id);

      if (equip) {
        if (equip.cableCoordinates !== null) {
          const cable: any = _.cloneDeep(equip.cableCoordinates);
          cable[cable.length - 1].x = device.imageLeft
          cable[cable.length - 1].y = device.imageTop
          equip.cableCoordinates = _.cloneDeep(cable)
          if (equip.parentId && equip.parentId > 0) {
            this.updateFloorObject({ id: equip.id, parentId: equip.parentId, isUpdate: true, type: "device", cableCoordinates: equip.cableCoordinates })
            this.updatedKeyValueJson({ id: equip.id, parentId: equip.parentId, isUpdate: true, type: "device", cableCoordinates: equip.cableCoordinates });
          } else {
            this.updateFloorObject({ id: equip.id, isUpdate: true, type: "device", cableCoordinates: equip.cableCoordinates })
            this.updatedKeyValueJson({ id: equip.id, isUpdate: true, type: "device", cableCoordinates: equip.cableCoordinates });
          }
          if (this.equipmentDetails && this.equipmentDetails.id === equip.id) {
            this.equipmentDetails.cableCoordinates = _.cloneDeep(equip.cableCoordinates);
          }
        } else {
          equip.cableLinkImageLeft = device.imageLeft
          equip.cableLinkImageTop = device.imageTop
          this.updateFloorObject({ id: equip.id, isUpdate: true, connectionLength: this.getConnectionLength(device, equip), type: "device", cableLinkImageLeft: equip.cableLinkImageLeft, cableLinkImageTop: equip.cableLinkImageTop })
        }
        equip = this.setCableOrConnectionLine(equip);
      }
    });
  }
  closeDeviceDroppedContext() {
    this.showFirstDragPopup = false;
  }
  ngOnDestroy(): void {
    this.planImage = undefined;
    this.customerChangeAttemp.unsubscribe();
    this.floorEquipmentSelectionFromHeader.unsubscribe();
    this.logOutSubEvent.unsubscribe();
    this.isCameraOpenSub.unsubscribe();
    this.userSelectedNextDevice.unsubscribe();
    this.delAllFavorites.unsubscribe();
    this.routerSubscription.unsubscribe();
    localStorage.removeItem('stopDrag');
    this.eventService.broadcast(EventEmitterType.searchReset, {})
    this.cdRef.detectChanges();
    this.destroy$.next();
    this.destroy$.complete();
  }
  showCountFilterPopupOpen(tab: any) {
    this.countFilterClicked = true;
    this.showCountFilterPopup = true;
    this.selectedFilterCountTab = tab;
  }
  ngAfterViewChecked(): void {

    this.cdRef.detectChanges()
  }
  filterCategoryChange(event: any) {
    this.filterCategoryData = _.cloneDeep(event);
    this.concatFilterChangedRequests = concat(
      this.updateDeviceNameChoice(),
      this.changeArchiveDeviceVisiblity(),
      this.updateAbbreviatedNameChoice(),
      this.updatePartNumberChoice(),
      this.updateConnectionLineChoice(),
      this.updateFieldOfView(),
    ).subscribe((data) => {
      this.cdRef.detectChanges();
    });
    if (this.projectId && this.projectId > 0) {
      this.updatePlanMarkup();
    }
    if (this.equipmentDetails && this.equipmentDetails !== undefined && this.filterCategoryData.filter((e: any) => e.isChecked && e.category.name === this.equipmentDetails.categoryName).length === 0 && this.equipmentList.length > 0) {
      this.closeEquipmentDetails();
    }
    if (this.isProject && this.equipmentDetails && this.equipmentDetails !== undefined && this.filterCategoryData.filter((e: any) => e.isChecked && e.category.name === this.equipmentDetails.installStatus).length === 0) {
      this.closeEquipmentDetails();
    }
    if (this.equipmentDetails && this.equipmentDetails !== undefined && this.filterCategoryData.filter((e: any) => e.isChecked && e.category.name === this.equipmentDetails.status).length === 0) {
      this.closeEquipmentDetails();
    }
    this.selectedFloor.floorEquipments = this.setDefaultValuesForSelectedFloor(this.selectedFloor.floorEquipments);
    this.detectFloorChanges();
  }
  downloadReports() {
    const initialState: ModalOptions = {
      initialState: {},
      class: 'w-1024',
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(PrintOutPopupComponent, initialState);
    this.bsModalRef.content.event.subscribe((r: any) => {
      if (r !== 'cancel') {
        const data: any = {
          initialState: { isFromSite: this.isProject ? false : true, floorId: this.floorId, pinColor: this.pinColorType, pinSizeValue: this.pinSize, assignedToFilter: this.assignedToFilter, filterCategory: [] },
          class: 'modal-lg', ignoreBackdropClick: true
        }
        if (r === PrintOutType.NUMBERED) {
          data.initialState.filterCategory = _.cloneDeep(FloorUtils.setFilterCategory(PrintOutType.NUMBERED, this.isProject, _.cloneDeep(this.equipmentList)));
          data.initialState.filterCategory.filter((x: any) => x.category.id === 3)[0]['isChecked'] = this.showCoverage;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 4)[0]['isChecked'] = this.showConnectionLine;
          data.initialState.installByUserItems = this.installedByUsersItems;
          data.initialState.user = this.authService.getUserKeyData();
          if (this.filterCategoryData !== undefined) {
            this.filterCategoryData.forEach((element: any) => {
              if (data.initialState.filterCategory.filter((k: any) => k.type !== 'vp' && k.category.name === element.category.name).length > 0) {
                data.initialState.filterCategory.filter((k: any) => k.category.name === element.category.name)[0]['isChecked'] = _.cloneDeep(element.isChecked)
              }
            });
          }
          data.initialState['isFromSite'] = _.cloneDeep(this.isProject ? false : true);
          this.numberedPrintOut(data);
        } else if (r === PrintOutType.ICON) {
          data.initialState.filterCategory = _.cloneDeep(FloorUtils.setFilterCategory(PrintOutType.ICON, this.isProject, _.cloneDeep(this.equipmentList)));
          data.initialState.filterCategory.filter((x: any) => x.category.id === 0)[0]['isChecked'] = this.showDeviceName;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 1)[0]['isChecked'] = this.showAbbreviatedName;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 2)[0]['isChecked'] = this.showPartNumber;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 3)[0]['isChecked'] = this.showCoverage;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 4)[0]['isChecked'] = this.showConnectionLine;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 7)[0]['isChecked'] = !this.showArchived;
          data.initialState.installByUserItems = this.installedByUsersItems;
          data.initialState.user = this.authService.getUserKeyData();
          if (this.filterCategoryData !== undefined) {
            this.filterCategoryData.forEach((element: any) => {
              if (data.initialState.filterCategory.filter((k: any) => k.category.name === element.category.name).length > 0) {
                data.initialState.filterCategory.filter((k: any) => k.category.name === element.category.name)[0]['isChecked'] = _.cloneDeep(element.isChecked)
              }
            });
          }
          data.initialState['isFromSite'] = _.cloneDeep(this.isProject ? false : true);
          this.iconPrintOut(data);
        } else if (r === PrintOutType.MULTIPLAN) {
          data.initialState['isFromFloorPlan'] = true;
          data.initialState['isFromSite'] = _.cloneDeep(this.isProject ? false : true);
          data.initialState['id'] = _.cloneDeep(this.isProject ? this.projectId : this.siteId);
          data.initialState['buildings'] = _.cloneDeep(this.initalBuildingFloorDropdownData);
          data.initialState['isUnlimitedPlan'] = _.cloneDeep(this.authService.getUserKeyData().isUnlimitedPlansInReports);
          data.initialState['totalPlans'] = _.cloneDeep(this.buildingDetails.floors.length);
          data.initialState.filterCategory = FloorUtils.setFilterCategory(PrintOutType.MULTIPLAN, this.isProject, _.cloneDeep(this.equipmentList));
          data.initialState.filterCategory.filter((x: any) => x.category.id === 3)[0]['isChecked'] = this.showCoverage;
          data.initialState.filterCategory.filter((x: any) => x.category.id === 4)[0]['isChecked'] = this.showConnectionLine;
          if (this.filterCategoryData !== undefined) {
            this.filterCategoryData.forEach((element: any) => {
              if (data.initialState.filterCategory.filter((k: any) => k.type !== 'vp' && k.category.name === element.category.name).length > 0) {
                data.initialState.filterCategory.filter((k: any) => k.category.name === element.category.name)[0]['isChecked'] = _.cloneDeep(element.isChecked)
              }
            });
          }
          data.class = 'w-1200';
          this.multiPrintOut(data);
        }
      }
    })

  }

  updateDeviceNameChoice() {
    const temp = this.filterCategoryData.find((item: any) => item.category.id === 0);
    if (!Utilities.isNull(temp) && this.showDeviceName !== temp.isChecked && temp.isChecked !== undefined) {
      this.showDeviceName = temp.isChecked;
      return this.floorPlanService.saveShowDeviceNameChoice(this.showDeviceName ? 1 : 0);
    }
    return empty();
  }
  changeArchiveDeviceVisiblity() {
    const temp = this.filterCategoryData.find((item: any) => item.category.id === 5);
    if (!this.buildingDetails.isSiteArchived) {
      if (!Utilities.isNull(temp) && this.showArchivedDevice !== temp.isChecked && temp.isChecked !== undefined) {
        this.showArchived = temp.isChecked;
        this.showArchivedDevice = temp.isChecked;
        return this.floorPlanService.saveArchiveDeviceVisiblity(this.showArchived ? 1 : 0);
      }
    } else {
      this.showArchived = false;
      this.showArchivedDevice = false;
    }
    return empty();
  }
  updatePartNumberChoice() {
    const temp = this.filterCategoryData.find((item: any) => item.category.id === 2);
    if (!Utilities.isNull(temp) && this.showPartNumber !== temp.isChecked && temp.isChecked !== undefined) {
      this.showPartNumber = temp.isChecked;
      return this.floorPlanService.saveShowPartNumberChoice(this.showPartNumber ? 1 : 0);
    }
    return empty();
  }
  updateAbbreviatedNameChoice() {
    const temp = this.filterCategoryData.find((item: any) => item.category.id === 1);
    if (!Utilities.isNull(temp) && this.showAbbreviatedName !== temp.isChecked) {
      if (temp.isChecked !== undefined) {
        this.showAbbreviatedName = temp.isChecked;
        return this.floorPlanService.saveAbbreviatedNameChoice(this.showAbbreviatedName ? 1 : 0);
      }
    }
    return empty();
  }
  updateConnectionLineChoice() {
    const temp = this.filterCategoryData.find((item: any) => item.category.id === 4);

    if (!Utilities.isNull(temp) && this.showConnectionLine !== temp.isChecked) {
      if (temp.isChecked !== undefined) {
        this.showConnectionLine = temp.isChecked;
        return this.floorPlanService.saveConnectionLineChoice(this.showConnectionLine ? 1 : 0);
      }
    }
    return empty();
  }
  private updatePlanMarkup() {
    const FieldOfMarkUp = this.filterCategoryData.find((item: any) => item.category.id === 7 && item.type === 'vp');
    if (!Utilities.isNull(FieldOfMarkUp) && this.showPlanMarkup !== FieldOfMarkUp.isChecked) {
      if (FieldOfMarkUp.isChecked !== undefined) {
        this.showPlanMarkup = FieldOfMarkUp.isChecked;
        this.floorPlanService.saveShowPlanMarkup(this.showPlanMarkup ? 1 : 0).subscribe({
          next: () => {
            this.checkFloorImage(FieldOfMarkUp);
          }
        });
      }
    }
  }
  checkFloorImage(FieldOfMarkUp?: any) {
    if (FieldOfMarkUp.isChecked) {
      this.planImage = this.selectedFloor.awsImageURL;
    } else {
      this.planImage = this.selectedFloor.baseImageURL;
    }
  }
  updateFieldOfView() {
    const FieldOfVIewData = this.filterCategoryData.find((item: any) => item.category.id === 3);
    if (!Utilities.isNull(FieldOfVIewData) && this.showCoverage !== FieldOfVIewData.isChecked && FieldOfVIewData.isChecked !== undefined) {
      this.showCoverage = FieldOfVIewData.isChecked;
      return this.floorPlanService.saveFieldOfView(this.showCoverage ? 1 : 0);
      // this.setZoomInOutrange();
    }
    return empty();
  }
  pinColorChanged(event: any) {
    this.pinColorType = event;
    this.selectedFloor.floorEquipments = _.cloneDeep(this.setDefaultValuesForSelectedFloor(this.selectedFloor.floorEquipments));
    if (this.equipmentDetails && this.equipmentDetails.coverageArea && !this.actionBtn.edit && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice && (this.permissions.canEdit || this.permissions.canManage || this.permissions.canUpdateDevice)) {
      this.equipmentDetails = this.setCoverageElements(this.equipmentDetails, this.largerDimension);
      const timeout = setTimeout(() => {
        this.showIconsForCoverage();
        clearTimeout(timeout);
      }, 100);
    }
  }
  addMarkup() {
    const floorPlan = this.buildingDetails.floors.find(
      (x: any) => x.id === this.floorId
    );
    const initialState: ModalOptions = {
      initialState: {
        image: this.planImage,
        file: floorPlan,
        caption: floorPlan.fileName,
        projectId: this.projectId,
        isFromFloorPlan: true,
        showImageEditorForBuilding: true,
        siteId: this.siteId,
        canResizePlanMarkup: this.selectedFloor.canResizePlanMarkup
      }, ignoreBackdropClick: true, class: 'modal-page-cover modal-markup'
    };
    this.bsModalRef = this.modalService.show(PixieComponent, initialState);
    const pixieSub = this.bsModalRef.content.saveImage.subscribe((res: any) => {
      if (res) {
        this.planImage = res.image.awsImageURL;
        this.selectedFloor.awsImageURL = this.planImage;
        this.uploadNewFloorFile = [];
        this.uploadNewFloorFile.push(res.image);
        pixieSub.unsubscribe();
        this.getUnSavedCount();
        this.checkMarkupFilter(true);
      }
    })
    this.bsModalRef.onHidden?.subscribe(() => {
      pixieSub.unsubscribe();
    })
  }

  checkMarkupFilter(data: any) {
    if (data === false) {
      this.showPlanMarkupFilter = false;
    }
    else {
      if (this.filterCategoryData.find((x: any) => x.category.id === 7)) {
        this.filterCategoryData.find((x: any) => x.category.id === 7).isChecked = data;
        this.showPlanMarkupFilter = true;
      }
    }
    this.cdRef.detectChanges();
  }

  pinSizeValueChanged(event: any) {
    this.pinSize = DevicePinSizeValue[event]
    this.floorPlanService.setPinSize(this.floorId, event).subscribe();
    const timeOut = setTimeout(() => {
      if (this.equipmentDetails && this.equipmentDetails !== null && this.equipmentDetails.coverageArea) {
        this.getTranslate(this.equipmentDetails);
        this.cdRef.detectChanges();
      }
      clearTimeout(timeOut)
    }, 0);
  }

  checkScaleForFloorPlainInFeet(isZoom?: any) {
    if (parseInt(this.zoomValue) > 0 && this.selectedFloor !== undefined && this.selectedFloor.floorScale !== undefined && this.selectedFloor.floorScale !== null && this.selectedFloor.floorScale >= 0) {
      const rulerWidth: any = 88;
      if (isZoom === undefined) {
        this.feetValue = this.defaultFeetValue = ((this.selectedFloor.floorScale * rulerWidth) / (parseInt(this.zoomValue))).toFixed(1);
      } else {
        this.feetValue = (parseFloat(this.defaultFeetValue) / this.zoomFactor).toFixed(1);
      }
    }
  }

  numberedPrintOut(data: any) {
    this.bsModalPrintOutRef = this.modalService.show(NumberedPlanReportComponent, data);
    this.bsModalPrintOutRef.content.event.subscribe((n: any) => {
      const initialState: ModalOptions = {
        initialState: { downloadData: n, headerName: 'Numbered Plan Report (Column Configuration)', columnConfig: this.columnConfig, isProject: this.isProject, id: this.isProject ? this.projectId : this.siteId },
        class: 'modal-dialog w-1200', ignoreBackdropClick: true
      };
      this.bsModalConfigRef = this.modalService.show(ReportConfigColumnComponent, initialState);
      this.bsModalPrintOutRef.setClass('w-1024 d-none');
      this.bsModalConfigRef.content.closeAllModalEvent.subscribe((config: any) => {
        this.bsModalConfigRef?.hide();
        this.bsModalPrintOutRef?.hide();
      })
      this.bsModalConfigRef.content.backEvent.subscribe((config: any) => {
        this.bsModalPrintOutRef.setClass('modal-lg');
        this.columnConfig = config
        this.bsModalConfigRef?.hide();
      })
      this.bsModalConfigRef.content.downloadEvent.subscribe((config: any) => {
        this.bsModalConfigRef?.hide();
        this.bsModalPrintOutRef?.hide();
        this.downloadNumberOrIconPrintReport(config, true);
      })
    })
  }


  multiPrintOut(data: any) {
    this.bsModalPrintOutRef = this.modalService.show(MultiplanReportComponent, data);
    this.bsModalPrintOutRef.content.event.subscribe((n: any) => {
      const initialState: ModalOptions = {
        initialState: {
          downloadData: n,
          reportName: 'Multi-Plan',
          headerName: 'Multi-Plan Report (Column Configuration)',
          columnConfig: this.columnConfig, isProject: this.isProject, id: this.isProject ? this.projectId : this.siteId
        },
        class: 'modal-dialog w-1200', ignoreBackdropClick: true
      };
      this.bsModalConfigRef = this.modalService.show(ReportConfigColumnComponent, initialState);
      this.bsModalPrintOutRef.setClass('w-1024 d-none');
      this.bsModalConfigRef.content.closeAllModalEvent.subscribe(() => {
        this.bsModalConfigRef?.hide();
        this.bsModalPrintOutRef?.hide();
      })
      this.bsModalConfigRef.content.backEvent.subscribe((config: any) => {
        this.bsModalPrintOutRef.setClass('w-1024');
        this.bsModalConfigRef?.hide();
        this.columnConfig = config
      })
      this.bsModalConfigRef.content.downloadEvent.subscribe((config: any) => {
        this.bsModalConfigRef?.hide();
        this.bsModalPrintOutRef?.hide();
        this.downloadMultiPlanReport(config);
      })
    })
  }
  iconPrintOut(data: any) {
    this.bsModalPrintOutRef = this.modalService.show(IconPlanReportComponent, data);
    this.bsModalPrintOutRef.content.event.subscribe((icReport: any) => {
      this.bsModalPrintOutRef?.hide();
      this.downloadNumberOrIconPrintReport(icReport, false);
    })
  }
  base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  generateDailyReportFileName(isBeta: any) {
    const date = new Date();
    const user = this.authService.getUserKeyData();
    let FileName =
      "SiteOwl-" + (isBeta ? 'Numbered_Plan_Report' : 'Icon_Plan_Report') + '-' + (user.customerName || '') + '-' + (this.projectId ? this.buildingDetails.projectName : this.buildingDetails.siteName) + '-' +
      (this.selectedFloor.name || "") + "-" + moment(date).format("YYYYMMDD") + "-" + moment(date).format("HHmmss") + "." + "pdf";
    if (FileName.match(/^[a-zA-Z1-9.?!:;\- ]+$/g) !== null) {
      const newFileName = FileName.replace(/[^a-zA-Z0-9-_ \.]/g, "");
      FileName = newFileName.replace(/ /g, "_");
    }
    return FileName;
  }

  downloadNumberOrIconPrintReport(params: any, isNumbered: any) {
    if (this.isProject) {
      params.downloadData['projectId'] = this.projectId
    } else {
      params.downloadData['siteId'] = this.siteId
    }
    if (!this.hideLiveDevice && !isNumbered && this.isProject) {
      params.downloadData.viewingPreference.push('showLiveDevices')
    }
    this.floorPlanService.generatePdf(params.downloadData, isNumbered, this.isProject).pipe().subscribe(
      (result: any) => {
        if (result && result["body"]) {
          this.isLoadingDailyReport = false;
          const pdfGenerationString = result["body"];
          const blob = new Blob(
            [this.base64ToArrayBuffer(pdfGenerationString)],
            { type: "application/pdf" }
          );
          const a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = this.generateDailyReportFileName(isNumbered);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          const eventObject: any = {
            'Report Name': 'Single Plan Printout',
            'Scope': (this.isProject ? this.buildingDetails.projectName : this.buildingDetails.siteName) + ',' + this.buildingDetails.name + ',' + this.selectedFloor.name,
          };
          if (params.columnList && params.columnList.length > 0) {
            params.columnList.forEach((element: any) => {
              element.value.forEach((keyVal: any) => {
                const tmpKey = keyVal['name'];
                eventObject['COLUMN_' + tmpKey] = keyVal['isDefault']
              });
            });
          }
          eventObject['Version'] = isNumbered ? 'Beta' : 'Existing';
          if (!this.projectId) {
            eventObject['Stage'] = 'Live Sites';
          } else {
            eventObject['Stage'] = this.buildingDetails.stage;
          }
          eventObject['Optimized For'] = params.optimizedFor == 1 ? OptimizedReport[0].name : OptimizedReport[1].name;
          this.trackMixpanelEventTrigger(eventObject, 'Single Plan Printout');
        } else if (result && result["body"] === '') {
          this.isLoadingDailyReport = false;
          this.messageService.errorMessage('Error: ', 'Something went wrong')
        }
      },
      (e: any) => {
        if (e.status === 500) {
          this.router.navigate(["secure", "error", e.status], { skipLocationChange: true }).then(() => {

          });
        }
      }
    );
  }

  generateMultiPlanReportFileName() {
    const date = new Date();
    const user = this.authService.getUserKeyData();
    let multiPrintReportFileName = 'SiteOwl-Multi_Plan_Report-' + (user.customerName || '') + '-' + (this.projectId ? this.buildingDetails.projectName : this.buildingDetails.siteName) +
      '-' + moment(date).format('YYYYMMDD') + '-' + moment(date).format('HHmmss') + '.' + 'pdf';
    if (multiPrintReportFileName.match(/^[a-zA-Z1-9.?!:;\- ]+$/g) !== null) {
      const newMultiPrintReportFileName = multiPrintReportFileName.replace(/[^a-zA-Z0-9-_ \.]/g, '');
      multiPrintReportFileName = newMultiPrintReportFileName.replace(/ /g, "_");
    }
    return multiPrintReportFileName;
  }

  downloadMultiPlanReport(params: any) {
    if (this.isProject) {
      params.downloadData['projectId'] = this.projectId
    } else {
      params.downloadData['siteId'] = this.siteId
    }
    this.floorPlanService.multiPlanPrintOut(this.isProject ? this.projectId : this.siteId, params.downloadData, !this.isProject).pipe().subscribe(
      (result: any) => {
        if (result && result["body"]) {
          this.isLoadingDailyReport = false;
          const pdfGenerationString = result["body"];
          const blob = new Blob(
            [this.base64ToArrayBuffer(pdfGenerationString)],
            { type: "application/pdf" }
          );
          const a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = this.generateMultiPlanReportFileName();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          const eventObject: any = {
            'Report Name': 'Multi-Plan Report',
            'Scope': (this.projectId ? this.buildingDetails.projectName : this.buildingDetails.siteName) + ',' + this.buildingDetails.name + ',' + this.selectedFloor.name,
            'Source': 'Floor Plan',
          };
          if (params.columnList && params.columnList.length > 0) {
            params.columnList.forEach((element: any) => {
              element.value.forEach((keyVal: any) => {
                const tmpKey = keyVal['name'];
                eventObject['COLUMN_' + tmpKey] = keyVal['isDefault']
              });
            });
          }
          if (!this.projectId) {
            eventObject['Stage'] = 'Live Sites';
          } else {
            eventObject['Stage'] = this.buildingDetails.stage;
          }
          eventObject['Optimized For'] = params.optimizedFor == 1 ? OptimizedReport[0].name : OptimizedReport[1].name;
          this.trackMixpanelEventTrigger(eventObject, 'Multi-Plan Report');
        } else if (result && result["body"] === '') {
          this.isLoadingDailyReport = false;
          this.messageService.errorMessage('Error: ', 'Something went wrong')
        }
      },
      (e: any) => {
        if (e.status === 500) {
          this.router.navigate(["secure", "error", e.status], { skipLocationChange: true }).then(() => {

          });
        }
      })
  }

  trackMixpanelEventTrigger(object: any, eventName: any) {
    const userKeyData = this.authService.getUserKeyData()
    const trackMixPanel: any = {
      'Account Id': userKeyData.customerId,
      'Account Name': userKeyData.customerName
    }
    for (const property in object) {
      trackMixPanel[property] = object[property];
    }
    this.mixpanelService.trackEvent('Report Generated', trackMixPanel, eventName);
  }
  equipLinkTicket(event: any) {
    this.selectedItem = event;
    this.filterTickets(this.selectedChild ? this.selectedChild : this.selectedItem);
    this.linkTicket(this.selectedItem);
  }
  equipCreateTicket(event: any) {
    this.selectedItem = event;
    this.createTicket(this.selectedItem);
  }
  selectDeviceFromAssociatedTab(device: any) {
    if (device.floorId === this.floorId) {
      this.openEquipmentDetail({ pageX: device.imageLeft, pageY: device.imageTop }, FloorUtils.getEquipment(this.selectedFloor, device.id));
    }
  }
  getEquipmentListData() {
    this.floorPlanService.getFavoriteEquipmentList().pipe().subscribe({
      next: (favResult: any) => {
        if (this.uploadFavJson.length > 0) {
          this.defaultFavoriteEquipments = _.cloneDeep(favResult);
          let index = 0;

          // Iterate over defaultFavoriteEquipments
          this.defaultFavoriteEquipments.forEach((element: any) => {
            element.index = index++;
            element.isEquipmentFavoriteForm = false;
            element.isFavorateUpdate = false;

            // Check if element exists in favoriteEquipmentData
            const existingFavoriteIndex = this.favoriteEquipmentData.findIndex((e: any) => e.id === element.id);

            // Update or push element to favoriteEquipmentData
            if (existingFavoriteIndex !== -1) {
              this.favoriteEquipmentData[existingFavoriteIndex] = _.cloneDeep(element);
            } else {
              this.favoriteEquipmentData.push(_.cloneDeep(element));
            }
          });

          // Filter and map elements with id < 1
          const newFavorites = this.favoriteEquipmentData.filter((e: any) => e.id < 1).map((element: any) => {
            element.orderNumber = this.favoriteEquipmentData.length > 0 ? _.orderBy(this.favoriteEquipmentData, ['orderNumber'])[this.favoriteEquipmentData.length - 1].orderNumber + 1 : 1;
            return _.cloneDeep(element);
          });

          // Remove elements with id < 1 from favoriteEquipmentData
          this.favoriteEquipmentData = this.favoriteEquipmentData.filter((e: any) => e.id >= 1);

          // Push newFavorites to favoriteEquipmentData
          this.favoriteEquipmentData.push(...newFavorites);

          // Update index
          index = 0;
          this.favoriteEquipmentData.forEach((element: any) => {
            element.index = index++;
          });

          // Clone favoriteEquipmentData and trigger change detection
          this.favoriteEquipmentData = _.cloneDeep(this.favoriteEquipmentData);
          this.cdRef.detectChanges();
        } else {
          this.favoriteEquipmentData = this.defaultFavoriteEquipments = null;
          this.favoriteEquipmentData = _.cloneDeep(favResult);
          let index = 0;
          this.favoriteEquipmentData.forEach((element: any) => {
            // TO-DO If Db side not handle
            // element.type = element.type || 'device';
            element.index = index++;
            element.isEquipmentFavoriteForm = false;
            element.isFavorateUpdate = false;
          });
          this.defaultFavoriteEquipments = _.cloneDeep(this.favoriteEquipmentData);
          this.detectFloorChanges();
        }

        // this.defaultFavoriteEquipments = _.cloneDeep(this.favoriteEquipmentData);
      }
    });
  }
  saveEquipmentDetailFromContext(device: any) {
    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.equipmentDetails.id).length > 0) {
      Object.keys(device.equipmentDetails).forEach((element: any) => {
        if (element !== 'id') {
          this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.equipmentDetails.id)[0][element] = device.equipmentDetails[element];
          if (this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.equipmentDetails.id)[0].tempId).length > 0 && element === 'name') {
            this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === this.selectedFloor.floorEquipments.filter((e: any) => e.id === device.equipmentDetails.id)[0].tempId)[0].name = device.equipmentDetails.name;
          }
          if (this.updatedFloorEquipmentValues.filter(e => e.id === device.equipmentDetails.id).length > 0) {
            this.updatedFloorEquipmentValues.filter(e => e.id === device.equipmentDetails.id)[0][element] = device.equipmentDetails[element];
          }
        }
      })

    }
    this.detectFloorChanges();
  }

  checkNavigation(e?: any) {
    if (this.totalDiscardCount > 0) {
      const initialState: ModalOptions = {
        initialState: { totalDiscardCnt: this.totalDiscardCount },
        class: '', ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(SavePlanPopupComponent, initialState);
      this.bsModalRef.content.callSyncEvent.subscribe((r: any) => {
        this.isToNavigate = true;
        this.bsModalRef?.hide();
        this.saveFloorPlan();
      })
      this.bsModalRef.content.callDiscardEvent.subscribe((r: any) => {
        this.isToNavigate = false;
        this.bsModalRef?.hide();
        this.resetFloorPlan();
        this.navigateToListing();
      })
    } else { this.navigateToListing() }
  }

  navigateToListing() {
    this.bsModalRef?.hide();
    if (this.isProject) {
      this.router.navigate(['/secure/project/' + (this.buildingDetails.stage === "Installation" ? 'installation' : 'design')]);
    } else {
      this.router.navigate(['/secure/live-site/list']);
    }
  }
  favOrderChanged(changeFavValue: any) {
    this.favoriteEquipmentData = changeFavValue.favoriteEquipmentData;
    if (changeFavValue.equipment) {
      this.updateFavoriteJson(changeFavValue.equipment.id)
    }
  }

  updateFavoriteJson(favId: any) {
    if (this.uploadFavJson && this.uploadFavJson.length === 0) {
      this.uploadFavJson = [];
      this.uploadFavJson.push({ id: favId })
    } else {
      if (this.uploadFavJson.filter((x: any) => x.id === favId).length === 0) {
        this.uploadFavJson.push({ id: favId })
      }
    }
    this.isFavoriteUpdated = true;
    this.getUnSavedCount(true)
  }

  prepareFavJsonForServer() {
    this.favUpload = [];
    this.favoriteEquipmentData.forEach((elementCheck: any) => {
      if (elementCheck.id < 1) {
        elementCheck.id = null;
        if (elementCheck.childFavouriteEquipments && elementCheck.childFavouriteEquipments.length > 0) {
          elementCheck.childFavouriteEquipments.forEach((childfav: any) => {
            childfav.id = null;
          });
        }
      }
      if ((elementCheck.isDeleted && elementCheck.id !== null) || elementCheck.id === null) {
        if (elementCheck.id === null && !elementCheck.isDeleted) {
          this.favUpload.push(_.cloneDeep(elementCheck));
        } else if (elementCheck.id !== null && elementCheck.isDeleted) {
          this.favUpload.push({ id: elementCheck.id, isDeleted: elementCheck.isDeleted, childFavouriteEquipments: [] });
        }
      } else {
        const exsitingFavData = this.defaultFavoriteEquipments.filter((x: any) => x.id === elementCheck.id)[0];
        const diffData: any = Utilities.difference(elementCheck, exsitingFavData);
        if (diffData.childFavouriteEquipments && diffData.childFavouriteEquipments.length > 0) {
          delete diffData.childFavouriteEquipments;
        }
        if (Object.keys(diffData).length > 0) {
          this.favUpload.push(_.cloneDeep(diffData));
          this.favUpload[this.favUpload.length - 1].id = elementCheck.id;
          this.favUpload[this.favUpload.length - 1].childFavouriteEquipments = [];
        }
      }
      if (elementCheck.childFavouriteEquipments && elementCheck.childFavouriteEquipments.length > 0) {
        let countDeleted = true;
        elementCheck.childFavouriteEquipments.forEach((elementChildCheck: any) => {
          if (elementChildCheck.isDeleted && elementChildCheck.id !== null) {
            if (this.favUpload.filter((e: any) => e.id === elementCheck.id).length > 0) {
              this.favUpload.filter((e: any) => e.id === elementCheck.id)[0].childFavouriteEquipments.push({ id: elementChildCheck.id, isDeleted: elementChildCheck.isDeleted })
            } else {
              this.favUpload.push({ id: elementCheck.id, childFavouriteEquipments: [] })
              this.favUpload[this.favUpload.length - 1].childFavouriteEquipments.push({ id: elementChildCheck.id, isDeleted: elementChildCheck.isDeleted })
            }
          } else {
            if (countDeleted && elementCheck.isDeleted) {
              countDeleted = false;
              elementCheck.childFavouriteEquipments.forEach((elementChildFav: any) => {
                if (this.favUpload.filter((e: any) => e.id === elementCheck.id).length > 0) {
                  this.favUpload.filter((e: any) => e.id === elementCheck.id)[0].childFavouriteEquipments.push({ id: elementChildCheck.id, isDeleted: elementChildCheck.isDeleted })
                } else {
                  this.favUpload.push({ id: elementCheck.id, isDeleted: elementCheck.isDeleted, childFavouriteEquipments: [] })
                  this.favUpload[this.favUpload.length - 1].childFavouriteEquipments.push({ id: elementChildCheck.id, isDeleted: elementChildCheck.isDeleted })
                }
              });
            } else if (countDeleted && !elementCheck.isDeleted && elementCheck.id > 0) {

              const exsitingChildFavData: any = this.defaultFavoriteEquipments.filter((x: any) => x.id === elementCheck.id)[0];
              if (exsitingChildFavData && exsitingChildFavData.childFavouriteEquipments && exsitingChildFavData.childFavouriteEquipments.length > 0) {
                exsitingChildFavData.childFavouriteEquipments.forEach(
                  (child: any) => {
                    if (child.id === elementChildCheck.id) {
                      const diffDataChild: any = Utilities.difference(elementChildCheck, child);
                      if (Object.keys(diffDataChild).length > 0) {
                        if (this.favUpload.filter((e: any) => e.id === elementCheck.id).length === 0) {
                          this.favUpload.push({ id: elementCheck.id, childFavouriteEquipments: [] })
                          this.favUpload[this.favUpload.length - 1].childFavouriteEquipments.push(_.cloneDeep(diffDataChild));
                          this.favUpload[this.favUpload.length - 1].childFavouriteEquipments[this.favUpload[this.favUpload.findIndex((e: any) => e.id === elementCheck.id)].childFavouriteEquipments.length - 1].id = child.id;
                        } else {
                          this.favUpload[this.favUpload.findIndex((e: any) => e.id === elementCheck.id)].childFavouriteEquipments.push(_.cloneDeep(diffDataChild));
                          this.favUpload[this.favUpload.findIndex((e: any) => e.id === elementCheck.id)].childFavouriteEquipments[this.favUpload[this.favUpload.findIndex((e: any) => e.id === elementCheck.id)].childFavouriteEquipments.length - 1].id = child.id;
                        }
                      }
                    }
                  }
                );
              }

            }
          }
        });
      }
    });
    this.favUpload.forEach((removeElement: any) => {
      removeElement = this.removeUnWantedFavKeys(removeElement);
      removeElement = this.convertNullToNAFav(removeElement);
      if (removeElement && removeElement.childFavouriteEquipments && removeElement.childFavouriteEquipments.length > 0) {
        removeElement.childFavouriteEquipments.forEach((removeChildFavoriteElement: any) => {
          removeChildFavoriteElement = this.removeUnWantedFavKeys(removeChildFavoriteElement);
          removeChildFavoriteElement = this.convertNullToNAFav(removeChildFavoriteElement)
        });
      }
    })
    this.favUpload = this.favUpload.filter((e: any) => Object.keys(e).length > 1);

  }

  removeUnWantedFavKeys(res: any) {
    Object.keys(res).forEach(keys => {
      if (floorPlanFavDeviceUsedKeys.filter((e: any) => e === keys).length === 0) {
        delete res[keys];
      }
    });
    if ((res.equipment || res.equipment === 'NA' || (res.equipment && res.equipment.length === 0) || res.equipment === undefined || res.equipment === null) && res.id !== null) {
      delete res.equipment;
    }
    if (res.childFavouriteEquipments && res.childFavouriteEquipments.length === 0) {
      delete res.childFavouriteEquipments;
    }
    return res;
  }

  convertNullToNAFav(res: any) {
    if (res && res.id !== null) {
      for (const key in res) {
        if (res[key] === null || res[key] === 'null') {
          res[key] = '';
        }
      }
    }
    return res;
  }
  createFav() {
    if (this.equipmentDetails) {
      this.closeEquipmentDetails();
    }
    if (this.selectedChild) {
      this.equipmentFavs.duplicateFavorite(this.selectedChild)
    } else {
      this.equipmentFavs.duplicateFavorite(this.selectedItem)
    }
  }
  changeValueFromFavorite(event: any) {
    this.favoriteEquipmentData = _.cloneDeep(event.favoriteEquipmentData);
    if (event.equipment.isDeleted && event.equipment.id < 1) {
      if (this.uploadFavJson.filter((x: any) => x.id === event.equipment.id).length > 0) {
        this.uploadFavJson.splice(this.uploadFavJson.findIndex((x: any) => x.id === event.equipment.id), 1);
        this.getUnSavedCount(true);
      }
    } else {
      this.favOrderChanged(event);
    }
    this.isFavoriteUpdated = true;
    this.cdRef.detectChanges();
  }

  uploadFavJsonFile(url: any, presignedUrl: any) {
    if (this.bsModalRef && !this.bsModalRef.content.stopEndApiCall) {
      const changeData: any = _.cloneDeep(this.favUpload)
      this.floorPlanService.uploadJsonFileToServer(url, changeData).subscribe({
        next: (res: any) => {
          this.uploadFloorDetailsJson(presignedUrl);
        }, err: (j: any) => {
          this.btnService.enable();
          this.isStopApiCall = _.cloneDeep(false);
          this.syncStarted = false;
          this.bsModalRef?.hide();
          console.log("JSON Upload error", j)
        }
      })
    }
  }
  uploadFloorDetailsJson(r: any) {
    if (this.bsModalRef && !this.bsModalRef.content.stopEndApiCall) {
      if (this.isStopApiCall && (this.updatedFloorEquipmentValues && this.updatedFloorEquipmentValues.length > 0 && r.filter((u: any) => u.includes(this.folderName + '/floorDetail_floorDetail.json')) || (this.uploadNewFloorFile && this.uploadNewFloorFile.length > 0))) {
        if ((this.updatedFloorEquipmentValues.filter((z: any) => z.parentId).length > 0) || (this.uploadNewFloorFile && this.uploadNewFloorFile.length > 0)) {
          this.checkHasSecondaryDevice().then(() => {
            this.uploadJsonFile(r.filter((u: any) => u.includes(this.folderName + '/floorDetail_floorDetail.json'))[0]);
          })
        } else {
          this.uploadJsonFile(r.filter((u: any) => u.includes(this.folderName + '/floorDetail_floorDetail.json'))[0]);
        }
      } else {
        this.endSyncCall();
      }
    }
  }

  deviceHistory(device: any) {
    const initialState: ModalOptions = {
      initialState: { equipmentDetails: device, projectId: this.projectId, domotzUserId: this.buildingDetails.domotzUserId },
      class: 'modal-dialog device-history-modal-dialog modal-xxxl', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(EquipmentHistoryComponent, initialState);
  }

  setDataFromIndexDB() {
    this.isShowLoader = true;
    this.floorIndexDBService.getCountOfIndexDB("commonDetails").then((r: any) => {
      if (r && r > 0) {
        this.floorIndexDBService.getDetailsOfIndexDB("commonDetails").then((r: any) => {
          if (r && r.length > 0 && r[0].data.fileData && r[0].data.fileData.awsImageURL) {
            this.planImage = _.cloneDeep(r[0].data.fileData.awsImageURL);
            this.uploadNewFloorFile.push(r[0].data.fileData);
            this.actionBtn.edit = false;
            this.actionBtn.save = true;
            this.getUnSavedCount()
            if (this.uploadNewFloorFile.length > 0) {
              this.selectedFloor.awsImageURL = this.planImage;
              this.checkMarkupFilter(true);
            }
          }
        })
      }
    })

    this.floorIndexDBService.getCountOfIndexDB("floorDevices").then((r: any) => {
      if (r && r > 0) {
        this.floorIndexDBService.getDetailsOfIndexDB("floorDevices").then((r: any) => {
          if (r && r.length > 0) {
            this.updatedFloorEquipmentValues = [];
            const devicesFromIndexedDB: any = [];
            r.forEach((element: any) => {
              const updatedData: any = jsonpack.unpack(element.device);
              devicesFromIndexedDB.push(updatedData);
            });
            this.floorIndexDBService.getCountOfIndexDB("deviceImage").then((img: any) => {
              if (img && img > 0) {
                this.floorIndexDBService.getDetailsOfIndexDB("deviceImage").then(async (imgData: any) => {
                  if (imgData && imgData.length > 0) {
                    const devicesImageFromIndexedDB: any = [];
                    imgData.forEach((element: any) => {
                      devicesImageFromIndexedDB.push(element);
                    });
                    for (let i = 0; i < devicesImageFromIndexedDB.length; i++) {
                      const base64String = await this.blobToBase64(devicesImageFromIndexedDB[i].image);
                      // 'result' contains the Base64 representation of the Blob
                      if (devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId).length > 0 && devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.imgId).length > 0) {
                        devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.imgId)[0].awsImageURL = base64String;
                        devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.imgId)[0].thumbnailAwsImageURL = base64String;
                      } else if (devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId).length > 0 && devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.id).length > 0) {
                        devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.id)[0].awsImageURL = base64String;
                        devicesFromIndexedDB.filter((e: any) => e.id === devicesImageFromIndexedDB[i].deviceId)[0]['images'].filter((x: any) => devicesImageFromIndexedDB[i].imageId === x.id)[0].thumbnailAwsImageURL = base64String;
                      }
                      if (i === devicesImageFromIndexedDB.length - 1) {
                        this.updateDeviceFromIndexedDB(devicesFromIndexedDB);
                      }
                    }
                  }
                });
              } else {
                this.updateDeviceFromIndexedDB(devicesFromIndexedDB);
              }
            }, err => {
              this.updateDeviceFromIndexedDB(devicesFromIndexedDB);
            });

          }
        })
      }
    });
    this.floorIndexDBService.getCountOfIndexDB("favDevices").then((rFav: any) => {
      if (rFav && rFav > 0) {
        this.floorIndexDBService.getDetailsOfIndexDB("favDevices").then((rFavDevice: any) => {
          if (rFavDevice && rFavDevice.length > 0) {
            this.uploadFavJson = [];
            rFavDevice.forEach((element: any) => {
              const updatedFavData: any = jsonpack.unpack(element.device);
              if (this.favoriteEquipmentData && this.favoriteEquipmentData.length > 0 && this.favoriteEquipmentData.filter((x: any) => x.id === updatedFavData.id).length > 0) {
                Object.keys(updatedFavData).forEach((updateVal: any) => {
                  if (this.favoriteEquipmentData.filter((x: any) => x.id === updatedFavData.id).length > 0 && updateVal !== "id") {
                    this.favoriteEquipmentData.filter((x: any) => x.id === updatedFavData.id)[0][updateVal] = updatedFavData[updateVal];
                  }
                })
              } else {
                this.favoriteEquipmentData.push(updatedFavData);
              }
              this.uploadFavJson.push({ id: updatedFavData.id })
            });
            this.cdRef.detectChanges()
            this.actionBtn.edit = false;
            this.actionBtn.save = true;
            this.getUnSavedCount(true, true);
          }
        })
      }
    });

  }
  updateDeviceFromIndexedDB(devicesFromIndexedDB: any) {
    devicesFromIndexedDB.filter((e: any) => !e.parentId).forEach((updatedData: any) => {
      if (updatedData.isHub && (updatedData.id < 1 || this.netWorkSwitches.filter((e: any) => e.id === updatedData.id).length === 0)) {
        this.netWorkSwitches.push({
          floorId: this.floorId,
          floorName: this.selectedFloor.name,
          id: updatedData.id,
          name: updatedData.name,
          networkSwitchLinkTempId: updatedData.tempId
        })
      }
      if (updatedData.isHub === false && updatedData.id >= 1 && this.netWorkSwitches.filter((e: any) => e.id === updatedData.id).length > 0) {
        this.netWorkSwitches.splice(this.netWorkSwitches.findIndex((e: any) => e.id === updatedData.id), 1);
      }
      if (updatedData.childFloorEquipments && updatedData.childFloorEquipments.length > 0) {
        updatedData.childFloorEquipments = [];
      }
      this.updatedFloorEquipmentValues.push(updatedData);
      if (updatedData.id >= 1) {
        if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId >= 1) {
          updatedData = this.setCableOrConnectionLine(updatedData)
        } else if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId < 1) {
          if (devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId).length > 0) {
            updatedData = this.setCableOrConnectionLine(updatedData, devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId)[0])
          }
        }
        this.updateFloorObject(updatedData);
      } else if (updatedData && updatedData.id && updatedData.id < 1) {//new device added to floorplan as primary
        updatedData = this.setDevices(updatedData);
        if (updatedData.coverageArea) {
          updatedData = this.setCoverageElements(updatedData, this.largerDimension)
        }
        if ((updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId >= 1) || updatedData.cableCoordinates) {
          updatedData = this.setCableOrConnectionLine(updatedData)
        } else if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId < 1) {
          if (devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId).length > 0) {
            updatedData = this.setCableOrConnectionLine(updatedData, devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId)[0])
          }
        }
        this.selectedFloor.floorEquipments.push(_.cloneDeep(updatedData));
      }

    });
    devicesFromIndexedDB.filter((e: any) => e.parentId).forEach((updatedData: any) => {
      if (updatedData.isHub && (updatedData.id < 1 || this.netWorkSwitches.filter((e: any) => e.id === updatedData.id).length === 0)) {
        this.netWorkSwitches.push({
          floorId: this.floorId,
          floorName: this.selectedFloor.name,
          id: updatedData.id,
          name: updatedData.name,
          networkSwitchLinkTempId: updatedData.tempId
        })
      }
      if (updatedData.isHub === false && updatedData.id >= 1 && this.netWorkSwitches.filter((e: any) => e.id === updatedData.id).length > 0) {
        this.netWorkSwitches.splice(this.netWorkSwitches.findIndex((e: any) => e.id === updatedData.id), 1);
      }
      this.updatedFloorEquipmentValues.push(updatedData);
      if (updatedData.id >= 1) {
        if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId >= 1) {
          updatedData = this.setCableOrConnectionLine(updatedData)
        } else if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId < 1) {
          if (devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId).length > 0) {
            updatedData = this.setCableOrConnectionLine(updatedData, devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId)[0])
          }
        }
        this.updateFloorObject(updatedData);
      } else if (updatedData && updatedData.parentId && updatedData.parentId > 0 && updatedData.id < 1 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === updatedData.parentId).length > 0) {  //new device added to floorplan as secondary
        updatedData = this.setDevices(updatedData);
        if (updatedData.coverageArea) {
          updatedData = this.setCoverageElements(updatedData, this.largerDimension)
        }
        if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId >= 1) {
          updatedData = this.setCableOrConnectionLine(updatedData)
        } else if (updatedData.networkSwitchLinkId && updatedData.networkSwitchLinkId < 1) {
          if (devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId).length > 0) {
            updatedData = this.setCableOrConnectionLine(updatedData, devicesFromIndexedDB.filter((e: any) => e.id === updatedData.networkSwitchLinkId)[0])
          }
        }
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === updatedData.parentId)[0].childFloorEquipments.push(_.cloneDeep(updatedData))
        this.selectedFloor.floorEquipments.filter((x: any) => x.id === updatedData.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === updatedData.parentId)[0]);
      }

    });
    this.actionBtn.edit = false;
    this.actionBtn.save = true;
    this.getUnSavedCount(false, true);
    this.detectFloorChanges();
  }
  checkFlagBadge(item: any) {
    let filteredData: any = [];
    if (item.childFloorEquipments && item.childFloorEquipments.length > 0) {
      const filterPipe = new FloorCategoryFilterPipe();
      filteredData = _.cloneDeep(filterPipe.transform(item.childFloorEquipments, { 'devices': this.filterCategoryData, 'isProject': this.isProject, 'hideLiveDevice': this.hideLiveDevice, 'assignedToFilter': this.assignedToFilter }));
    }
    return item && (item.isFlag || (filteredData.filter((e: any) => e.isFlag).length > 0))
  }
  updateFloorObjectForEquipmentDetail(device: any) {
    const id: any = (this.equipmentDetails === undefined || this.equipmentDetails === null) ? device.id : this.equipmentDetails.id;
    const floorDevice: any = this.selectedFloor.floorEquipments.filter((x: any) => x.id === id);
    if (floorDevice.length > 0) {
      Object.keys(device).forEach(element => {
        if (element !== "id") {
          if (element === 'name') {
            if (floorDevice[0].id >= 1 && this.netWorkSwitches.filter((e: any) => e.id === floorDevice[0].id).length > 0) {
              this.netWorkSwitches.filter((e: any) => e.id === floorDevice[0].id)[0].name = device.name;
            } else if (floorDevice[0].id < 1 && this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === floorDevice[0].tempId).length > 0) {
              this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === floorDevice[0].tempId)[0].name = device.name;
            }
          }
          floorDevice[0][element] = device[element];
        }
        if (this.equipmentDetails && element !== "id" && (this.equipmentDetails.parentId === null || this.equipmentDetails.parentId === undefined || this.equipmentDetails.parentId === '')) {
          this.equipmentDetails[element] = device[element];
        }
        if (element === 'pinColor' || element === 'status' || element === 'installStatus') {
          floorDevice[0] = this.setDevices(floorDevice[0]);
          floorDevice[0].tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(floorDevice[0], this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData);
          floorDevice[0].displayBorderColor = FloorUtils.displayBorderColor({ displayBorderColor: '' }, floorDevice[0], this.pinColorType, this.isProject);
          this.equipmentDetails = _.cloneDeep(floorDevice[0]);
        }
      });
      if ((device.networkSwitchLinkId && device.networkSwitchLinkId !== null && device.networkSwitchLinkId > 0) || (device.cableCoordinates === null && floorDevice[0].networkSwitchLinkId && floorDevice[0].networkSwitchLinkId !== null && floorDevice[0].networkSwitchLinkId > 0)) {
        floorDevice[0] = _.cloneDeep(this.setCableOrConnectionLine(floorDevice[0]));

        const existingDeviceData = FloorUtils.getEquipment(this.selectedFloor, device.id);
        const getConnectedDevice: any = FloorUtils.getEquipment(this.selectedFloor, device.networkSwitchLinkId);
        if (getConnectedDevice && getConnectedDevice.networkSwitchLinkEquipments === undefined) {
          getConnectedDevice.networkSwitchLinkEquipments = []

        }
        if (getConnectedDevice && getConnectedDevice.networkSwitchLinkEquipments && getConnectedDevice.networkSwitchLinkEquipments.filter((r: any) => r.id === existingDeviceData.id).length === 0) {
          getConnectedDevice['networkSwitchLinkEquipments'].push({
            floorId: this.selectedFloor.id, floorName: this.selectedFloor.name, id: existingDeviceData.id, installStatus: device.installStatus ? device.installStatus : existingDeviceData.installStatus, name: device.name ? device.name : existingDeviceData.name, networkSwitchLinkId: null, port: device.port ? device.port : existingDeviceData.port, status: device.status ? device.status : existingDeviceData.status
          })
        }
        if (this.equipmentDetails && this.equipmentDetails.id === device.id) {
          this.equipmentDetails = _.cloneDeep(floorDevice[0]);
        }
      }
      if (floorDevice[0].childFloorEquipments && floorDevice[0].childFloorEquipments.length > 0) {
        floorDevice[0].childFloorEquipments.forEach((c: any) => {
          this.updateSecondaryDevices(c, floorDevice)
        });
      }

    } else {
      if (device.networkSwitchLinkId && device.networkSwitchLinkId !== null && device.networkSwitchLinkId > 0) {
        const existingDeviceData = FloorUtils.getEquipment(this.selectedFloor, device.id);
        const getConnectedDevice: any = FloorUtils.getEquipment(this.selectedFloor, device.networkSwitchLinkId);
        if (getConnectedDevice && getConnectedDevice.networkSwitchLinkEquipments === undefined) {
          getConnectedDevice.networkSwitchLinkEquipments = []

        }
        if (getConnectedDevice && getConnectedDevice.networkSwitchLinkEquipments && getConnectedDevice.networkSwitchLinkEquipments.filter((r: any) => r.id === existingDeviceData.id).length === 0) {
          getConnectedDevice['networkSwitchLinkEquipments'].push({
            floorId: this.selectedFloor.id, floorName: this.selectedFloor.name, id: existingDeviceData.id, installStatus: device.installStatus ? device.installStatus : existingDeviceData.installStatus, name: device.name ? device.name : existingDeviceData.name, networkSwitchLinkId: null, port: device.port ? device.port : existingDeviceData.port, status: device.status ? device.status : existingDeviceData.status
          })
        }
      }
      const parentDevice: any = this.selectedFloor.floorEquipments.filter((x: any) => x.id === this.equipmentDetails.parentId);
      this.updateSecondaryDevices(device, parentDevice)
    }
    this.cdRef.detectChanges();
  }
  liveDeviceToggle(event: any) {
    if (!this.liveDevicesFetched) {
      this.floorPlanService.getLiveDevices(this.floorId).subscribe((res: any) => {
        this.liveDevicesFetched = true;
        this.currentFloorLiveDevice = _.cloneDeep(res);
        this.hideLiveDevice = !event;
        this.setLiveDevices();
        this.detectFloorChanges();
      })
    } else {
      this.hideLiveDevice = !event;
      this.setSearchDataInHeader();
    }
  }
  assignedToUserChanged(event: any) {
    this.assignedToFilter = event;
    this.selectedFloor.floorEquipments = _.cloneDeep(this.setDefaultValuesForSelectedFloor(this.selectedFloor.floorEquipments));
    this.cdRef.detectChanges();
  }

  buildingFloorDropdownData(event: any) {
    this.initalBuildingFloorDropdownData = _.cloneDeep(event);
  }
  inputCalled() {
    if (this.totalDiscardCount === 0) {
      this.actionBtn.edit = false;
      this.actionBtn.save = true;
      this.totalDiscardCount = 1;
    }
  }

  buildingChanged(e: any) {
    this.isBuildingChanged = true;
    localStorage.removeItem(stateManage.accordionState);
    localStorage.setItem(stateManage.scrollPosition, "0");
    localStorage.setItem(stateManage.accordionState, JSON.stringify({ 0: true }));
    if (this.uploadFavJson.length > 0 || this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0) {
      this.buildingOrPlanChangeButSaveRemain(e);
    } else {
      this.syncCompleted = false;
      this.initCall = true;
      this.cdRef.detectChanges();
      this.getNewPlanFromSameSite(e)
    }
  }

  planChanged(e: any) {
    localStorage.removeItem(stateManage.accordionState);
    localStorage.setItem(stateManage.scrollPosition, "0");
    localStorage.setItem(stateManage.accordionState, JSON.stringify({ 0: true }));
    this.isPlanChanged = true;
    if (this.uploadFavJson.length > 0 || this.updatedFloorEquipmentValues.length > 0 || this.uploadNewFloorFile.length > 0) {
      this.buildingOrPlanChangeButSaveRemain(e);
    } else {
      this.getNewPlanFromSameSite(e)
    }
  }

  buildingOrPlanChangeButSaveRemain(e: any) {
    if (this.totalDiscardCount > 0) {
      const initialState: ModalOptions = {
        initialState: { totalDiscardCnt: this.totalDiscardCount },
        class: '', ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(SavePlanPopupComponent, initialState);
      this.bsModalRef.content.callSyncEvent.subscribe((r: any) => {
        this.isToNavigate = false;
        this.bsModalRef?.hide();
        this.changeBuildingOrPlan = { change: true, e: e };
        this.saveFloorPlan();
      })
      this.bsModalRef.content.callDiscardEvent.subscribe((r: any) => {
        this.isToNavigate = false;
        this.bsModalRef?.hide();
        this.changeBuildingOrPlan = { change: true, e: e };
        this.resetFloorPlan();
        const timeOut = setTimeout(() => {
          this.actionBtn.edit = false;
          this.actionBtn.save = false;
          this.getNewPlanFromSameSite(e)
          clearTimeout(timeOut)
        }, 100);
      })
    }
  }
  getNewPlanFromSameSite(e: any) {
    this.equipmentDetails = null;
    if (this.equipmentFavs && this.equipmentFavs.equipmentDetails) {
      this.equipmentFavs.equipmentDetails = null;
    }
    this.router.navigate(['/secure/' + (this.isProject ? 'project/' : 'site/') + e.id + '/building/' + e.buildingId + '/floor/' + e.floorId, 'details']);
    this.changeBuildingOrPlan.change = false;
    this.changeBuildingOrPlan.e = null;
    this.liveDevicesFetched = false;
    this.hideLiveDevice = true;
    this.currentFloorLiveDevice = [];
    if (!this.actionBtn.edit) {
      this.actionBtn.edit = false;
      this.actionBtn.save = false;
    }
  }

  setSearchDataInHeader() {
    const data: any = _.cloneDeep(this.selectedFloor);
    if (this.hideLiveDevice && this.isProject) {
      data.floorEquipments = data.floorEquipments.filter((e: any) => !Utilities.isNull(e.projectId))
    }
    if (this.projectId) {
      data.isFromProject = true;
      if (!this.hideLiveDevice) {
        data.liveDevicesShow = true;
      } else {
        data.liveDevicesShow = false;
      }
    }
    this.floorDataTransferService.updateData(data);
  }


  showIconsForCoverage() {
    if (this.equipmentDetails && this.equipmentDetails.coverageArea && !this.actionBtn.edit && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice && ((this.projectId && this.equipmentDetails.projectId !== null) || !this.projectId) && (this.permissions.canEdit || this.permissions.canManage || this.permissions.canUpdateDevice)) {
      this.getTranslate(this.equipmentDetails)
      const timeOut = setTimeout(() => {
        this.updatingCoverageValue = false;
        clearTimeout(timeOut);
      }, 0);
    }
  }

  updateConnectionFlag(e: any) {
    this.updateConnection = true;
    let device: any = FloorUtils.getEquipment(this.selectedFloor, this.equipmentDetails.id)
    if (device) {
      device.cableCoordinates = _.cloneDeep(this.equipmentDetails.cableCoordinates);
      device = this.setCableOrConnectionLine(device);
      this.eventService.broadcast(EventEmitterType.cablePathChange, { coordinates: device.cableCoordinates })
    }
    this.showCountFilterPopup = false;
    this.countFilterClicked = false;
    this.saveEquipmentDetails(e);
    const flagTimer = setTimeout(() => {
      this.updateConnection = false;
      clearTimeout(flagTimer);
    }, 0);
  }

  updateSecondaryDevices(device: any, parentDevice: any) {
    Object.keys(device).forEach(element => {
      if (this.equipmentDetails && this.equipmentDetails.parentId !== null && parentDevice.length > 0 && parentDevice[0].childFloorEquipments && parentDevice[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0) {
        if (parentDevice[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id).length > 0 && element !== "id") {
          parentDevice[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0][element] = device[element];
        }
        if (this.equipmentDetails && element !== "id") {
          if (element === 'name') {
            if (this.equipmentDetails.id >= 1 && this.netWorkSwitches.filter((e: any) => e.id === this.equipmentDetails.id).length > 0) {
              this.netWorkSwitches.filter((e: any) => e.id === this.equipmentDetails.id)[0].name = device.name;
            } else if (this.equipmentDetails.id < 1 && this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === this.equipmentDetails.tempId).length > 0) {
              this.netWorkSwitches.filter((e: any) => e.networkSwitchLinkTempId === this.equipmentDetails.tempId)[0].name = device.name;
            }
          }
          this.equipmentDetails[element] = device[element];
        }
        if (element === 'pinColor' || element === 'status' || element === 'installStatus') {
          this.setDevices(parentDevice[0].childFloorEquipments.filter((y: any) => y.id === this.equipmentDetails.id)[0]);
        }
      } else {
        if (parentDevice.length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((y: any) => y.id === device.id).length > 0 && element !== "id") {
          parentDevice[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0][element] = device[element];
        }
        if (element === 'pinColor' || element === 'status' || element === 'installStatus') {
          if (parentDevice && parentDevice[0].childFloorEquipments !== undefined && parentDevice[0].childFloorEquipments.length > 0) {
            if (parentDevice[0].childFloorEquipments.filter((y: any) => y.id === device.id) && parentDevice[0].childFloorEquipments.filter((y: any) => y.id === device.id).length > 0) {
              this.setDevices(parentDevice[0].childFloorEquipments.filter((y: any) => y.id === device.id)[0]);
            }
          }
        }
      }

    });
    if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId).length > 0) {
      this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0] = this.setDevices(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0]);
      if (device.networkSwitchLinkId && device.networkSwitchLinkId !== null && device.networkSwitchLinkId > 0) {
        if (this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId).length > 0 && this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((e: any) => e.id === device.id).length > 0) {
          this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((e: any) => e.id === device.id)[0] = _.cloneDeep(this.setCableOrConnectionLine(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((e: any) => e.id === device.id)[0]));
          if (this.equipmentDetails && this.equipmentDetails.id === device.id) {
            this.equipmentDetails = _.cloneDeep(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].childFloorEquipments.filter((e: any) => e.id === device.id)[0]);
          }
        }
      }
    }
    this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].tooltipClass = FloorUtils.setClassForToolTipStatusForFloorSecondary(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0], this.isProject, true, this.pinColorType, this.assignedToFilter, this.filterCategoryData)
    this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].secondaryPopupPosition = FloorUtils.checkSecondaryDevicePosition(this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0], this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].imageLeft, this.selectedFloor.floorEquipments.filter((x: any) => x.id === device.parentId)[0].imageTop);

  }
  setLiveDevices() {
    this.currentFloorLiveDevice.forEach((element: any) => {
      element = this.setDevices(element);
      element.isLiveDevice = true;
      element = this.setCoverageElements(element, this.largerDimension)
      if (element.networkSwitchLinkId) {
        element = this.setCableOrConnectionLine(element, null, true)
      }
      // element.tooltipClass =
      element.displayCoverageConeColor = 'rgba(0, 54, 74)';
      element.childFloorEquipments.forEach((elementChild: any) => {
        elementChild = this.setDevices(elementChild);
        elementChild.isLiveDevice = true;
        elementChild = this.setCoverageElements(elementChild, this.largerDimension)
        if (elementChild.networkSwitchLinkId) {
          elementChild = this.setCableOrConnectionLine(elementChild, null, true)
        }
        elementChild.displayCoverageConeColor = 'rgba(0, 54, 74)';
      });
      this.selectedFloor.floorEquipments.push(element);
    });
    this.setSearchDataInHeader();
  }
  addUpdatedValueJsonForDevice(device: any) {
    const updatedDevice: any = _.cloneDeep(device);
    const updatingArrayUpdate = this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id);
    if (updatingArrayUpdate.length > 0) {
      if (Object.keys(updatedDevice).length > 0) {
        Object.keys(updatedDevice).forEach((element: any, index: any) => {
          if (updatedDevice && updatedDevice.isUpdate && updatedDevice.resolveFlagNotes !== undefined && updatedDevice.resolveFlagNotes !== null && updatingArrayUpdate && updatingArrayUpdate[0].flagNotes !== undefined && updatingArrayUpdate[0].flagNotes !== null) {
            delete updatingArrayUpdate[0].flagNotes;
          }
          if (element === 'id' && updatedDevice.isDeleted && updatedDevice.id < 1 && updatedDevice.parentId && updatedDevice.parentId > 0) {
            this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex(e => e.id === updatedDevice.id), 1);
          } else {
            if (element === 'isDeleted') {
              if (Object.keys(updatingArrayUpdate[0]).includes('isArchived')) {
                delete updatingArrayUpdate[0]['isArchived'];
              }
            } else if (element === 'isFlag' && !updatedDevice['isFlag'] && updatedDevice['flagNotes'] === null) {
              delete updatingArrayUpdate[0]['isFlag'];
              delete updatingArrayUpdate[0]['flagNotes'];
              delete updatingArrayUpdate[0]['isFlagAdd'];
              if (Object.keys(updatingArrayUpdate[0]).length < 4) {
                const index = this.updatedFloorEquipmentValues.map(function (img) { return img.id; }).indexOf(updatingArrayUpdate[0]['id'])
                if (index > -1) { // only splice array when item is found
                  delete updatingArrayUpdate[0];
                  this.updatedFloorEquipmentValues.splice(index, 1); // 2nd parameter means remove one item only
                }
              }
            }
            if (updatingArrayUpdate[0] !== undefined) {
              updatingArrayUpdate[0][element] = updatedDevice[element]
            }
          }
        });
        if (updatingArrayUpdate[0] !== undefined) {
          updatingArrayUpdate[0] = this.updatedFloorEquipmentValues.filter((e: any) => e.id === device.id)[0];
        }
      }
    } else {
      this.updatedFloorEquipmentValues.push(updatedDevice);
    }
  }
  base64ToBlob(base64Data: any, contentType: any) {
    const byteCharacters = atob(base64Data.replace(/^data:image\/[a-z]+;base64,/, ""));
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  blobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  getConnectionLength(device: any, connectedDevice: any) {
    let deviceLength = 0;
    if (device.imageLeft !== undefined && device.imageTop !== undefined && connectedDevice.imageLeft !== undefined && connectedDevice.imageTop !== undefined) {
      if (device.cableCoordinates === null) {
        deviceLength = this.calculateDistanceBetweenPoints({ x: parseFloat(device.imageLeft), y: parseFloat(device.imageTop) }, { x: parseFloat(connectedDevice.imageLeft), y: parseFloat(connectedDevice.imageTop) });
      } else {
        if (device.cableCoordinates !== null && typeof device.cableCoordinates === "string") {
          device.cableCoordinates = _.cloneDeep(JSON.parse(device.cableCoordinates));
        }
        device.cableCoordinates.forEach((element: any, i: any) => {
          if (i > 0) {
            deviceLength = deviceLength + this.calculateDistanceBetweenPoints({ x: parseFloat(element.x), y: parseFloat(element.y) }, { x: parseFloat(device.cableCoordinates[i - 1].x), y: parseFloat(device.cableCoordinates[i - 1].y) });
          }
        });
      }
    }
    return deviceLength.toFixed(1);
  }

  calculateDistanceBetweenPoints(p1: any, p2: any) {
    const point1X = (p1.x / 100) * this.floorImageShadow.naturalWidth; // defaulat image will be onsider 1500
    const point1Y = (p1.y / 100) * this.floorImageShadow.naturalHeight;
    const point2X = (p2.x / 100) * this.floorImageShadow.naturalWidth;
    const point2Y = (p2.y / 100) * this.floorImageShadow.naturalHeight;
    // Calculate the distance between the two points using the Pythagorean theorem
    const distancePX = ((Math.sqrt(Math.pow(point2X - point1X, 2) + Math.pow(point2Y - point1Y, 2))));
    const distance = (distancePX / this.floorImageShadow.naturalWidth) * this.selectedFloor.floorScale
    return distance;
  }
  browserLeave($event: any) {
    if (this.totalDiscardCount > 0) {
      $event.preventDefault();
      $event.returnValue = 'false';
    }
  }
  updateAllParentAndDChildConnections(id: any) {
    if (this.selectedFloor.floorEquipments.filter((e: any) => e.id === id).length > 0) {
      let parentDevice = this.selectedFloor.floorEquipments.filter((e: any) => e.id === id)[0];
      if (parentDevice.networkSwitchLinkId > 0) {
        if (parentDevice.childFloorEquipments.filter((e: any) => e.id === parentDevice.networkSwitchLinkId).length > 0) {
          parentDevice.cableCoordinates = null;
          parentDevice = this.setCableOrConnectionLine(parentDevice);
          this.addUpdatedValueJsonForDevice({ id: parentDevice.id, type: parentDevice.type, parentId: parentDevice.parentId, cableCoordinates: null, isUpdate: true });
        }
      }
      if (parentDevice.childFloorEquipments.length > 0) {
        parentDevice.childFloorEquipments.forEach((element: any) => {
          if (element.networkSwitchLinkId === parentDevice.id || parentDevice.childFloorEquipments.filter((e: any) => e.id === element.networkSwitchLinkId).length > 0) {
            element.cableCoordinates = null;
            element = this.setCableOrConnectionLine(element);
            this.addUpdatedValueJsonForDevice({ id: element.id, type: element.type, parentId: element.parentId, cableCoordinates: null, isUpdate: true });
          }
        });
      }
    }
  }
  recheckDeviceNameCount() {
    this.baseNameCount = [];
    this.selectedFloor.floorEquipments.forEach((element: any) => {
      this.baseNameCount = FloorUtils.checkDeviceNames(this.baseNameCount, element);
    });
  }
  stopSyncCallDueToNoInternet() {
    this.eventService.subscribe(EventEmitterType.internetConnectionGone, () => {
      this.btnService.enable();
      this.isStopApiCall = _.cloneDeep(false);
      this.syncStarted = false;
      this.bsModalRef?.hide();
      this.cdRef.detectChanges();
    })
  }
  removeFlagUpdatedValues(deviceData: any) {
    delete this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id)[0].isFlag;
    delete this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id)[0].isFlagAdd;
    delete this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id)[0].flagNotes;
    if (Object.keys(this.updatedFloorEquipmentValues.filter(e => e.id === deviceData.id)[0]).filter(e => e !== 'id' && e !== 'isUpdate' && e !== 'type' && e !== 'parentId').length === 0) {
      this.updatedFloorEquipmentValues.splice(this.updatedFloorEquipmentValues.findIndex(e => e.id === deviceData.id), 1);
    }
    this.getUnSavedCount();
  }
}
